import React from 'react'
import { Loading } from 'carbon-components-react'
import { useSelector } from 'react-redux'
import { ResponsiveBar } from '@nivo/bar'
import { coolors } from '../../settings'
import { selectOutputIsFetching } from './redux/selectors'

function CustomTooltip({ data }) {
  const value = data.conflicting || data.dropped || data.start || data.end || 0

  return (
    <div className="chart-bar-tooltip">
      <span>
        {data.label}: <strong>{value}</strong>
      </span>
    </div>
  )
}

export default function Charts({ operations: mappedData, handleBarClick }) {
  const isFetching = useSelector(selectOutputIsFetching)

  return (
    <>
      <h4>Operations - filtered out &amp; retained for planning</h4>

      <div className="app-chart">
        {isFetching && (
          <div className="app-chart-loading">
            <Loading withOverlay={false} />
          </div>
        )}

        {mappedData && mappedData.length > 0 && (
          <ResponsiveBar
            data={mappedData}
            keys={['inv', 'dropped', 'start', 'end', 'conflicting', 'topInv']}
            indexBy="label"
            onClick={handleBarClick}
            margin={{
              top: 20, right: 10, bottom: 10, left: 40,
            }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={[
              coolors[0],
              coolors[1],
              coolors[3],
              coolors[3],
              coolors[5],
              coolors[0],
            ]}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            label={(d) => (['dropped', 'start', 'end', 'conflicting'].includes(d.id)
              ? d.data.labelFormat
              : '')}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            animate
            motionStiffness={90}
            motionDamping={15}
            tooltip={CustomTooltip}
          />
        )}
      </div>
      <ul className="app-chart-labels">
        {mappedData.map((d) => (
          <li
            key={d.label}
            style={{ flexBasis: `${100 / mappedData.length}%` }}
          >
            {d.label}
          </li>
        ))}
      </ul>
    </>
  )
}
