import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  ButtonSet,
  FileUploaderDropContainer,
  InlineNotification,
  Loading,
  TextInput,
} from 'carbon-components-react'
import { Upload16, CheckmarkFilled32 } from '@carbon/icons-react'
import Collapse from '@material-ui/core/Collapse'
import * as actions from './redux/actions'
import {
  selectDatasourceUploading,
  selectDatasourceUploadError,
  selectDatasourceUploadSuccess,
} from './redux/selectors'

const Upload = ({ onContinue }) => {
  const dispatch = useDispatch()

  const [fileName, setFileName] = useState(
    moment().format('DD-MM-YYYY H:mm:ss')
  )
  const [fileNameInvalid, setFileNameInvalid] = useState(false)
  const [file, setFile] = useState(null)

  const isLoading = useSelector(selectDatasourceUploading)
  const error = useSelector(selectDatasourceUploadError)
  const success = useSelector(selectDatasourceUploadSuccess)

  const onResetError = () => {
    dispatch(actions.setUploadState({ error: null }))
  }

  const onAddFiles = useCallback(
    (e, { addedFiles }) => {
      onResetError()
      e.stopPropagation()
      setFile(addedFiles[0])
      setFileName(addedFiles[0]?.name || '')
    },
    [fileName, dispatch, onResetError]
  )

  const onFileNameChange = (e) => {
    const { value } = e.target
    if (!value) {
      setFileNameInvalid(true)
    } else {
      setFileNameInvalid(false)
    }

    setFileName(value)
  }

  const onUpload = () => {
    dispatch(actions.uploadData({ file, name: fileName }))
  }

  const onRemoveFile = () => {
    onResetError()
    setFile(null)
    setFileName()
  }

  const handleContinue = () => {
    onContinue()
    onRemoveFile()
  }

  // Effects
  useEffect(() => {
    if (success) {
      onRemoveFile()
    }
  }, [success])

  return (
    <div className="upload-container">
      <Collapse in={isLoading && !success}>
        <Loading withOverlay={false} description="Uploading..." />
      </Collapse>
      <Collapse in={!isLoading && !success}>
        <div>
          <Collapse in={!!file}>
            <div className="mb-1">
              <TextInput
                light
                id="filename"
                labelText=""
                value={fileName}
                onChange={onFileNameChange}
                invalid={fileNameInvalid}
                invalidText="File name is required"
              />
            </div>
            <ButtonSet className="upload-btn-set">
              <Button size="small" onClick={onUpload} renderIcon={Upload16}>
                Upload
              </Button>
              <Button size="small" kind="ghost" onClick={onRemoveFile}>
                Cancel
              </Button>
            </ButtonSet>
          </Collapse>

          <Collapse in={!file}>
            <FileUploaderDropContainer
              onAddFiles={onAddFiles}
              // accept={['csv']}
              id="upload-file"
              className="uploadbox"
              labelText="Drag and drop the file here or click to select it"
            />
          </Collapse>
        </div>
      </Collapse>

      <Collapse in={success && !isLoading}>
        <div className="flex--full-centered">
          <div className="submitted-wrapper">
            <div className="submitted-icon">
              <CheckmarkFilled32 />
            </div>
            <div className="submitted-message">Upload complete!</div>
            {onContinue && (
              <Button kind="ghost" size="small" onClick={handleContinue}>
                Continue
              </Button>
            )}
          </div>
        </div>
      </Collapse>

      <Collapse in={error && error.length > 0}>
        <InlineNotification
          lowContrast
          hideCloseButton
          kind="error"
          title="Upload error"
          subtitle={<UploadError error={error || ''} />}
          className="upload-error"
        />
      </Collapse>
    </div>
  )
}

const UploadError = ({ error }) => {
  if (typeof error === 'string') {
    return error
  }

  if (Array.isArray(error)) {
    return (
      <ul className="upload-error">
        {error.map((err) => (
          <li key={err}>{err}</li>
        ))}
      </ul>
    )
  }
}

export default Upload
