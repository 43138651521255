import moment from 'moment'

export const mapDataToChart = (data) => {
  if (!data) return []

  const keys = data.timeline
  const entities = Object.keys(data).filter((x) => x !== 'timeline')

  return entities.map((entity) => {
    return {
      id: entity,
      data: data[entity].map((staff, i) => {
        return {
          x: keys[i],
          y: staff,
        }
      }),
    }
  })
}

export const fillDataWithTime = (dataArray) => {
  const data = dataArray[0].data

  const dateStart = moment(data[0].x)
  const dateEnd = moment(data[data.length - 1].x)

  const datetimes = []

  while (dateEnd.diff(dateStart, 'minutes') >= 0) {
    datetimes.push(dateStart.format('YYYY-MM-DD HH:mm:ss'))
    dateStart.add(15, 'minutes')
  }

  const filledData = dataArray.map((d) => {
    return {
      id: d.id,
      data: datetimes.map((dt) => {
        const y = d.data.find((dd) => dd.x === dt)

        return {
          x: dt,
          y: y?.y || 0,
        }
      }),
    }
  })

  return filledData
}

export const mapDataToBarChart = (data) => {
  if (!data) return []

  const keys = data.timeline
  const entities = Object.keys(data).filter((x) => x !== 'timeline')

  return entities.map((entity) => {
    return {
      id: entity,
      data: data[entity].map((staff, i) => {
        return {
          datetime: moment(keys[i]).format('DD.MM HH:mm'),
          staff,
        }
      }),
    }
  })
}

export const generateTimeTicks = (timeline) => {
  return timeline
    .filter((x, i) => i % 10 === 0)
    .map((x) => moment(x).format('DD.MM HH:mm'))
}

export const findMaxY = (data) => {
  if (!data) return null

  const entities = Object.keys(data).filter((x) => x !== 'timeline')
  let maxY = 0

  entities.forEach((entity) => {
    const entityMax = Math.max(...data[entity])
    maxY = entityMax > maxY ? entityMax : maxY
  })

  return maxY
}
