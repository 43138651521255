import React, { useEffect, useState } from 'react'
import {
  Column,
  Grid,
  Row,
  TableHeader,
  TableRow,
  Table as TableCarbon,
  TableHead,
  TableContainer,
  Checkbox,
  DataTable,
} from 'carbon-components-react'
import { TableBody, TableCell } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Page } from '../../components/Page'
import { Sidebar } from './Sidebar'
import { useFreezableOperations } from '../preprocessing/redux/useFreezableOperations'
import { selectPlanningFreezeIds } from '../preprocessing/redux/selectors'
import {
  addFreezeId,
  addFreezeIds,
  removeFreezeId,
  removeFreezeIds,
} from '../preprocessing/redux/actions'
import { TransparentButton } from '../../components/transparent-button'
import { selectActiveScenario } from '../scenarios/redux/selectors'

const headers = [
  {
    key: 'checkbox',
    header: 'Freeze',
    orderable: false,
  },
  {
    key: 'operation',
    header: 'Operation',
  },
  {
    key: 'description',
    header: 'Description',
  },
  {
    key: 'responsible_entity',
    header: 'Work center',
  },
  {
    key: 'priority',
    header: 'Priority',
  },
  {
    key: 'func_loc',
    header: 'Functional location',
  },
]

const SelectColumn = styled(Column)`
  text-align: right;
  padding-top: 1.5rem;

  button {
    margin-left: 1rem;
  }
`

const Table = styled(TableCarbon)`
  tbody tr {
    ${(props) => !props.disabled && 'cursor: pointer;'}
  }
`

export const FreezeOperations = () => {
  const { uniqueCenters } = useFreezableOperations()
  const [selectedCenter, selectCenter] = useState(
    uniqueCenters.length > 0 ? uniqueCenters[0] : undefined,
  )
  const dispatch = useDispatch()
  const freezeIds = useSelector(selectPlanningFreezeIds)
  const activeScenario = useSelector(selectActiveScenario)

  const isLocked = !!activeScenario?.engine_job_id

  const handleChangeFreezeId = (freezeId, checked) => {
    if (checked) {
      dispatch(addFreezeId(freezeId))
    } else {
      dispatch(removeFreezeId(freezeId))
    }
  }

  useEffect(() => {
    if (selectedCenter) {
      const foundCenter = uniqueCenters.find(
        (findCenter) => findCenter.responsibleEntity === selectedCenter.responsibleEntity,
      )

      if (foundCenter) {
        selectCenter({
          ...foundCenter,
        })
      }
    }
  }, [uniqueCenters])

  const handleSelectAll = () => {
    if (!selectedCenter) {
      return
    }

    const toBeFrozen = selectedCenter.operations.map((operation) => parseInt(operation.id, 10))

    dispatch(addFreezeIds(toBeFrozen))
  }

  const handleClearAll = () => {
    const toBeCleared = selectedCenter.operations.map((operation) => parseInt(operation.id, 10))

    dispatch(removeFreezeIds(toBeCleared))
  }

  useEffect(() => {
    if (!selectedCenter && uniqueCenters.length > 0) {
      selectCenter(uniqueCenters[0])
    }
  }, [uniqueCenters])

  const rows = selectedCenter ? selectedCenter.operations : []

  return (
    <Page
      SidebarComponent={Sidebar}
      sidebarProps={{
        selectedCenter,
        uniqueCenters,
        selectCenter,
        isLocked,
      }}
    >
      <Grid fullWidth className="mb-2">
        <Row>
          <Column>
            <h4 className="mb-2">
              {selectedCenter ? (
                <span>
                  Select operations for {selectedCenter?.responsibleEntity} to
                  be frozen in current week
                </span>
              ) : (
                <span>No centers found</span>
              )}
            </h4>
          </Column>
          <SelectColumn>
            <TransparentButton onClick={handleClearAll} disabled={isLocked}>
              Clear all
            </TransparentButton>
            <TransparentButton onClick={handleSelectAll} disabled={isLocked}>
              Select all
            </TransparentButton>
          </SelectColumn>
        </Row>
        <Row>
          <Column>
            <DataTable rows={rows} headers={headers} isSortable>
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
              }) => (
                <TableContainer>
                  <Table
                    disabled={isLocked}
                    className="app-data-table"
                    {...getTableProps()}
                  >
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader
                            key={header.key}
                            {...getHeaderProps({ header })}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => {
                        const checked = freezeIds.includes(parseInt(row.id, 10))

                        return (
                          <TableRow
                            key={row.id}
                            {...getRowProps({ row })}
                            onClick={(event) => {
                              if (isLocked) {
                                return
                              }

                              handleChangeFreezeId(
                                parseInt(row.id, 10),
                                !checked,
                              )
                            }}
                          >
                            <TableCell>
                              <Checkbox
                                disabled={isLocked}
                                labelText=""
                                id={`freeze-${row.id}`}
                                key={row.id}
                                checked={checked}
                                onClick={(event) => {
                                  event.preventDefault()

                                  if (isLocked) {
                                    return
                                  }

                                  handleChangeFreezeId(
                                    parseInt(row.id, 10),
                                    event.target.checked,
                                  )
                                }}
                              />
                            </TableCell>
                            <TableCell>{row.cells[1].value}</TableCell>
                            <TableCell>{row.cells[2].value}</TableCell>
                            <TableCell>{row.cells[3].value}</TableCell>
                            <TableCell>{row.cells[4].value}</TableCell>
                            <TableCell>{row.cells[5].value}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
          </Column>
        </Row>
      </Grid>
    </Page>
  )
}
