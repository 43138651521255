export const UPLOAD_DATA = '[datasource] upload'
export const UPLOAD_DATA_SUCCESS = '[datasource] upload :success'
export const UPLOAD_DATA_FAIL = '[datasource] upload :fail'
export const GET_DATA = '[datasource] get'
export const GET_INITIAL_DATA = '[datasource] get initial'
export const GET_DATA_SUCCESS = '[datasource] get :success'
export const GET_DATA_FAIL = '[datasource] get :fail'
export const GET_FILES = '[datasource] get files'
export const GET_FILES_SUCCESS = '[datasource] get files :success'
export const GET_FILES_FAIL = '[datasource] get files :fail'
export const DELETE_FILE = '[datasource] DELETE file'
export const DELETE_FILE_SUCCESS = '[datasource] DELETE file :success'
export const DELETE_FILE_FAIL = '[datasource] DELETE file :fail'
export const SET_SELECTED_FILE = '[datasource] set file'
export const SET_DATA_STATE = '[datasource] set data state'
export const SET_UPLOAD_STATE = '[datasource] set upload state'
export const SET_MAPPED_DATA = '[datasource] set mappeddata state'
export const SET_UPLOAD_MODAL_OPEN = '[datasource] set modal'

export const uploadData = (payload) => ({
  type: UPLOAD_DATA,
  payload,
})

export const uploadDataSuccess = (payload) => ({
  type: UPLOAD_DATA_SUCCESS,
  payload,
})

export const uploadDataFail = (payload) => ({
  type: UPLOAD_DATA_FAIL,
  payload,
})

export const getData = (payload) => ({
  type: GET_DATA,
  payload,
})

export const getInitialData = (payload) => ({
  type: GET_INITIAL_DATA,
  payload,
})

export const getDataSuccess = (payload) => ({
  type: GET_DATA_SUCCESS,
  payload,
})

export const getDataFail = (payload) => ({
  type: GET_DATA_FAIL,
  payload,
})

export const getFiles = (payload) => ({
  type: GET_FILES,
  payload,
})

export const getFilesSuccess = (payload) => ({
  type: GET_FILES_SUCCESS,
  payload,
})

export const getFilesFail = (payload) => ({
  type: GET_FILES_FAIL,
  payload,
})

export const setSelectedFile = (payload) => ({
  type: SET_SELECTED_FILE,
  payload,
})

export const setDataState = (payload) => ({
  type: SET_DATA_STATE,
  payload,
})

export const setUploadState = (payload) => ({
  type: SET_UPLOAD_STATE,
  payload,
})

export const setMappedData = (payload) => ({
  type: SET_MAPPED_DATA,
  payload,
})

export const setUploadModalOpen = (payload) => ({
  type: SET_UPLOAD_MODAL_OPEN,
  payload,
})

export const deleteFile = (payload) => ({
  type: DELETE_FILE,
  payload,
})

export const deleteFileSuccess = (payload) => ({
  type: DELETE_FILE_SUCCESS,
  payload,
})

export const deleteFileFail = (payload) => ({
  type: DELETE_FILE_FAIL,
  payload,
})
