import React, { useMemo, useState } from 'react'
import moment from 'moment'
import { AddAlt16, SubtractAlt16 } from '@carbon/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextInput } from 'carbon-components-react'

import {
  selectInputStops,
  selectInputWorkcenters,
} from '../preprocessing/redux/selectors'
import {
  selectPlanningDailyResources,
  selectPlanningResources,
} from './redux/selectors'
import { setDailyResources } from './redux/actions'
import ComboSelect from '../../components/ComboSelect'
import { round } from '../../utils/Math'

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

const DailyResources = ({ disabled }) => {
  const dispatch = useDispatch()
  const [selectedWeekday, setSelectedWeekday] = useState('')
  const stops = useSelector(selectInputStops)
  const dailyResources = useSelector(selectPlanningDailyResources)
  const resources = useSelector(selectPlanningResources)
  const workcenters = useSelector(selectInputWorkcenters)

  // Memos
  const stopsPerWeekday = useMemo(() => {
    const weekdays = {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
    }

    Object.keys(stops).forEach((id) => {
      if (!stops[id] || !stops[id].start) {
        return
      }
      const day = moment(stops[id].start).isoWeekday() // Returns 1-7, 1 = Monday
      const weekday = Object.keys(weekdays)[day - 1]
      weekdays[weekday].push({ id, ...stops[id] })
    })

    return weekdays
  }, [stops])

  // Methods
  const onAvailabilityChange = (weekday, workcenter, value) => () => {
    dispatch(
      setDailyResources({
        weekday,
        workcenter,
        key: 'availability',
        value: value >= 0 ? value : 0,
      }),
    )
  }

  const onAvailabilityInputChange = (weekday, workcenter, value) => {
    dispatch(
      setDailyResources({
        weekday,
        workcenter,
        key: 'availability',
        value,
      }),
    )
  }

  const onWeekdayChange = (selected) => {
    setSelectedWeekday(selected)
  }

  if (!workcenters?.length) {
    return <div>There are no work centers selected.</div>
  }

  // Render
  return (
    <div>
      <div className="mb-2">
        <ComboSelect
          id="weekday"
          items={WEEKDAYS}
          selectedItems={selectedWeekday || ''}
          labelText="Select weekday"
          placeholder="Weekday"
          getOptionLabel={(item) => item}
          onChange={onWeekdayChange}
        />
      </div>

      {dailyResources && resources && stopsPerWeekday && selectedWeekday && (
        <React.Fragment key={selectedWeekday}>
          {stopsPerWeekday[selectedWeekday].length > 0 && (
            <>
              <span className="bx--label">Stops</span>
              <ul className="ul color--grey mb-1">
                {stopsPerWeekday[selectedWeekday].map((stop) => (
                  <li key={stop.id}>
                    {stop.start} - Func.loc {stop.location}
                  </li>
                ))}
              </ul>
            </>
          )}

          <ul className="stops-resources">
            <li>
              <div />
              <div className="text--center">Staff</div>
            </li>
            {dailyResources
              && dailyResources[selectedWeekday]
              && Object.keys(dailyResources[selectedWeekday])
                .sort()
                .map((workcenter) => {
                  const { availability } = dailyResources[selectedWeekday][workcenter]
                  // const minAvailability =
                  //   resources[workcenter].availability || 0

                  return (
                    <li key={workcenter}>
                      <div>{workcenter}</div>
                      <div className="stops-resources-controls">
                        <Button
                          hasIconOnly
                          renderIcon={SubtractAlt16}
                          iconDescription="Subtract"
                          size="small"
                          kind="ghost"
                          disabled={availability === 0 || disabled}
                          onClick={onAvailabilityChange(
                            selectedWeekday,
                            workcenter,
                            round(parseFloat(availability) - 0.1, 1),
                          )}
                        />
                        <TextInput
                          value={availability || 0}
                          labelText=""
                          id="availability"
                          min="0"
                          max="999"
                          step={0.1}
                          type="number"
                          disabled={disabled}
                          onChange={(e) => onAvailabilityInputChange(
                            selectedWeekday,
                            workcenter,
                            e.target.value,
                          )}
                          onBlur={(e) => onAvailabilityInputChange(
                            selectedWeekday,
                            workcenter,
                            round(e.target.value, 1),
                          )}
                        />
                        <Button
                          hasIconOnly
                          renderIcon={AddAlt16}
                          iconDescription="Add"
                          size="small"
                          kind="ghost"
                          disabled={disabled}
                          onClick={onAvailabilityChange(
                            selectedWeekday,
                            workcenter,
                            round(parseFloat(availability) + 0.1, 1),
                          )}
                        />
                      </div>
                    </li>
                  )
                })}
          </ul>
        </React.Fragment>
      )}
    </div>
  )
}

export default DailyResources
