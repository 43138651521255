import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid, Row, Column, Loading,
} from 'carbon-components-react'

import {
  selectDatasourceData,
  selectDatasourceDataIsFetching,
} from '../datasource/redux/selectors'
import { selectInputPriority, selectOutputData } from './redux/selectors'
import { mapDataToChart } from './redux/utils'
import * as actions from './redux/actions'

import { Page } from '../../components/Page'
import Breakdown from './Breakdown'
import Charts from './Charts'
import Sidebar from './Sidebar'
import { selectActiveScenario } from '../scenarios/redux/selectors'

export default function ManageData() {
  const dispatch = useDispatch()

  const operations = useSelector(selectDatasourceData)
  const selectedPriorities = useSelector(selectInputPriority)
  const filteredData = useSelector(selectOutputData)
  const isFetching = useSelector(selectDatasourceDataIsFetching)
  const activeScenario = useSelector(selectActiveScenario)

  const mappedOperations = useMemo(
    () => mapDataToChart(operations, filteredData),
    [operations, filteredData],
  )
  const stopsEnabled = selectedPriorities ? selectedPriorities.S : false

  const handleBarClick = (bar) => {
    dispatch(actions.setInputState({ selectedBar: bar.data.label }))
  }

  const SidebarComponent = operations?.length ? Sidebar : null
  const isLocked = !!activeScenario?.engine_job_id

  const sidebarProps = {
    isLocked,
    stopsEnabled,
  }

  return (
    <Page SidebarComponent={SidebarComponent} sidebarProps={sidebarProps}>
      {isFetching && (
        <Grid fullWidth>
          <Row>
            <Column>
              <div className="app-loading-full">
                <Loading withOverlay={false} />
              </div>
            </Column>
          </Row>
        </Grid>
      )}

      {!isFetching && operations?.length > 0 && (
        <>
          <Grid fullWidth>
            <Row>
              <Column>
                {operations?.length > 0 && (
                  <Charts
                    operations={mappedOperations}
                    filteredData={filteredData}
                    handleBarClick={handleBarClick}
                  />
                )}
              </Column>
            </Row>
          </Grid>

          <Breakdown />
        </>
      )}
    </Page>
  )
}
