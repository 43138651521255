import { combineReducers } from 'redux'

import {
  GET_FILTERED_DATA,
  GET_FILTERED_DATA_FAIL,
  GET_FILTERED_DATA_SUCCESS,
  SET_INPUT,
  SET_INPUT_STATE,
  SET_INPUT_WORKCENTERS,
  SET_INPUT_IKKE,
  SET_STOPS,
  CLEAR_INPUT_STATE,
  ADD_FREEZE_ID,
  ADD_FREEZE_IDS,
  CLEAR_FREEZE_IDS,
  REMOVE_FREEZE_ID,
  REMOVE_FREEZE_IDS,
  SET_FREEZE_IDS,
  SET_INPUT_REVISION_CODES,
  SET_RETAIN_OPERATIONS,
} from './actions'
import { SET_ACTIVE_SCENARIO } from '../../scenarios/redux/actions'

const inputState = {
  workcenters: [],
  revisionCodes: [],
  operationStatus: {},
  approval: {},
  priority: {},
  retainOperations: [],
  freezeIds: [],
  ikke: false,
  stops: {},
  selectedBar: null,
}

const outputState = {
  error: null,
  data: null,
}

const input = (state = { ...inputState }, { type, payload }) => {
  switch (type) {
    case SET_INPUT:
      return {
        ...state,
        [payload.name]: Array.isArray(payload.value)
          ? payload.value
          : {
              ...state[payload.name],
              ...payload.value,
            },
      }

    case SET_ACTIVE_SCENARIO:
    case CLEAR_INPUT_STATE:
      return {
        ...state,
        ...inputState,
      }

    case SET_INPUT_REVISION_CODES:
      return {
        ...state,
        revisionCodes: payload,
      }

    case SET_INPUT_WORKCENTERS:
      return {
        ...state,
        workcenters: payload.sort(),
      }

    case SET_INPUT_STATE:
      return {
        ...state,
        ...payload,
      }

    case SET_INPUT_IKKE:
      return {
        ...state,
        ikke: payload,
      }

    case SET_STOPS:
      return {
        ...state,
        stops: payload,
      }

    case CLEAR_FREEZE_IDS: {
      return {
        ...state,
        freezeIds: [],
      }
    }

    case SET_FREEZE_IDS: {
      return {
        ...state,
        freezeIds: payload,
      }
    }

    case ADD_FREEZE_ID: {
      if (state.freezeIds.includes(payload)) {
        return { ...state }
      }

      return {
        ...state,
        freezeIds: [...state.freezeIds, payload],
      }
    }

    case ADD_FREEZE_IDS: {
      const newIds = payload.filter((id) => !state.freezeIds.includes(id))

      return {
        ...state,
        freezeIds: [...state.freezeIds, ...newIds],
      }
    }

    case REMOVE_FREEZE_ID:
      return {
        ...state,
        freezeIds: state.freezeIds.filter((freezeId) => freezeId !== payload),
      }

    case REMOVE_FREEZE_IDS:
      return {
        ...state,
        freezeIds: state.freezeIds.filter(
          (freezeId) => !payload.includes(freezeId),
        ),
      }

    case SET_RETAIN_OPERATIONS:
      return {
        ...state,
        retainOperations: payload,
      }

    default:
      return state
  }
}

const output = (state = outputState, { type, payload }) => {
  switch (type) {
    case GET_FILTERED_DATA:
      return {
        ...state,
        //data: null,
        isFetching: true,
        error: null,
      }
    case GET_FILTERED_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        data: payload,
      }
    case GET_FILTERED_DATA_FAIL:
      return {
        ...state,
        isFetching: false,
        error: payload,
        data: null,
      }
    default:
      return state
  }
}

export default combineReducers({
  input,
  output,
})
