import { client } from '../../../api/client'

async function fetchEngineJobs() {
  const response = await client({
    method: 'GET',
    url: '/engine_jobs',
  })
  return response.data
}

export default {
  fetchEngineJobs,
}
