import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Papa from 'papaparse'
import Case from 'case'
import moment from 'moment'
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Pagination,
} from 'carbon-components-react'
import { Delete16 as DeleteCarbon, Download16 as Download } from '@carbon/icons-react'
import { Tooltip } from '@whitespace/ui'
import { client as apiClient } from '../../api/client'
import * as actions from './redux/actions'
import { selectDatasourceFiles } from './redux/selectors'
import { IconButton } from '../../components/IconButton'
import { useCanDelete } from '../scenarios/redux/useCanDelete'
import styled from '@emotion/styled'

const PAGE_SIZES = [10, 20, 50, 100, 200]

export default function UploadTable() {
  // Hooks
  const dispatch = useDispatch()
  const [paginatedRows, setPaginatedRows] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const files = useSelector(selectDatasourceFiles)
  const { canDelete } = useCanDelete()

  const onPaginationChange = (pagination) => {
    setPage(pagination.page)
    setPageSize(pagination.pageSize)
  }

  const paginate = (initialRows, page, pageSize) => {
    const rows = [...initialRows]
    return rows.splice((page - 1) * pageSize, pageSize)
  }

  const onDelete = (id) => () => {
    dispatch(actions.deleteFile(id))
  }

  const onDownload = (id) => async () => {
    try {
      const currentFile = files.find((file) => file.id === parseInt(id, 10))

      if (!currentFile) {
        throw new Error("This file doesn't exist")
      }

      const response = await apiClient({
        method: 'GET',
        url: '/operations_data_rows',
        params: {
          csv_upload_id: id,
        },
      })

      let { data } = response

      if (!data || !data.length) {
        throw new Error('There is no data available')
      }

      data = data.map((entry) => {
        const entryObject = {}
        Object.keys(entry).forEach((key) => {
          switch (key) {
            case 'func_loc':
              entryObject['Func.Loc'] = encodeURIComponent(entry[key])
              break
            case 'plan_ikke':
              entryObject['PLAN/IKKE'] = entry[key]
              break
            case 'scheduled_start_date':
              entryObject.ScheduledStartDateTime = moment(entry[key]).format(
                'DD.MM.YYYY HH:mm:ss',
              )
              break
            case 'scheduled_end_date':
              entryObject.ScheduledEndDateTime = moment(entry[key]).format(
                'DD.MM.YYYY HH:mm:ss',
              )
              break
            case 'id':
              break
            default:
              entryObject[Case.pascal(key)] = entry[key]
          }
        })
        return entryObject
      })

      const csv = Papa.unparse(data, {
        delimiter: ';',
      })

      const blob = new Blob([csv])
      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = currentFile.name.substr(-3) === 'csv'
        ? currentFile.name
        : `${currentFile.name}.csv`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (err) {
      console.error(err)
    }
  }

  const rows = useMemo(() => {
    if (!files || !files.length) return []
    return [...files]
      .sort((a, b) => (b.created > a.created ? 1 : -1))
      .map((file) => ({
        ...file,
        id: String(file.id),
        created: moment(file.created).format('YYYY-MM-DD'),
      }))
  }, [files])

  const headers = [
    {
      key: 'name',
      header: 'CSV file name',
    },
    {
      key: 'created',
      header: 'Uploaded on',
    },
  ]

  useEffect(() => {
    if (rows?.length) {
      const paginatedRows = paginate(rows, page, pageSize)
      setPaginatedRows(paginatedRows)

      if (rows.length <= (page - 1) * pageSize) {
        setPage(1)
      }
    }
  }, [rows, page, pageSize])

  if (!rows || !headers) return null

  return (
    <div>
      <DataTable rows={paginatedRows} headers={headers} isSortable>
        {({
          rows, headers, getHeaderProps, getRowProps, getTableProps,
        }) => (
          <TableContainer>
            <Table className="app-data-table" {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                  <TableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell
                      style={{ textAlign: 'right', position: 'relative' }}
                    >
                      <Tooltip content="Download file" position="left">
                        <IconButton onClick={onDownload(row.id)}>
                          <Download />
                        </IconButton>
                      </Tooltip>
                      <DeleteFile
                        id={row.id}
                        onDelete={onDelete(row.id)}
                        disabled={!canDelete(parseInt(row.id, 10))}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <Pagination
        page={page}
        totalItems={rows?.length || 0}
        pageSizes={PAGE_SIZES}
        pageSize={pageSize}
        onChange={onPaginationChange}
      />
    </div>
  )
}

const Delete = styled(DeleteCarbon)`
  &[aria-disabled="true"] {
    color: #d0d0d0;
  }
`

function DeleteFile({ onDelete, disabled = false }) {
  const [confirm, setConfirm] = useState(false)

  const handleDelete = () => {
    setConfirm(true)
  }

  const onConfirmDelete = () => {
    onDelete()
    setConfirm(false)
  }

  const onCancelConfirm = () => {
    setConfirm(false)
  }

  return (
    <>
      {!confirm && (
        <Tooltip content={disabled ? 'Datasource is still in use' : 'Delete file'} position="left">
          <IconButton onClick={handleDelete} disabled={disabled}>
            <Delete aria-disabled={disabled} />
          </IconButton>
        </Tooltip>
      )}
      {confirm && (
        <div className="app-inline-prompt">
          <Button
            size="small"
            kind="danger"
            onClick={onConfirmDelete}
            style={{ marginLeft: 'auto' }}
          >
            Confirm?
          </Button>
          <Button size="small" kind="ghost" onClick={onCancelConfirm}>
            Cancel
          </Button>
        </div>
      )}
    </>
  )
}
