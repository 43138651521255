import React from 'react'
import ReactDOM from 'react-dom'
import { ComposedModal, ModalBody, ModalHeader } from 'carbon-components-react'

const WarningsModal = ({ open, onClose, warnings, week }) => {
  // Render
  return ReactDOM.createPortal(
    <ComposedModal
      open={open}
      className="app-modal warnings-modal"
      onClose={onClose}
    >
      <ModalHeader title="Data validation" />
      <ModalBody>
        <p className="mb-1 width-70">
          You are about to run the engine but some operations that are scheduled
          for <strong>week {week}</strong> have been filtered out:
        </p>
        {warnings?.length > 0 && (
          <ul>
            {warnings.map((warning) => (
              <li key={warning}>{warning}</li>
            ))}
          </ul>
        )}
      </ModalBody>
    </ComposedModal>,
    document.body
  )
}

export default WarningsModal
