import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid'
import moment from 'moment'
import {
  Grid,
  Row,
  Column,
  Button,
  TextInput,
  DatePicker,
  DatePickerInput,
} from 'carbon-components-react'
import { Delete16 } from '@carbon/icons-react'
import ComboSelect from '../../components/ComboSelect'
import { StatefulComboSelect } from '../../components/StatefulComboSelect'
import { selectInputPriority, selectInputStops } from './redux/selectors'
import { setStops } from './redux/actions'
import { selectActiveScenario } from '../scenarios/redux/selectors'
import { selectDatasourceData } from '../datasource/redux/selectors'
import {
  selectPlanningDesiredWeek,
  selectPlanningDesiredYear,
} from '../planning/redux/selectors'

export default function Stops({ onClose }) {
  const [localStops, setLocalStops] = useState({})

  const dispatch = useDispatch()
  const stops = useSelector(selectInputStops)
  const priority = useSelector(selectInputPriority)
  const operations = useSelector(selectDatasourceData)
  const activeScenario = useSelector(selectActiveScenario)
  const desiredWeek = useSelector(selectPlanningDesiredWeek)
  const desiredYear = useSelector(selectPlanningDesiredYear)

  const isLocked = !!activeScenario?.engine_job_id
  const isDisabled = !priority.S || isLocked

  // Effects
  useEffect(() => {
    if (stops) {
      setLocalStops(stops)
    }
  }, [stops])

  // Memos
  const revisionCodes = useMemo(() => (operations?.length
    ? [
      ...new Set(operations.map((op) => op.revision_code).filter((x) => x)),
    ].sort()
    : []), [operations])

  const locations = useMemo(() => (operations?.length
    ? [
      ...new Set(
        operations.map((op) => op.func_loc.substr(0, 3)).filter((x) => x),
      ),
    ].sort((a, b) => a - b)
    : []), [operations])

  const minDate = moment()
    .year(desiredYear)
    .isoWeek(desiredWeek)
    .day(1)
    .format('YYYY/MM/DD')

  const maxDate = moment(minDate).add(4, 'days').format('YYYY/MM/DD')

  // Methods
  const onLocationChange = (id) => (value) => {
    const stopsCopy = { ...localStops }
    stopsCopy[id].location = value
    setLocalStops(stopsCopy)
  }

  const onStopDateChange = (id) => (value) => {
    const stopsCopy = { ...localStops }
    stopsCopy[id].start = value[0] ? moment(value[0]).format('YYYY/MM/DD') : ''
    setLocalStops(stopsCopy)
  }

  const onDurationChange = (id) => (e) => {
    const stopsCopy = { ...localStops }
    const { value } = e.target
    stopsCopy[id].duration = parseInt(value)
    setLocalStops(stopsCopy)
  }

  const onCodeChange = (id) => (value) => {
    const stopsCopy = { ...localStops }
    stopsCopy[id].code = value
    setLocalStops(stopsCopy)
  }

  const onAddNewStop = () => {
    const newStop = {
      ...localStops,
      [uuid()]: {
        location: '',
        start: '',
        duration: 1,
        code: [],
      },
    }
    setLocalStops(newStop)
  }

  const onDeleteStop = (id) => () => {
    const stopsCopy = { ...localStops }
    delete stopsCopy[id]
    setLocalStops(stopsCopy)
  }

  const onDone = () => {
    onClose()
    dispatch(setStops(localStops))
  }

  return (
    <Grid fullWidth style={{ padding: 0 }}>
      <Row>
        <Column>
          {Object.keys(localStops).map((id) => (
            <div className="stops-container" key={id}>
              <ComboSelect
                light
                id="location"
                items={locations}
                selectedItems={localStops[id].location}
                labelText="Functional location"
                placeholder="Choose location"
                getOptionLabel={(item) => item}
                onChange={onLocationChange(id)}
                disabled={isDisabled}
              />

              <DatePicker
                light
                datePickerType="single"
                dateFormat="Y/m/d"
                minDate={minDate}
                maxDate={maxDate}
                onChange={onStopDateChange(id)}
              >
                <DatePickerInput
                  autoComplete="off"
                  id="start"
                  placeholder="yyyy/mm/dd"
                  labelText="Start of stop (day)"
                  disabled={isDisabled}
                  value={
                    localStops[id].start
                      ? moment(localStops[id].start).format('YYYY/MM/DD')
                      : ''
                  }
                />
              </DatePicker>

              <TextInput
                light
                id="duration"
                labelText="Stop duration (hours)"
                type="number"
                min="1"
                max="16"
                value={localStops[id].duration}
                onChange={onDurationChange(id)}
                disabled={isDisabled}
              />
              <StatefulComboSelect
                light
                multiple
                id="code"
                items={revisionCodes}
                selectedItems={localStops[id].code}
                labelText="Revision codes"
                placeholder="Revision codes"
                getOptionLabel={(item) => item}
                onClose={onCodeChange(id)}
                disabled={isDisabled}
              />
              <div className="stop-actions">
                <Button
                  hasIconOnly
                  renderIcon={Delete16}
                  size="small"
                  kind="ghost"
                  iconDescription="Delete"
                  tooltipPosition="left"
                  onClick={onDeleteStop(id)}
                  disabled={isDisabled}
                />
              </div>
            </div>
          ))}
          <div className="btn-group">
            <Button
              kind="tertiary"
              size="field"
              onClick={onAddNewStop}
              disabled={isDisabled}
            >
              Add new stop
            </Button>
            <Button kind="primary" size="field" onClick={onDone}>
              Done
            </Button>
          </div>
        </Column>
      </Row>
    </Grid>
  )
}
