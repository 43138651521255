import { takeLatest, put, select } from 'redux-saga/effects'
import * as actions from './actions'
import { resetEngine } from '../../engine/redux/actions'
import { setResults } from '../../results/redux/actions'
import * as api from './api'
import {
  mapFilterParamsToRedux,
  mapPlanningParamsToRedux,
  mapStopsParamsToRedux,
} from './utils'
import {
  clearInputState,
  setInputState,
  setFreezeIds,
  clearFreezeIds,
} from '../../preprocessing/redux/actions'
import {
  setInput as setPlanningInputState,
  resetInput as resetPlanningInputState,
} from '../../planning/redux/actions'
import { selectActiveScenario } from './selectors'

function* getScenarios() {
  try {
    const response = yield api.getScenarios()
    yield put(actions.getScenariosSuccess(response))
  } catch (err) {
    yield put(actions.getScenariosFail())
  }
}

function* createScenario({ payload: body }) {
  try {
    const response = yield api.createScenario(body)
    yield put(actions.createScenarioSuccess(response))
    yield put(actions.setActiveScenario(response))
  } catch (err) {
    yield put(actions.createScenarioFail())
  }
}

function* cloneScenario({ payload }) {
  try {
    const response = yield api.cloneScenario(payload)
    yield put(actions.cloneScenarioSuccess(response))
    yield put(actions.setActiveScenario(response))
  } catch (err) {
    yield put(actions.cloneScenarioFail())
  }
}

function* deleteScenario({ payload: id }) {
  try {
    const activeScenario = yield select(selectActiveScenario)
    yield api.deleteScenario(id)

    yield put(actions.deleteScenarioSuccess(id))

    if (activeScenario?.id === id) {
      localStorage.setItem('activeScenarioId', 0)
    }
  } catch (err) {
    yield put(actions.deleteScenarioFail())
  }
}

function* updateScenario({ payload }) {
  try {
    const activeScenario = yield select(selectActiveScenario)
    const response = yield api.updateScenario(payload)

    yield put(actions.updateScenarioSuccess(response))

    if (response.id === activeScenario.id) {
      yield put(actions.setActiveScenario(response))
    }
  } catch (err) {
    yield put(actions.updateScenarioFail())
  }
}

function* setActiveScenario({ payload: scenario }) {
  try {
    if (!scenario) return
    yield put(resetEngine())
    yield put(setResults(null))

    // Set scenario in localStorage
    if (scenario) {
      localStorage.setItem('activeScenarioId', scenario.id)
    }

    if (scenario.engine_job_id) {
      // Preprocessing params
      const filterParams = yield api.getScenarioFilterParams(scenario.id)
      const stopsParams = yield api.getScenarioStopsParams(scenario.id)
      const preprocessingInput = {
        ...mapFilterParamsToRedux(filterParams),
        stops: mapStopsParamsToRedux(stopsParams),
      }
      yield put(setInputState(preprocessingInput))
    } else {
      yield put(clearInputState())
      yield put(clearFreezeIds())
    }

    // Planning params
    if (scenario.input_parameter_set_id) {
      const planningParams = yield api.getScenarioPlanningParams(
        scenario.input_parameter_set_id,
      )

      const planningInput = mapPlanningParamsToRedux(planningParams)

      yield put(setPlanningInputState(planningInput))
    } else {
      yield put(resetPlanningInputState())
    }
  } catch (err) {}
}

export default function* sagas() {
  yield takeLatest(actions.GET_SCENARIOS, getScenarios)
  yield takeLatest(actions.CREATE_SCENARIO, createScenario)
  yield takeLatest(actions.CLONE_SCENARIO, cloneScenario)
  yield takeLatest(actions.UPDATE_SCENARIO, updateScenario)
  yield takeLatest(actions.DELETE_SCENARIO, deleteScenario)
  yield takeLatest(actions.SET_ACTIVE_SCENARIO, setActiveScenario)
}
