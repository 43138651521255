// Upload
export const selectDatasourceFiles = (state) => state.datasource.upload.files
export const selectDatasourceUploadSuccess = (state) => state.datasource.upload.success
export const selectDatasourceUploadError = (state) => state.datasource.upload.error
export const selectDatasourceUploading = (state) => state.datasource.upload.isUploading
export const selectDatasourceModalOpen = (state) => state.datasource.upload.modalOpen

// Data
export const selectDatasourceData = (state) => state.datasource.data.data
export const selectDatasourceDataIsFetching = (state) => state.datasource.data.isFetching
export const selectDatasourceSelectedFile = (state) => state.datasource.data.selectedFile
export const selectDatasourceDataError = (state) => state.datasource.data.error
export const selectDatasourceDataSuccess = (state) => state.datasource.data.success

// Mapped Data
export const selectDatasourceWorkcenters = (state) => state.datasource.mappedData.workcenters
export const selectDatasourceOperationStatuses = (state) => state.datasource.mappedData.operationStatuses
export const selectDatasourceApprovals = (state) => state.datasource.mappedData.approvals
export const selectDatasourcePriorities = (state) => state.datasource.mappedData.priorities
