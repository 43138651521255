import {
  GET_ENGINE_JOBS,
  GET_ENGINE_JOBS_SUCCESS,
  GET_ENGINE_JOBS_FAIL,
} from './actions'

const initialState = {
  isFetching: false,
  error: null,
  jobsById: null,
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ENGINE_JOBS:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case GET_ENGINE_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        jobsById: putEngineJobs(state, payload),
      }
    case GET_ENGINE_JOBS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: payload,
      }

    default:
      return state
  }
}

function putEngineJobs(state, payload) {
  if (!Array.isArray(payload)) {
    return state
  }

  const jobs = {}

  payload.forEach((job) => {
    jobs[job.id] = job
  })

  return jobs
}

export default reducer
