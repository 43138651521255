export const round = (value: number, decimals = 1): number => {
  return Math.round(value * 10 ** decimals) / 10 ** decimals
}

export const floor = (value: number, decimals = 1): number => {
  return Math.floor(value * 10 ** decimals) / 10 ** decimals
}

export const ceil = (value: number, decimals = 1): number => {
  return Math.ceil(value * 10 ** decimals) / 10 ** decimals
}

export const toFixed = (value: number, decimals = 1): string => {
  return `${round(value, decimals)}`
}
