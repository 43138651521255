import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import {
  Checkbox,
  TextInput,
  Accordion,
  AccordionItem,
  Button,
} from 'carbon-components-react'
import { Edit16 } from '@carbon/icons-react'
import styled from '@emotion/styled'
import {
  selectDatasourceWorkcenters,
  selectDatasourceOperationStatuses,
  selectDatasourcePriorities,
} from '../datasource/redux/selectors'
import {
  selectInputWorkcenters,
  selectInputPriority,
  selectInputStatusIkke,
  selectInputStops,
} from './redux/selectors'
import {
  selectPlanningDesiredWeek,
  selectPlanningDesiredYear,
  selectPlanningResources,
} from '../planning/redux/selectors'
import { setInput, setInputWorkcenters, setInputIkke } from './redux/actions'
import { mapResourceAvailability, mapResourceCapacity } from './redux/utils'
import { groupBy } from '../../utils'
import {
  setResourcesList,
  setInput as setPlanningInput,
} from '../planning/redux/actions'

import { StatefulComboSelect } from '../../components/StatefulComboSelect'
import StopsModal from './StopsModal'
import { SiderbarRevisionCodes } from './SiderbarRevisionCodes'
import { PreviousWeekOperations } from './PreviousWeekOperations'

const CheckboxContainer = styled.div`
  .bx--checkbox-wrapper {
    &:first-of-type {
      margin-top: unset !important;
    }

    &:last-of-type {
      margin-top: unset !important;
    }
  }
`

export default function Sidebar({ isLocked, stopsEnabled }) {
  const dispatch = useDispatch()
  const [isModalOpen, setModalOpen] = useState(false)
  const workCenters = useSelector(selectDatasourceWorkcenters)
  const operationStatuses = useSelector(selectDatasourceOperationStatuses)
  const priorities = useSelector(selectDatasourcePriorities)

  const selectedWorkcenters = useSelector(selectInputWorkcenters)
  const selectedPriorities = useSelector(selectInputPriority)
  const selectedIkke = useSelector(selectInputStatusIkke)
  const stops = useSelector(selectInputStops)

  const desiredWeek = useSelector(selectPlanningDesiredWeek)
  const desiredYear = useSelector(selectPlanningDesiredYear)
  const resources = useSelector(selectPlanningResources)

  useEffect(() => {
    if (operationStatuses && operationStatuses.length === 1) {
      dispatch(
        setInput({
          name: 'operationStatus',
          value: { [operationStatuses[0]]: true },
        }),
      )
    }
  }, [operationStatuses, dispatch])

  const stopList = useMemo(() => {
    if (!stops) return []

    const list = Object.keys(stops)
      .map((id) => {
        if (!stops[id].start || !stops[id].location) {
          return null
        }
        return {
          ...stops[id],
          id,
        }
      })
      .filter((x) => x)

    const stopMap = groupBy(list, 'location')

    return Object.keys(stopMap)
      .filter((x) => x)
      .map((location) => ({
        location,
        count: stopMap[location].length,
      }))
  }, [stops])

  const onWorkcentersChange = (value) => {
    dispatch(setInputWorkcenters(value))

    // Handle resources
    const resourcesList = { ...resources }

    value.forEach((workcenter) => {
      if (!resources[workcenter]) {
        resourcesList[workcenter] = {
          availability: mapResourceAvailability(workcenter),
          capacity: mapResourceCapacity(workcenter),
        }
      }
    })

    Object.keys(resourcesList).forEach((workcenter) => {
      if (!value.includes(workcenter)) {
        delete resourcesList[workcenter]
      }
    })

    dispatch(setResourcesList(resourcesList))
  }

  const onCheckboxChange = (name, option) => (value) => {
    dispatch(
      setInput({
        name,
        value: {
          [option]: value,
        },
      }),
    )
  }

  const onInputIkkeChange = (value) => {
    dispatch(setInputIkke(value))
  }

  const onSelectAllWorkcenters = () => {
    const selected = selectedWorkcenters.length === workCenters.length ? [] : workCenters

    onWorkcentersChange(selected)
  }

  const handleWeekChoice = (e) => {
    dispatch(
      setPlanningInput({
        desiredWeek: parseInt(e.target.value),
      }),
    )
  }

  const handleYearChoice = (e) => {
    dispatch(
      setPlanningInput({
        desiredYear: parseInt(e.target.value),
      }),
    )
  }

  const onModalClose = () => {
    setModalOpen(false)
  }

  const onModalOpen = () => {
    if (!selectedPriorities.S) {
      dispatch(
        setInput({
          name: 'priority',
          value: {
            S: true,
          },
        }),
      )
    }
    setModalOpen(true)
  }

  return (
    <>
      <Accordion>
        <AccordionItem open title="Planning window">
          <div className="half-flex">
            <TextInput
              inline
              type="number"
              labelText="Year"
              id="desired-year"
              value={desiredYear || ''}
              disabled={isLocked}
              onChange={handleYearChoice}
            />
            <TextInput
              inline
              type="number"
              labelText="Week"
              id="desired-week"
              min="1"
              max="53"
              value={desiredWeek || ''}
              disabled={isLocked}
              onChange={handleWeekChoice}
            />
          </div>
        </AccordionItem>

        <AccordionItem open title="Work centers">
          <div className="mb-1">
            <div className="flexbox">
              <span
                className={clsx('bx--label', {
                  'bx--label--disabled': isLocked,
                })}
              >
                Work centers
              </span>
              {!isLocked && (
                <a
                  href="#"
                  onClick={onSelectAllWorkcenters}
                  style={{ marginLeft: 'auto' }}
                >
                  <span className="bx--label">
                    {selectedWorkcenters.length > 0
                    && selectedWorkcenters.length === workCenters.length
                      ? 'Deselect all'
                      : 'Select all'}
                  </span>
                </a>
              )}
            </div>
            <StatefulComboSelect
              multiple
              id="workcenters"
              items={workCenters || []}
              selectedItems={selectedWorkcenters || []}
              placeholder="Work centers"
              disabled={isLocked}
              onClose={onWorkcentersChange}
            />
          </div>
        </AccordionItem>

        <SiderbarRevisionCodes isLocked={isLocked} />

        <PreviousWeekOperations isLocked={isLocked} />

        <AccordionItem open title="Priority &amp; status">
          {priorities?.length > 0 && (
            <fieldset className="bx--fieldset">
              <legend className="bx--label">Priority</legend>

              <CheckboxContainer className="flexbox">
                {priorities.map((item) => (
                  <Checkbox
                    labelText={item}
                    id={`priority-${item}`}
                    key={item}
                    checked={selectedPriorities[item] || false}
                    disabled={isLocked}
                    onChange={onCheckboxChange('priority', item)}
                  />
                ))}
              </CheckboxContainer>
            </fieldset>
          )}
          <fieldset className="bx--fieldset">
            <legend className="bx--label">Status IKKE</legend>
            <div className="flexbox">
              <Checkbox
                labelText="Include"
                id="ikke"
                checked={selectedIkke}
                disabled={isLocked}
                onChange={onInputIkkeChange}
              />
            </div>
          </fieldset>
        </AccordionItem>

        <AccordionItem open title="Stops">
          {stopsEnabled && stopList?.length > 0 && (
            <ul className="stop-sidebar-list">
              <li>
                <div>
                  <span className="bx--label">Functional location</span>
                </div>
                <div>
                  <span className="bx--label">Nr. of stops</span>
                </div>
              </li>
              {stopList.map((stop) => (
                <li key={stop.location}>
                  <div>{stop.location}</div>
                  <div>{stop.count}</div>
                </li>
              ))}
            </ul>
          )}

          <Button
            kind="tertiary"
            size="field"
            onClick={onModalOpen}
            renderIcon={Edit16}
          >
            Edit stops
          </Button>
          {/* {!stopsEnabled && (
            <p className="mt-1">
              Stops are disabled. Enable them by checking{' '}
              <strong>Priority &raquo; S</strong>.
            </p>
          )} */}
        </AccordionItem>
      </Accordion>
      <StopsModal onClose={onModalClose} open={isModalOpen} />
    </>
  )
}
