import { useSelector } from 'react-redux'
import { selectOutputData, selectRevisionCodes } from './selectors'
import { selectDatasourceData } from '../../datasource/redux/selectors'
import { useMemo } from 'react'
import { Operation } from './useFreezableOperations'

export const useRevisionCodes = () => {
  const operations = useSelector(selectDatasourceData)
  const selectedRevisionCodes = useSelector(selectRevisionCodes)

  const uniqueRevisionCodes = useMemo(() => {
    const revisionCodes = operations
      .filter((operation: Operation) => operation.priority !== 'S' && operation.revision_code
      )
      .map((operation: Operation) => operation.revision_code)

    const codes = revisionCodes.filter(
      (revisionCode: string, index: number) =>
        revisionCodes.findIndex(
          (indexCode: string) => indexCode === revisionCode,
        ) === index,
    )

    return codes
  }, [operations])

  return {
    selectedRevisionCodes,
    uniqueRevisionCodes
  }
}
