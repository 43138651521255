import { useEffect } from 'react'
import * as actions from '../features/preprocessing/redux/actions'
import {
  mapDictObjectToArray,
  mapStops,
} from '../features/preprocessing/redux/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDatasourceData,
} from '../features/datasource/redux/selectors'
import {
  selectInputPriority,
  selectInputStatusIkke,
  selectInputStops,
  selectInputWorkcenters, selectRetainOperations,
  selectRevisionCodes,
} from '../features/preprocessing/redux/selectors'
import {
  selectPlanningDesiredWeek,
  selectPlanningDesiredYear,
} from '../features/planning/redux/selectors'

export const useFilteredDataSubscription = () => {
  const dispatch = useDispatch()
  const operations = useSelector(selectDatasourceData)
  const selectedWorkcenters = useSelector(selectInputWorkcenters)
  const selectedPriorities = useSelector(selectInputPriority)
  const ikke = useSelector(selectInputStatusIkke)
  const stops = useSelector(selectInputStops)
  const desiredWeek = useSelector(selectPlanningDesiredWeek)
  const desiredYear = useSelector(selectPlanningDesiredYear)
  const selectedRevisionCodes = useSelector(selectRevisionCodes)
  const retainOperations = useSelector(selectRetainOperations)

  useEffect(() => {
    if (operations?.length) {
      dispatch(
        actions.getFilteredData({
          workcenters: selectedWorkcenters,
          operation_statuses: ['approved'],
          priorities: mapDictObjectToArray(selectedPriorities),
          ikke,
          stop_operations: selectedPriorities.S ? mapStops(stops) : [],
          desired_week: desiredWeek,
          desired_year: desiredYear,
          revision_codes: selectedRevisionCodes,
          retain_operations: retainOperations,
        }),
      )
    }
  }, [
    operations,
    retainOperations,
    selectedWorkcenters,
    ikke,
    stops,
    selectedPriorities,
    desiredWeek,
    desiredYear,
    selectedRevisionCodes,
    dispatch,
  ])
}
