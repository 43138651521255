export const GET_SCENARIOS = '[scenarios] GET'
export const GET_SCENARIOS_SUCCESS = '[scenarios] GET :success'
export const GET_SCENARIOS_FAIL = '[scenarios] GET :fail'
export const CREATE_SCENARIO = '[scenarios] CREATE'
export const CREATE_SCENARIO_SUCCESS = '[scenarios] CREATE :success'
export const CREATE_SCENARIO_FAIL = '[scenarios] CREATE :fail'
export const CLONE_SCENARIO = '[scenarios] CLONE'
export const CLONE_SCENARIO_SUCCESS = '[scenarios] CLONE :success'
export const CLONE_SCENARIO_FAIL = '[scenarios] CLONE :fail'
export const UPDATE_SCENARIO = '[scenarios] UPDATE'
export const UPDATE_SCENARIO_SUCCESS = '[scenarios] UPDATE :success'
export const UPDATE_SCENARIO_FAIL = '[scenarios] UPDATE :fail'
export const DELETE_SCENARIO = '[scenarios] DELETE'
export const DELETE_SCENARIO_SUCCESS = '[scenarios] DELETE :success'
export const DELETE_SCENARIO_FAIL = '[scenarios] DELETE :fail'
export const SET_ACTIVE_SCENARIO = '[scenarios] SET'
export const SET_ACTIVE_SCENARIO_SUCCESS = '[scenarios] SET :success'
export const SET_ACTIVE_SCENARIO_ENGINE_JOB = '[scenarios] SET ENGINE JOB'

// Read
export const getScenarios = (payload) => ({
  type: GET_SCENARIOS,
  payload,
})

export const getScenariosSuccess = (payload) => ({
  type: GET_SCENARIOS_SUCCESS,
  payload,
})

export const getScenariosFail = (payload) => ({
  type: GET_SCENARIOS_FAIL,
  payload,
})

// Create
export const createScenario = (payload) => ({
  type: CREATE_SCENARIO,
  payload,
})

export const createScenarioSuccess = (payload) => ({
  type: CREATE_SCENARIO_SUCCESS,
  payload,
})

export const createScenarioFail = (payload) => ({
  type: CREATE_SCENARIO_FAIL,
  payload,
})

// Clone
export const cloneScenario = (payload) => ({
  type: CLONE_SCENARIO,
  payload,
})

export const cloneScenarioSuccess = (payload) => ({
  type: CLONE_SCENARIO_SUCCESS,
  payload,
})

export const cloneScenarioFail = (payload) => ({
  type: CLONE_SCENARIO_FAIL,
  payload,
})

// Update
export const updateScenario = (payload) => ({
  type: UPDATE_SCENARIO,
  payload,
})

export const updateScenarioSuccess = (payload) => ({
  type: UPDATE_SCENARIO_SUCCESS,
  payload,
})

export const updateScenarioFail = (payload) => ({
  type: UPDATE_SCENARIO_FAIL,
  payload,
})

// Delete
export const deleteScenario = (payload) => ({
  type: DELETE_SCENARIO,
  payload,
})

export const deleteScenarioSuccess = (payload) => ({
  type: DELETE_SCENARIO_SUCCESS,
  payload,
})

export const deleteScenarioFail = (payload) => ({
  type: DELETE_SCENARIO_FAIL,
  payload,
})

export const setActiveScenario = (payload) => ({
  type: SET_ACTIVE_SCENARIO,
  payload,
})

export const setActiveScenarioSuccess = (payload) => ({
  type: SET_ACTIVE_SCENARIO_SUCCESS,
  payload,
})

export const setActiveScenarioEngineJob = (payload) => ({
  type: SET_ACTIVE_SCENARIO_ENGINE_JOB,
  payload,
})
