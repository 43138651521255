import React, { useState } from 'react'
import update from 'immutability-helper'
import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TextInput,
  FormGroup,
  Loading,
  InlineNotification,
} from 'carbon-components-react'

import { login } from '../api/login'

import VisaviLogo from '../assets/images/visavi-logo-color.svg'
import WhiteSpaceLogo from '../assets/images/white-space-logo.svg'

export function Login({ handleLogin }) {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [user, setUser] = useState({
    email: '',
    password: '',
  })

  function handleSubmit(e) {
    setLoading(true)
    setError(undefined)
    login(user, handleLogin, setError, setLoading)
    e.preventDefault()
  }

  const errors = {
    username: error?.find
      ? error.find((error) => error.loc[1] === 'username')?.msg
      : undefined,
    password: error?.find
      ? error.find((error) => error.loc[1] === 'password')?.msg
      : undefined,
    global: error && !error.find && error,
  }

  return (
    <ComposedModal
      id="login-dialog"
      open
      selectorPrimaryFocus="#login-username"
      onClose={() => false}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSubmit(e)
        }
      }}
    >
      {loading && <Loading />}
      <ModalHeader title="Login Maintenance Planner" />
      <ModalBody hasForm>
        <FormGroup legendText="">
          <TextInput
            required
            id="login-username"
            labelText="Username"
            placeholder="Username"
            value={user.email}
            onChange={(e) => setUser(update(user, { email: { $set: e.target.value } }))}
            invalid={errors.username}
            invalidText={errors.username}
            className="mb-1"
          />
          <TextInput.PasswordInput
            required
            id="login-password"
            labelText="Password"
            placeholder="Password"
            value={user.password}
            onChange={(e) => setUser(update(user, { password: { $set: e.target.value } }))}
            invalid={errors.password}
            invalidText={errors.password}
            tooltipPosition="left"
          />
        </FormGroup>
        {errors.global && (
          <InlineNotification
            lowContrast
            hideCloseButton
            kind="error"
            title="Failed to login"
            subtitle={errors.global}
          />
        )}
        <div className="login-logos">
          <div className="mb-1">Powered by</div>
          <a
            href="https://visavi.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={VisaviLogo} alt="" className="app-logo" />
          </a>
          <a
            href="https://whitespace.energy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={WhiteSpaceLogo}
              className="app-logo"
              alt="White Space Energy"
              title="White Space Energy"
            />
          </a>
        </div>
      </ModalBody>
      <ModalFooter primaryButtonText="Log in" onRequestSubmit={handleSubmit} />
    </ComposedModal>
  )
}
