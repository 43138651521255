import React from 'react'
import ReactDOM from 'react-dom'
import { ComposedModal, ModalBody, ModalHeader } from 'carbon-components-react'
import ClonePlan from './ClonePlan'

const ClonePlanModal = ({ open, onClose }) => {
  return ReactDOM.createPortal(
    <ComposedModal
      open={open}
      className="app-modal plan-modal"
      onClose={onClose}
    >
      <ModalHeader title="Save existing as new" />
      <ModalBody>
        <ClonePlan onClose={onClose} />
      </ModalBody>
    </ComposedModal>,
    document.body
  )
}

export default ClonePlanModal
