import { createSelector } from 'reselect'

// Input
export const selectPreprocessingInputState = (state) => state.preprocessing.input
export const selectInputWorkcenters = (state) => state.preprocessing.input.workcenters
export const selectRevisionCodes = (state) => state.preprocessing.input.revisionCodes
export const selectInputOperationStatus = (state) => state.preprocessing.input.operationStatus
export const selectInputApproval = (state) => state.preprocessing.input.approval
export const selectInputPriority = (state) => state.preprocessing.input.priority
export const selectInputStatusIkke = (state) => state.preprocessing.input.ikke
export const selectInputSelectedBar = (state) => state.preprocessing.input.selectedBar
export const selectInputStops = (state) => state.preprocessing.input.stops
export const selectRetainOperations = (state) => state.preprocessing.input.retainOperations

// Output
export const selectOutputData = (state) => state.preprocessing.output.data
export const selectOutputIsFetching = (state) => state.preprocessing.output.isFetching
export const selectOutputDataWarnings = createSelector(
  selectOutputData,
  (data) => data?.warnings,
)
export const selectPlanningFreezeIds = (state) => state.preprocessing.input.freezeIds
