import { client } from '../../../api/client'

async function startEngine(body) {
  const response = await client({
    method: 'POST',
    url: '/start_engine_job',
    data: body,
  })
  return response.data
}
async function checkEngine(engine_job_id) {
  const response = await client({
    method: 'GET',
    url: `/engine_jobs/${engine_job_id}`,
  })
  return response.data
}
async function checkEngineStatus(engine_job_id) {
  const response = await client({
    method: 'GET',
    url: `/engine_job/${engine_job_id}/status`,
  })
  return response.data
}

export default {
  startEngine,
  checkEngine,
  checkEngineStatus,
}
