import { client } from '../../../api/client'

async function getResults(jobId) {
  const response = await client({
    method: 'GET',
    url: `/engine_job/${jobId}/results`,
  })
  return response.data
}

async function getCurrentWeekResults(engineId) {
  const response = await client({
    method: 'GET',
    url: `/current-week-operations-summary/${engineId}`,
  })
  return response.data
}

export default {
  getResults,
  getCurrentWeekResults,
}
