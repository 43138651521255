import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectOutputData } from '../features/preprocessing/redux/selectors'
import { selectDatasourceData } from '../features/datasource/redux/selectors'

const BAR_NAME = 'Executed in previous week'

export const usePreviousWeekOperations = () => {
  const datasource = useSelector(selectDatasourceData)
  const data = useSelector(selectOutputData)

  const previousOperations = useMemo(() => {
    if (!datasource || !data) {
      return []
    }

    const selectedData = data.filtered_data.find((x) => x.name === BAR_NAME)
    const sourceIndex = data.filtered_data.findIndex((x) => x.name === BAR_NAME)

    if (sourceIndex < 0) {
      return []
    }

    const source = data.filtered_data[sourceIndex - 1]

    return datasource
      .filter((x) => source.id.includes(x.id))
      .filter((x) => !selectedData.id.includes(x.id))
  }, [datasource, data])

  const previousOperationCodes = useMemo(() => previousOperations.map((operation) => `${operation.work_order_id}-${operation.operation_id}`), [previousOperations])

  const notAllowedCodes = useMemo(() => {
    if (!datasource || !data) {
      return []
    }

    const sourceIndex = data.filtered_data.findIndex((x) => x.name === BAR_NAME)

    if (sourceIndex < 0) {
      return []
    }

    const source = data.filtered_data[sourceIndex - 1]

    const filteredDataSource = datasource.filter((x) => source.id.includes(x.id))

    return filteredDataSource.map((operation) => `${operation.work_order_id}-${operation.operation_id}`)
  }, [datasource, data])

  return {
    previousOperations,
    previousOperationCodes,
    notAllowedCodes,
  }
}
