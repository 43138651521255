export const SET_ENGINE_STATE = '[engine] SET state'
export const START_ENGINE = '[engine] start'
export const START_ENGINE_SUCCESS = '[engine] start :success'
export const START_ENGINE_FAIL = '[engine] start :fail'
export const CHECK_ENGINE = '[engine] check'
export const CHECK_ENGINE_SUCCESS = '[engine] check :success'
export const CHECK_ENGINE_FAIL = '[engine] check :fail'
export const CHECK_ENGINE_STATUS = '[engine] check status'
export const CHECK_ENGINE_STATUS_SUCCESS = '[engine] check status :success'
export const CHECK_ENGINE_STATUS_FAIL = '[engine] check status :fail'
export const RESET_ENGINE = '[engine] RESET'

export const setEngineState = (payload) => ({
  type: SET_ENGINE_STATE,
  payload,
})

export const startEngine = (payload) => ({
  type: START_ENGINE,
  payload,
})

export const startEngineSuccess = (payload) => ({
  type: START_ENGINE_SUCCESS,
  payload,
})

export const startEngineFail = (payload) => ({
  type: START_ENGINE_FAIL,
  payload,
})

export const checkEngine = (payload) => ({
  type: CHECK_ENGINE,
  payload,
})

export const checkEngineSuccess = (payload) => ({
  type: CHECK_ENGINE_SUCCESS,
  payload,
})

export const checkEngineFail = (payload) => ({
  type: CHECK_ENGINE_FAIL,
  payload,
})

export const checkEngineStatus = (payload) => ({
  type: CHECK_ENGINE_STATUS,
  payload,
})

export const checkEngineStatusSuccess = (payload) => ({
  type: CHECK_ENGINE_STATUS_SUCCESS,
  payload,
})

export const checkEngineStatusFail = (payload) => ({
  type: CHECK_ENGINE_STATUS_FAIL,
  payload,
})

export const resetEngine = () => ({
  type: RESET_ENGINE,
})
