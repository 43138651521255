import {
  GET_CURRENT_WEEK_RESULTS_SUCCESS,
  GET_RESULTS,
  GET_RESULTS_FAIL,
  GET_RESULTS_SUCCESS, SET_CURRENT_WEEK_RESULTS,
  SET_RESULTS,
} from './actions'

const initialState = {
  isFetching: false,
  error: null,
  jobId: null,
  data: null,
  currentWeekData: null,
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_RESULTS:
      return {
        ...state,
        isFetching: true,
        error: null,
        jobId: payload,
      }
    case GET_RESULTS_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        data: payload,
      }
    case GET_RESULTS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: payload,
        jobId: null,
      }
    case SET_RESULTS:
      return {
        ...state,
        isFetching: false,
        error: null,
        data: payload,
      }
    case GET_CURRENT_WEEK_RESULTS_SUCCESS:
      return {
        ...state,
        error: null,
        currentWeekData: payload,
      }
    default:
      return state
  }
}

export default reducer
