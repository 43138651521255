import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Accordion,
  AccordionItem,
  TextInput as TextInputCarbon,
} from 'carbon-components-react'
import styled from '@emotion/styled'
import { CarbonizedSlider as Slider } from '../../components/Slider'
import { BasicSlider } from '../../components/BasicSlider'

import { selectPlanningState, selectPlanningResources } from './redux/selectors'
import { selectInputWorkcenters } from '../preprocessing/redux/selectors'
import { setInput, setScore, setResources } from './redux/actions'
import DailyResources from './DailyResources'
import { round } from '../../utils/Math'
import { mapScore } from '../engine/redux/utils'

const TextInput = styled(TextInputCarbon)`
  padding-right: 8px !important;
`

export default function Sidebar({ isLocked }) {
  const dispatch = useDispatch()

  const planningState = useSelector(selectPlanningState)
  const resources = useSelector(selectPlanningResources)
  const workcenters = useSelector(selectInputWorkcenters)

  // Methods
  const onSlideChange = (name) => (value) => {
    dispatch(
      setInput({
        [name]: value,
      }),
    )
  }
  const onScoreChange = (name) => (value) => {
    dispatch(
      setScore({
        [name]: value,
      }),
    )
  }

  const onResourceChange = (value, name, property) => {
    dispatch(
      setResources({
        name,
        value,
        property,
      }),
    )
  }

  const SCORE_SLIDERS = [
    {
      name: 'distantBacklog',
      label: 'Distant backlog',
      min: 1,
      max: 4,
    },
    {
      name: 'backlog',
      label: 'Backlog',
      min: 1,
      max: 4,
    },
    {
      name: 'desiredWeek',
      label: `Planning window ${
        planningState?.desiredWeek ? `(week ${planningState.desiredWeek})` : ''
      }`,
      min: 1,
      max: 4,
    },
    {
      name: 'future',
      label: 'Future',
      min: 1,
      max: 4,
    },
  ]

  return (
    <>
      <Accordion>
        <AccordionItem open title="Backlog">
          <Slider
            min={1}
            max={25}
            step={1}
            labelText="Weeks"
            value={planningState.backlogWeeks || 0}
            onChange={onSlideChange('backlogWeeks')}
            disabled={isLocked}
          />
        </AccordionItem>
        <AccordionItem open title="Score vs time">
          {SCORE_SLIDERS.map((slider) => (
            <Slider
              key={slider.name}
              min={slider.min}
              max={slider.max}
              step={1}
              disabled={isLocked}
              labelText={slider.label}
              valueLabel={(value) => mapScore(value)}
              value={planningState?.scores[slider.name] || 0}
              onChange={onScoreChange(slider.name)}
            />
          ))}
        </AccordionItem>
        <AccordionItem open title="Base crew resources">
          {workcenters?.length === 0 && (
            <p>There are no work centers selected.</p>
          )}

          {Object.keys(resources).length > 0 && (
            <table className="sidebar-table">
              <thead>
                <tr>
                  <td />
                  <td>Staff</td>
                  <td>Capacity</td>
                </tr>
              </thead>
              <tbody>
                {Object.keys(resources).map((workcenter) => (
                  <tr key={workcenter}>
                    <td>
                      <span className="bx--label">{workcenter}</span>
                    </td>
                    <td>
                      <TextInput
                        lang="en"
                        hideLabel
                        inline
                        size="sm"
                        type="number"
                        labelText=""
                        id={`${workcenter}-staff`}
                        min={0}
                        max={20}
                        step={0.1}
                        onChange={(e) => onResourceChange(
                          e.target.value,
                          workcenter,
                          'availability',
                        )}
                        onBlur={(e) => onResourceChange(
                          round(e.target.value, 1),
                          workcenter,
                          'availability',
                        )}
                        disabled={isLocked}
                        value={resources[workcenter].availability || 0}
                      />
                    </td>
                    <td>
                      <BasicSlider
                        step={0.1}
                        min={0}
                        max={1}
                        id={`${workcenter}-capacity`}
                        valueLabelFormat={(value) => `${value * 100}%`}
                        onChange={(value) => onResourceChange(value, workcenter, 'capacity')}
                        disabled={isLocked}
                        value={resources[workcenter].capacity || 0}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </AccordionItem>
        <AccordionItem open title="Adjust base crew by day">
          <DailyResources disabled={isLocked} />
        </AccordionItem>
      </Accordion>
    </>
  )
}
