import React from 'react'
import { Page } from './components/Page'
import Preprocessing from './features/preprocessing/Container'
import Planning from './features/planning/Container'
import Results from './features/results/Container'
import Scenarios from './features/scenarios/Container'
import Datasource from './features/datasource/Container'
import { FreezeOperations } from './features/freeze-operations'

export const flowSteps = [
  {
    path: '/review-input-data',
    component: Preprocessing,
    label: 'Review input data',
  },
  {
    path: '/freeze-operations',
    component: FreezeOperations,
    label: 'Freeze operations',
  },
  {
    path: '/build-plan',
    component: Planning,
    label: 'Build plan',
  },
  {
    path: '/analyse-plan',
    component: Results,
    label: 'Analyse plan',
  },
]

export default [
  {
    path: '/',
    exact: true,
    component: () => <Page />,
    label: 'Home',
  },
  {
    path: '/plans',
    exact: true,
    component: Scenarios,
    label: 'Manage plans',
  },
  {
    path: '/datasource',
    exact: true,
    component: Datasource,
    label: 'Data source',
  },
  ...flowSteps,
]
