import Case from 'case'
import { mapScoreReverse } from '../../engine/redux/utils'

export const mapFilterParamsToRedux = (params) => {
  const paramsObject = {
    workcenters: [],
    operationStatus: {},
    priority: {},
    ikke: false,
  }

  if (!params.length) {
    return paramsObject
  }

  params.forEach((param) => {
    switch (param.name) {
      case 'workcenters':
        paramsObject.workcenters = param.value
        break
      case 'operation_statuses':
        param.value.forEach((status) => {
          paramsObject.operationStatus[Case.title(status)] = true
        })
        break
      case 'priorities':
        param.value.forEach((prio) => {
          paramsObject.priority[Case.title(prio)] = true
        })
        break
      case 'ikke':
        paramsObject.ikke = param.value
        break
      case 'freeze_ids':
        paramsObject.freezeIds = param.value
        break
      case 'ui_revision_codes':
        paramsObject.revisionCodes = param.value
        break
      case 'retain_operations':
        console.log(paramsObject)
        paramsObject.retainOperations = param.value
        break
    }
  })

  return paramsObject
}

export const mapPlanningParamsToRedux = (params) => {
  const resources = {}

  params.resources.forEach(({ name, availability, capacity }) => {
    resources[name] = {
      availability,
      capacity,
    }
  })

  const planningParams = {
    desiredWeek: params.desired_week,
    desiredYear: params.desired_year,
    backlogWeeks: params.backlog_weeks,
    scores: {
      distantBacklog: mapScoreReverse(params.score_distant_backlog),
      backlog: mapScoreReverse(params.score_backlog),
      desiredWeek: mapScoreReverse(params.score_desired_week),
      future: mapScoreReverse(params.score_future),
    },
    resources,
  }

  if (
    params.daily_resources &&
    Object.keys(params.daily_resources).length > 0
  ) {
    planningParams.dailyResources = params.daily_resources
  }

  return planningParams
}

export const mapStopsParamsToRedux = (stops) => {
  if (!stops?.length) return []

  const stopsObject = {}

  stops.forEach((stop) => {
    stopsObject[stop.id] = {
      location: stop.func_loc,
      start: stop.start,
      duration: stop.duration,
      code: stop.revision_codes,
    }
  })

  return stopsObject
}
