import React, { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, TextInput } from 'carbon-components-react'
import Collapse from '@material-ui/core/Collapse'
import ComboSelect from '../../components/ComboSelect'
import { selectDatasourceFiles } from '../datasource/redux/selectors'
import { setUploadState } from '../datasource/redux/actions'
import Upload from '../datasource/Upload'
import * as actions from './redux/actions'

export default function CreatePlan({ onClose }) {
  // Hooks
  const [newScenario, setNewScenario] = useState({
    name: '',
    csvFile: '',
  })
  const [isUploadOpen, setUploadOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  // Selectors
  const files = useSelector(selectDatasourceFiles)

  // Methods
  const onFileChange = (selected) => {
    if (!selected) {
      setNewScenario({
        ...newScenario,
        csvFile: '',
      })
    } else {
      setNewScenario({
        ...newScenario,
        csvFile: selected,
      })
    }
  }

  const onNameChange = (e) => {
    setNewScenario({
      ...newScenario,
      name: e.target.value,
    })
  }

  const resetScenario = () => {
    setNewScenario({
      name: '',
      csvFile: '',
    })
  }

  const onCreate = () => {
    if (!newScenario.csvFile) return

    dispatch(
      actions.createScenario({
        name: newScenario.name,
        csv_upload_id: newScenario.csvFile.id,
      }),
    )

    onClose()
    history.push('/review-input-data')
    resetScenario()
  }

  const onUploadNew = (e) => {
    e.stopPropagation()

    dispatch(setUploadState({ success: false, error: null }))
    setUploadOpen(!isUploadOpen)
  }

  const onUploadContinue = () => {
    setNewScenario({
      ...newScenario,
      csvFile: files[0],
    })

    setUploadOpen(false)
  }

  // Memos
  const dropdownFiles = useMemo(
    () => (files ? [...files].sort((a, b) => b.id - a.id) : []),
    [files],
  )

  const canCreate = newScenario.name && newScenario.csvFile

  return (
    <div className="app-scenario-new">
      <div>
        <TextInput
          light
          id="plan-name"
          labelText="Plan name"
          className="mb-2"
          value={newScenario.name}
          onChange={onNameChange}
        />
        <div className="mb-2">
          <div className="app-scenario-flex">
            <span className="bx--label">Select CSV file</span>
            <a href="#" className="bx--label" onClick={onUploadNew}>
              {isUploadOpen ? 'Select existing' : 'Upload new'}
            </a>
          </div>
          <Collapse in={isUploadOpen}>
            <Upload onContinue={onUploadContinue} />
          </Collapse>
          <Collapse in={!isUploadOpen}>
            <ComboSelect
              disablePortal
              light
              id="files"
              items={dropdownFiles}
              selectedItems={newScenario.csvFile}
              labelText=""
              placeholder="Choose file"
              getOptionLabel={(item) => (item ? item.name : '')}
              onChange={onFileChange}
            />
          </Collapse>
        </div>
        <Button onClick={onCreate} disabled={!canCreate}>
          Create
        </Button>
      </div>
    </div>
  )
}
