import React from 'react'
import { Grid, Row, Column } from 'carbon-components-react'
import ResourcesChart from './ResourcesChart'
import ScoresChart from './ScoresChart'

export default function Main() {
  return (
    <>
      <Grid fullWidth>
        <Row>
          <Column md={4}>
            <ScoresChart />
          </Column>
          <Column md={4}>
            <ResourcesChart />
          </Column>
        </Row>
      </Grid>
    </>
  )
}
