import React from 'react'
import { Grid, Row, Column } from 'carbon-components-react'
import { ScenariosTable } from './ScenariosTable'

export default function Main() {
  return (
    <Grid fullWidth className="mb-2">
      <Row>
        <Column>
          <h4 className="mb-2">Manage plans</h4>
          <ScenariosTable />
        </Column>
      </Row>
    </Grid>
  )
}
