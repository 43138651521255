import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, TextInput } from 'carbon-components-react'
import ComboSelect from '../../components/ComboSelect'

import {
  selectScenariosDataById,
  selectScenariosDataAllIds,
  selectActiveScenario,
} from './redux/selectors'
import * as actions from './redux/actions'

export default function ClonePlan({ onClose }) {
  const [newScenario, setNewScenario] = useState({
    name: '',
    csvFile: '',
  })
  const [existingPlan, setExistingPlan] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()

  const scenariosById = useSelector(selectScenariosDataById)
  const scenariosIds = useSelector(selectScenariosDataAllIds)
  const activeScenario = useSelector(selectActiveScenario)

  useEffect(() => {
    if (activeScenario) {
      setExistingPlan(activeScenario)
      setNewScenario({ name: `${activeScenario.name} copy` })
    }
  }, [activeScenario])

  // const onFileChange = (selected) => {
  //   if (!selected) {
  //     setNewScenario({
  //       ...newScenario,
  //       csvFile: '',
  //     })
  //   } else {
  //     setNewScenario({
  //       ...newScenario,
  //       csvFile: selected,
  //     })
  //   }
  // }

  const onExistingPlanChange = (selected) => {
    setExistingPlan(selected)
  }

  const onNameChange = (e) => {
    setNewScenario({
      ...newScenario,
      name: e.target.value,
    })
  }

  const resetScenario = () => {
    setNewScenario({
      name: '',
      csvFile: '',
    })
  }

  const onCreateFromExisting = () => {
    dispatch(
      actions.cloneScenario({
        name: newScenario.name,
        planId: existingPlan.id,
      }),
    )

    onClose()
    history.push('/review-input-data')
    resetScenario()
  }

  const dropdownScenarios = useMemo(() => {
    if (!scenariosIds?.length || !scenariosById) {
      return []
    }

    return scenariosIds.map((id) => scenariosById[id])
  }, [scenariosIds, scenariosById])

  const canCreateFromExisting = newScenario.name && !!existingPlan

  return (
    <div className="app-scenario-new">
      <TextInput
        light
        id="plan-name"
        labelText="Plan name"
        className="mb-2"
        value={newScenario.name}
        onChange={onNameChange}
      />
      <div className="mb-2">
        <ComboSelect
          disablePortal
          light
          id="existing-plan"
          items={dropdownScenarios}
          selectedItems={existingPlan}
          labelText="Existing plan"
          placeholder="Choose plan"
          getOptionLabel={(item) => (item ? item.name || '' : '')}
          onChange={onExistingPlanChange}
        />
      </div>

      <Button onClick={onCreateFromExisting} disabled={!canCreateFromExisting}>
        Create
      </Button>
    </div>
  )
}
