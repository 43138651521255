import moment from 'moment'
import * as labels from '../labels'

export const mapDictObjectToArray = (obj) => {
  return Object.keys(obj)
    .map((x) => obj[x] && x)
    .filter((x) => x)
}

export const mapDataToChart = (operations, data) => {
  if (!operations || !operations.length || !data) {
    return []
  }

  const filteredData = data.filtered_data
  const retainedData = data.retained_data_ids
  const totalData = data.filtered_data.find(
    (d) => d.name === 'Total operations available'
  )
  const totalDataLength = totalData.id.length

  const mappedFilteredData = filteredData.map((filter, i) => {
    if (filter.name === labels.TOTAL_OPERATIONS) {
      return {
        label: labels.TOTAL_OPERATIONS,
        start: filter.id.length,
        labelFormat: filter.id.length,
        tableLabel: labels.TOTAL_OPERATIONS,
      }
    }

    const dropped = filteredData[i - 1]?.id.filter(
      (x) => !filter.id.includes(x)
    )

    const droppedKey = labels.isDroppedLabel(filter.name)
      ? 'dropped'
      : 'conflicting'

    return {
      label: filter.name,
      labelFormat: `-${dropped?.length}`,
      [droppedKey]: dropped?.length,
      inv: filter.id.length,
      topInv: totalDataLength - dropped?.length - filter.id.length,
    }
  })

  const mappedRetainedData = {
    label: labels.RETAINED_OPERATIONS,
    end: retainedData.length,
    labelFormat: retainedData.length,
    tableLabel: labels.RETAINED_OPERATIONS,
    topInv: totalDataLength - retainedData.length,
  }

  return [...mappedFilteredData, mappedRetainedData]
}

export const mapResourceAvailability = (workcenter) => {
  const availability = {
    AEAU: 3,
    AEEL: 2,
    BAME: 4,
    FEFO: 3,
    SEMV: 1,
    SEPL: 2,
    SERV: 2,
  }
  return availability[workcenter] || 1
}

export const mapResourceCapacity = (workcenter) => {
  const capacity = {
    FEFO: 1.0,
  }
  return capacity[workcenter] || 0.6
}

export const mapStops = (stops) => {
  if (!stops) return []

  return Object.keys(stops)
    .map((id) => {
      const stop = stops[id]

      if (!stop.location || !stop.start || !stop.duration) {
        return null
      }

      return {
        func_loc: stop.location,
        revision_codes: stop.code,
        start: stop.start
          ? moment(stop.start).format('YYYY-MM-DD HH:mm:ss')
          : '',
        duration: stop.duration,
      }
    })
    .filter((x) => x)
}
