import React from 'react'
import { Button } from 'carbon-components-react'
import { Tooltip } from '@whitespace/ui'

interface ProjectHeaderActionProps {
  children: React.ReactNode
  onClick?: () => void
  tooltipText: string
  tooltipDirection?: 'start' | 'center' | 'end'
}

export const ProjectHeaderAction = ({ onClick, children, tooltipText, tooltipDirection }: ProjectHeaderActionProps) => {
  return (
    <Tooltip content={tooltipText} position="bottom" direction={tooltipDirection}>
      <Button
        onClick={onClick}
        aria-label={tooltipText}
        className="bx--header__action bx--btn bx--btn--primary bx--tooltip--hidden bx--btn--icon-only bx--tooltip__trigger bx--tooltip--a11y bx--btn--icon-only--bottom bx--tooltip--align-center"
      >
        {children}
      </Button>
    </Tooltip>
  )
}
