import { useHistory, useLocation } from 'react-router-dom'
import {
  ProgressStep as CarbonProgressStep,
  RenderLabelProps,
} from 'carbon-components-react'
import React, { FC } from 'react'

export interface ProgressStepProps {
  href: string
  label: string
  disabled?: boolean
}

export const ProgressStep = ({ href, label, disabled }: ProgressStepProps) => {
  const history = useHistory()
  const location = useLocation()

  const onClickHandler = () => {
    history.push(href)
  }

  const renderLabel: FC<RenderLabelProps> = ({ children }) => {
    return (
      <span className="bx--progress-label ovr-flow-nav-label">{children}</span>
    )
  }

  return (
    <CarbonProgressStep
      current={location.pathname === href}
      onClick={onClickHandler}
      label={label}
      renderLabel={renderLabel}
      disabled={disabled}
    />
  )
}
