import React, { useMemo } from 'react'
import { ResponsiveBarCanvas } from '@nivo/bar'
import { mapDataToBarChart, generateTimeTicks, findMaxY } from '../redux/utils'

const coolors = ['#cccccc', '#06d6a0', '#118ab2', '#073b4c', '#ef476f']

export default function Workload({ data }) {
  const mappedData = useMemo(() => {
    if (!data) return []
    return mapDataToBarChart(data)
  }, [data])

  const maxY = useMemo(() => {
    if (!data) return []
    return findMaxY(data)
  }, [data])

  const ticks = useMemo(() => {
    return generateTimeTicks(data.timeline)
  }, [data])

  return (
    mappedData &&
    mappedData.length > 0 &&
    mappedData.map((entity) => (
      <React.Fragment key={entity.id}>
        <h4 className="app-chart-title">{entity.id}</h4>
        <div style={{ width: '100%', height: '300px' }}>
          <ResponsiveBarCanvas
            data={entity.data}
            keys={['staff']}
            indexBy="datetime"
            margin={{ top: 50, right: 10, bottom: 70, left: 80 }}
            padding={0}
            enableGridY
            valueScale={{
              type: 'linear',
              min: 'auto',
              max: maxY,
              stacked: false,
              reverse: false,
            }}
            indexScale={{ type: 'band', round: true }}
            colors={[coolors[0]]}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickValues: ticks,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legendPosition: 'middle',
              legendOffset: 42,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: [...new Array(maxY + 1)].map((_, i) => i),
              legend: 'Staff',
              legendPosition: 'middle',
              legendOffset: -60,
            }}
            enableLabel={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            tooltip={CustomTooltip}
          />
        </div>
      </React.Fragment>
    ))
  )
}

function CustomTooltip({ id, indexValue, value }) {
  return (
    <div className="chart-bar-tooltip">
      <h6>
        <span>{indexValue}</span>
      </h6>
      <span style={{ textTransform: 'capitalize' }}>
        {id}: {value}
      </span>
    </div>
  )
}
