export const getUniqueValues = (data, name) => {
  return [
    ...new Set(
      data
        .map((item) => item[name])
        .filter((x) => x)
        .sort()
    ),
  ]
}
