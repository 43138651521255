import axios from 'axios'

export const login = (user, loggedIn, setError, setLoading) => {
  const url = `${window.REACT_APP_API_URL}/login`

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  const data = new FormData()
  data.append('username', user.email)
  data.append('password', user.password)

  axios
    .post(url, data, config)
    .then(response => {
      localStorage.setItem('token', response.data.access_token)
      loggedIn(true)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      setError(
        error.response
          ? error.response.data.detail
          : 'Server seems to be offline'
      )
    })
}
