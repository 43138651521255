import React from 'react'
import clsx from 'clsx'
import { Content, SideNav } from 'carbon-components-react'

export function Page({
  SidebarComponent,
  ContentComponent,
  ToolbarComponent,
  children,
  contentProps,
  sidebarProps,
  toolbarProps,
}) {
  return (
    <>
      {SidebarComponent && (
        <SideNav
          expanded
          isFixedNav
          isChildOfHeader={false}
          aria-label=""
          className="sidebar dark-theme"
        >
          <SidebarComponent {...sidebarProps} />
        </SideNav>
      )}
      <Content
        className={clsx({
          'page-has-toolbar': !!ToolbarComponent,
        })}
      >
        {ContentComponent && <ContentComponent {...contentProps} />}
        {children}
      </Content>
      {ToolbarComponent && <ToolbarComponent {...toolbarProps} />}
    </>
  )
}
