import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  InlineLoading,
  InlineNotification,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react'
import { Run20 } from '@carbon/icons-react'
import styled from '@emotion/styled'
import {
  startEngine,
  checkEngine,
  checkEngineStatus,
  setEngineState,
} from './redux/actions'
import {
  selectEngineState,
  selectEngineIsRunning,
  selectPrioritizeShortJobs,
} from './redux/selectors'
import { selectOutputDataWarnings } from '../preprocessing/redux/selectors'
import { selectPlanningDesiredWeek } from '../planning/redux/selectors'
import { selectActiveScenario } from '../scenarios/redux/selectors'
import WarningsModal from './WarningsModal'

export const EngineToolbarContainer = styled.div`
  .bx--inline-notification--low-contrast {
    color: #161616;
    background-color: #fdf6dd !important;
  }
`

export default function EngineToolbar() {
  const dispatch = useDispatch()
  const [isWarningsModalOpen, setWarningsModalOpen] = useState(false)

  const state = useSelector(selectEngineState)
  const isRunning = useSelector(selectEngineIsRunning)
  // const jobId = useSelector(selectEngineJobId)
  const activeScenario = useSelector(selectActiveScenario)
  const prioritizeShortJobs = useSelector(selectPrioritizeShortJobs)
  const warnings = useSelector(selectOutputDataWarnings)

  const week = useSelector(selectPlanningDesiredWeek)

  // Debug
  const debugMode = JSON.parse(localStorage.getItem('debug'))
  const debugTimelimitInput = useRef(null)

  // Effects
  useEffect(() => {
    if (activeScenario?.engine_job_id) {
      dispatch(checkEngine(activeScenario.engine_job_id))
      dispatch(checkEngineStatus(activeScenario.engine_job_id))
    }
  }, [dispatch])

  useEffect(() => {
    if (isRunning && activeScenario?.engine_job_id) {
      const checkLoop = setInterval(() => {
        dispatch(checkEngineStatus(activeScenario.engine_job_id))
      }, [2000])

      return () => {
        clearInterval(checkLoop)
      }
    }

    return () => {}
  }, [isRunning, activeScenario, dispatch])

  const memoPrioritizeShortJobs = useMemo(() => {
    if (!prioritizeShortJobs) {
      return 'mixed_length'
    }

    return prioritizeShortJobs ? 'short_jobs' : 'mixed_length'
  }, [prioritizeShortJobs])

  // Methods
  const handleEngineStart = () => {
    if (debugMode) {
      const debugEngineTimeLimit = Number(debugTimelimitInput.current.value)
      const debugParams = debugEngineTimeLimit > 0
        ? { debug_engine_time_limit: debugEngineTimeLimit }
        : {}

      dispatch(startEngine(debugParams))
    } else {
      dispatch(startEngine())
    }
  }

  const onPrioritizeShortJobs = (option) => {
    const value = option === 'short_jobs'
    dispatch(setEngineState({ prioritizeShortJobs: value }))
  }

  const onWarningsModalOpen = (e) => {
    e.preventDefault()
    setWarningsModalOpen(true)
  }

  const onWarningsModalClose = () => {
    setWarningsModalOpen(false)
  }

  const isLocked = !!activeScenario?.engine_job_id

  if (!state) {
    return null
  }

  return (
    <EngineToolbarContainer>
      <div className="toolbar dark-theme sidebar">
        <h5 className="mb-1">Planning engine</h5>

        {debugMode && (
          <div className="mb-2 debug-layout">
            <label>
              debug_engine_time_limit{' '}
              <input
                type="number"
                className="debug-input"
                min="0"
                max="300"
                id="debug_engine_time_limit"
                ref={debugTimelimitInput}
              />
            </label>
          </div>
        )}

        <div className="engine-toggle mb-2">
          <RadioButtonGroup
            legendText=""
            name="radio-button-group"
            valueSelected={
              memoPrioritizeShortJobs
            }
            orientation="vertical"
            onChange={onPrioritizeShortJobs}
          >
            <RadioButton
              labelText="Prioritize short jobs"
              value="short_jobs"
              id="short_jobs"
              disabled={!!activeScenario?.engine_job_id}
            />
            <RadioButton
              labelText="Mixed length jobs"
              value="mixed_length"
              id="mixed_length"
              disabled={!!activeScenario?.engine_job_id}
            />
          </RadioButtonGroup>
        </div>
        {warnings?.length > 0 && (
          <InlineNotification
            lowContrast
            hideCloseButton
            kind="warning"
            title="Data validation"
            subtitle={(
              <span>
                {warnings.length === 1 && (
                  <>There is 1 warning that requires your attention.</>
                )}
                {warnings.length > 1 && (
                  <>
                    There are {warnings.length} warnings that require your
                    attention.
                  </>
                )}
                <br />
                <a
                  href="#"
                  className="engine-warnings-link"
                  onClick={onWarningsModalOpen}
                >
                  See details
                </a>
              </span>
            )}
          />
        )}
        <div className="engine-trigger">
          <Button
            onClick={handleEngineStart}
            renderIcon={Run20}
            kind="primary"
            children="Generate plans"
            disabled={isLocked}
          />
        </div>
        <div className="engine-status">
          {(state.isRunning
            || ['COMPLETED', 'FAILED', 'NOT_FOUND'].includes(state.status)) && (
            <>
              <InlineLoading
                description={
                  state.status === 'COMPLETED'
                    ? 'Engine job is completed!'
                    : state.status === 'FAILED'
                      ? `Engine failed. ${state.error}`
                      : state.status === 'NOT_FOUND'
                        ? 'Engine job was not found.'
                        : 'Engine is running...'
                }
                status={
                  state.status === 'COMPLETED'
                    ? 'finished'
                    : state.status === 'FAILED'
                      ? 'error'
                      : state.status === 'NOT_FOUND'
                        ? 'error'
                        : 'active'
                }
              />
              {state.status === 'COMPLETED' && (
                <div className="engine-results-link">
                  <Link to="/analyse-plan" className="bx--inline-loading__text">
                    Analyse plan
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
        {!state.status && !state.isRunning && state.error && (
          <InlineLoading
            description={`Engine failed. ${state.error}`}
            status="error"
          />
        )}
      </div>
      <WarningsModal
        warnings={warnings}
        week={week}
        onClose={onWarningsModalClose}
        open={isWarningsModalOpen}
      />
    </EngineToolbarContainer>
  )
}
