export const getNextWeek = (momentDate) => momentDate.add('1', 'week')

export const getNextWeekNumber = (momentDate) => getNextWeek(momentDate).isoWeek()

export const getNextWeekISO8601Year = (momentDate) => {
  const weekNumber = getNextWeekNumber(momentDate)

  if (weekNumber !== 53) {
    return getNextWeek(momentDate).year()
  }

  return momentDate.month() === 0
    ? getNextWeek(momentDate).year() - 1
    : getNextWeek(momentDate).year()
}
