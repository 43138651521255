import { AccordionItem, MultiSelect } from 'carbon-components-react'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setRevisionCodes } from './redux/actions'
import { useRevisionCodes } from './redux/useRevisionCodes'
import { StatefulComboSelect } from '../../components/StatefulComboSelect'

export const SiderbarRevisionCodes = ({ isLocked }) => {
  const { uniqueRevisionCodes, selectedRevisionCodes } = useRevisionCodes()
  const [componentVisible, setComponentVisible] = useState(true)
  const dispatch = useDispatch()

  const handleRevisionCodesChange = (codes) => {
    dispatch(setRevisionCodes(codes))
  }

  const handleSelectAllRevisionCodes = () => {
    if (uniqueRevisionCodes.length === selectedRevisionCodes.length) {
      dispatch(setRevisionCodes([]))

      return
    }

    dispatch(setRevisionCodes([...uniqueRevisionCodes]))
  }

  useEffect(() => {
    if (!componentVisible) {
      setComponentVisible(true)
    }
  }, [componentVisible])

  return (
    <AccordionItem open title="Revision codes">
      <div className="mb-1">
        <div className="flexbox">
          <span
            className={clsx('bx--label', {
              'bx--label--disabled': isLocked,
            })}
          >
            Revision codes
          </span>

          {!isLocked && (
            <a
              href="#"
              onClick={handleSelectAllRevisionCodes}
              style={{ marginLeft: 'auto' }}
            >
              <span className="bx--label">
                {uniqueRevisionCodes.length > 0
                && selectedRevisionCodes.length === uniqueRevisionCodes.length
                  ? 'Deselect all'
                  : 'Select all'}
              </span>
            </a>
          )}
        </div>
        <StatefulComboSelect
          multiple
          id="revisioncodes"
          items={uniqueRevisionCodes || []}
          selectedItems={selectedRevisionCodes || []}
          placeholder="Revision codes"
          disabled={isLocked}
          onClose={handleRevisionCodesChange}
        />
      </div>
    </AccordionItem>
  )
}
