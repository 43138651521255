import React from 'react'
import { Grid, Row, Column } from 'carbon-components-react'
import OperationsPerTimeSegment from './charts/OperationsPerTimeSegment'
import OperationsPerPriority from './charts/OperationsPerPriority'
import UtilizationPerWorkcenter from './charts/UtilizationPerWorkcenter'

export default function Metrics({ data }) {
  return (
    <Grid narrow condensed fullWidth>
      <Row condensed>
        <Column>
          <h4 className="app-chart-title mb-2">
            Resource utilisation per work center
          </h4>
          <UtilizationPerWorkcenter data={data.utilization_per_workcenter} />
        </Column>
      </Row>
      <Row condensed>
        <Column md={4}>
          <div style={{ width: '100%', height: '400px' }}>
            <h4 className="app-chart-title">Operations per time segment</h4>
            <OperationsPerTimeSegment data={data.operations_per_time_segment} />
          </div>
        </Column>
        <Column md={4}>
          <div style={{ width: '100%', height: '400px' }}>
            <h4 className="app-chart-title">Operations per priority</h4>
            <OperationsPerPriority data={data.operations_per_priority} />
          </div>
        </Column>
      </Row>
    </Grid>
  )
}
