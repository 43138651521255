import React, { useMemo } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { mapDataToUtilizationPerWorkcenter } from './utils'

const coolors = ['#118ab2', '#ddd']

const UtilizationPerWorkcenter = ({ data }) => {
  const chartData = useMemo(() => {
    if (!data) return []

    return mapDataToUtilizationPerWorkcenter(data)
  }, [data])

  const chartWidth = useMemo(() => {
    return chartData.length > 18 ? chartData.length * 60 + 100 : '100%'
  }, [chartData])

  return (
    <div
      style={{
        width: '100%',
        height: 420,
        overflowX: chartData.length > 18 ? 'scroll' : 'initial',
        marginBottom: chartData.length > 18 ? '3rem' : 0,
      }}
    >
      <div style={{ width: chartWidth, height: 380, margin: '0 auto' }}>
        <ResponsiveBar
          data={chartData}
          keys={['value', 'grey']}
          indexBy="workcenter"
          margin={{ top: 10, right: 10, bottom: 50, left: 80 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={coolors}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Resource utilisation [%]',
            legendPosition: 'middle',
            legendOffset: -60,
          }}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          tooltip={CustomTooltip}
        />
      </div>
    </div>
  )
}

function CustomTooltip({ indexValue, data }) {
  return (
    <div className="chart-bar-tooltip">
      <h6>
        <span>{indexValue}</span>
      </h6>
      <span style={{ textTransform: 'capitalize' }}>{data.value || 0}%</span>
    </div>
  )
}

export default UtilizationPerWorkcenter
