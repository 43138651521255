import moment from 'moment'
import Case from 'case'

export const mapStaffToChart = (data) => Object.keys(data)
  .reverse() // the chart seems to reverse the array
  .map((workcenter) => ({
    workcenter,
    Staff: data[workcenter].availability,
    Capacity: data[workcenter].capacity * 100,
  }))

export const mapCapacityToChart = (data) => Object.keys(data).map((workcenter) => ({
  workcenter,
  Capacity: data[workcenter].capacity * 100,
}))

export const mapScoresToChart = (planningState) => {
  const backlogSize = parseInt(planningState.backlogWeeks || 1)
  const desiredWeek = parseInt(planningState.desiredWeek || 1)
  const backlog = [...new Array(backlogSize)].map((_, i) => {
    const x = moment()
      .week(desiredWeek)
      .subtract(backlogSize - 1 - i, 'week')
      .isoWeek()
    return { x, y: 'backlog' }
  })
  const distantBacklog = [...new Array(5)].map((_, i) => {
    const x = moment()
      .week(backlog[0].x)
      .subtract(5 - 1 - i, 'week')
      .isoWeek()
    return { x, y: 'distantBacklog' }
  })
  const future = [...new Array(5)].map((_, i) => {
    const x = moment()
      .week(desiredWeek)
      .add(i + 2, 'week')
      .isoWeek()
    return { x, y: 'future' }
  })

  const timeline = [
    ...distantBacklog,
    ...backlog,
    { x: desiredWeek, y: 'desiredWeek' },
    ...future,
  ].map((item) => ({ ...item, y: item.y }))

  return Object.keys(planningState.scores).map((name) => {
    const id = name === 'desiredWeek'
      ? `Planning window ${
            planningState?.desiredWeek
              ? `(week ${planningState.desiredWeek})`
              : ''
      }`
      : Case.sentence(name)
    return {
      id,
      data: timeline.map((d) => {
        if (d.y === name) {
          return {
            x: d.x,
            y: planningState.scores[d.y],
          }
        }
        return {
          x: d.x,
          y: 0,
        }

      }),
    }
  })
}
