// import axios from 'axios'
// import { CANCEL } from 'redux-saga'
import { client } from '../../../api/client'

async function getFilteredData(body, fileId) {
  const response = await client({
    method: 'POST',
    url: `/filter_csv_upload/${fileId}`,
    data: body,
  })

  return response.data
}

// const CancelToken = axios.CancelToken
// const cancellable = (body, fileId) => {
//   const source = CancelToken.source()
//   const promise = axios.post(
//     `${window.REACT_APP_API_URL}/filter_csv_upload/${fileId}`,
//     body,
//     {
//       cancelToken: source.token,
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('token')}`,
//       },
//     }
//   )
//   promise[CANCEL] = () => source.cancel()
//   return promise
// }

export default {
  getFilteredData,
}
