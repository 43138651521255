import axios from 'axios'

export const client = async (config) => {
  const instance = axios.create({
    baseURL: window.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })

  return instance(config)
}
