import React, { useEffect, useState } from 'react'
import { Loading } from 'carbon-components-react'

import checkToken from '../api/checkToken'

import { App } from './App'
import { Login } from '../components/Login'

export function LoginContainer() {
  const [loggedIn, setLoggedIn] = useState('loading')

  useEffect(() => {
    async function persistentLogin() {
      const isTokenValid = await checkToken()
      setLoggedIn(isTokenValid)
    }

    persistentLogin()
  }, [])

  function handleLogin(state) {
    setLoggedIn(state)
  }

  function handleLogout() {
    localStorage.removeItem('token')
    setLoggedIn(false)
  }

  if (loggedIn === 'loading') {
    return (
      <div className="loader">
        <Loading />
      </div>
    )
  }

  if (loggedIn === true) {
    return <App open={false} handleLogout={handleLogout} />
  }

  if (loggedIn === 'open') {
    return <App open />
  }

  return <Login handleLogin={handleLogin} />
}
