import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  ChevronDown16,
  Close16,
  Checkmark16,
  Checkbox20,
  CheckboxCheckedFilled20,
} from '@carbon/icons-react'
import { Tag } from 'carbon-components-react'

export function StatefulComboSelect({
  items,
  selectedItems,
  onChange,
  onClose,
  id,
  labelText,
  placeholder,
  invalid,
  invalidText,
  getOptionLabel,
  open,
  disabled,
  groupBy,
  multiple = false,
  light = false,
  height = 8,
  optionKey = 'name',
  getOptionSelected,
}) {
  const [value, setValue] = useState([])
  // Hooks
  const classes = useStyles({ light, height })
  const carbonClasses = useCarbonStyles()

  // Effects
  useEffect(() => {
    if (selectedItems) {
      setValue(selectedItems)
    }
  }, [selectedItems])

  // Methods
  const handleChange = (_, selectedItems) => {
    if (disabled) return
    setValue(selectedItems)
  }

  const handleClose = () => {
    if (onClose) {
      onClose(value)
    }
  }

  const onTagClose = () => {
    if (disabled) return

    if (onChange) {
      onChange([])
    }
    if (onClose) {
      onClose([])
    }
  }

  const renderOption = (option, { selected }) => (
    <>
      {!multiple && (
        <>
          {option[optionKey] || option}
          {selected && <Checkmark16 style={{ marginLeft: 'auto' }} />}
        </>
      )}

      {multiple && (
        <>
          {!selected && <Checkbox20 style={{ marginRight: '0.5rem' }} />}
          {selected && (
            <CheckboxCheckedFilled20 style={{ marginRight: '0.5rem' }} />
          )}
          {option[optionKey] || option}
        </>
      )}
    </>
  )

  const renderInput = (params, selectedItem) => {
    const extendedProps = selectedItem?.reference
      ? {
        startAdornment: (
          <InputAdornment
            position="start"
            className={carbonClasses.adornment}
          >
            <Tag type="gray" className={carbonClasses.adornmentTag}>
              REF
            </Tag>
          </InputAdornment>
        ),
        style: {
          paddingLeft: '2rem',
        },
      }
      : {}

    return (
      <>
        {labelText && (
          <span
            className={classnames('bx--label', {
              'bx--label--disabled': disabled,
            })}
          >
            {labelText}
          </span>
        )}
        <TextField
          {...params}
          label={null}
          placeholder={placeholder}
          error={invalid}
          InputProps={{
            ...params.InputProps,
            ...extendedProps,
          }}
        />
        {invalid && (
          <span className="bx--form-requirement">
            {invalidText || 'This field is invalid'}
          </span>
        )}
      </>
    )
  }

  const renderTags = (value) => {
    if (!value?.length) return null

    const ref = value?.find((s) => s.reference)
    const count = ref ? value.length - 1 : value.length

    return (
      <>
        {count > 0 && (
          <Tag
            filter
            type="high-contrast"
            title="Clear all selected items"
            onClick={onTagClose}
            className={carbonClasses.tags}
          >
            {count}
          </Tag>
        )}
      </>
    )
  }

  return (
    <Autocomplete
      open={open}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      onChange={handleChange}
      onClose={handleClose}
      id={id}
      // disabled={disabled}
      classes={classes}
      popupIcon={<ChevronDown16 />}
      closeIcon={<Close16 />}
      options={items}
      value={value || ''}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      renderInput={(params) => renderInput(params, selectedItems)}
      renderTags={renderTags}
      style={{ width: '100%' }}
    />
  )
}

const useCarbonStyles = makeStyles(() => createStyles({
  tags: {
    fontFamily: ['IBM Plex Sans', 'Helvetica Neue', 'Arial', 'sans-serif'],
    marginLeft: '0.5rem !important',
    marginRight: '0 !important',
    height: '1rem',
    padding: '0 0.25rem 0 0.5rem !important',
    backgroundColor: '#f4f4f4 !important',
    color: '#161616 !important',
  },
  adornment: {
    height: 'auto',
    position: 'absolute',
    left: 0,
  },
  adornmentTag: {
    transform: 'scale(0.8)',
    backgroundColor: '#161616 !important',
    color: '#f4f4f4 !important',
  },
  reference: {
    transform: 'scale(0.8)',
  },
  referenceSingle: {
    transform: 'scale(0.8)',
    margin: '-5px 5px -5px -3px',
    backgroundColor: '#161616 !important',
    color: '#f4f4f4 !important',
  },
}))

const useStyles = makeStyles(() => createStyles({
  root: {
    fontFamily: ['IBM Plex Sans', 'Helvetica Neue', 'Arial', 'sans-serif'],
    borderRadius: 0,
  },
  inputRoot: {
    marginTop: 0,
    paddingBottom: '0 !important',
    borderBottom: '1px solid transparent',
    borderBottomColor: (props) => (props.light ? '#8d8d8d' : '#6f6f6f'),
    backgroundColor: (props) => (props.light ? '#ffffff' : '#262626'),

    '&:hover': {
      borderBottomColor: (props) => (props.light ? '#8d8d8d' : '#6f6f6f'),
      backgroundColor: (props) => (props.light ? '#ffffff' : '#353535'),
    },

    '&.Mui-disabled': {
      borderBottomColor: (props) => (props.light ? '#c6c6c6' : '#525252'),
    },
    '&.Mui-disabled:hover': {
      cursor: 'not-allowed',
      backgroundColor: (props) => (props.light ? '#ffffff' : '#262626'),
    },

    '&::before': {
      border: 'none',
    },

    '&.Mui-focused': {
      outline: '2px solid transparent',
      outlineOffset: '-2px',
      outlineColor: (props) => (props.light ? '#0f62fe' : '#ffffff'),
    },
    '&.MuiInput-underline::after': {
      display: 'none',
    },
    '&.MuiInput-underline::before': {
      display: 'none',
    },
    '&.Mui-error': {
      outline: '2px solid #da1e28',
      outlineOffset: '-2px',
    },
  },
  input: {
    fontFamily: ['IBM Plex Sans', 'Helvetica Neue', 'Arial', 'sans-serif'],
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.125rem',
    letterSpacing: '0.16px',
    width: '100%',
    height: 'calc(2.5rem - 1px)',
    padding: '0 1rem !important',
    color: (props) => (props.light ? '#161616' : '#f4f4f4'),

    '.Mui-disabled &': {
      cursor: 'not-allowed',
      color: (props) => (props.light ? '#c6c6c6' : '#525252'),
    },
  },

  popper: {
    zIndex: 60000,
  },

  paper: {
    borderRadius: 0,
    margin: 0,
    boxShadow:
        '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },

  listbox: {
    fontFamily: ['IBM Plex Sans', 'Helvetica Neue', 'Arial', 'sans-serif'],
    padding: 0,
    maxHeight: (props) => (props.height ? `${2.5 * props.height + 1.25}rem` : '220px'),
    backgroundColor: (props) => (props.light ? '#ffffff' : '#393939'),

    '& .MuiAutocomplete-option': {
      display: 'flex',
      padding: '0.6875rem 0',
      margin: '0 1rem',
      paddingRight: '1.5rem',
      borderTop: '1px solid transparent',
      borderBottom: '1px solid transparent',
      borderTopColor: (props) => (props.light ? '#e0e0e0' : '#525252'),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& li': {
      color: (props) => (props.light ? '#525252' : '#c6c6c6'),
      textDecoration: 'none',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '1rem',
    },
    '& li:first-child': {
      borderTopColor: 'transparent',
    },
    '& li[data-focus], & li[aria-selected="true"]': {
      background: 'none',
      margin: '0',
      padding: '0.6875rem 1rem',
      borderColor: 'transparent',
      backgroundColor: (props) => (props.light ? '#e5e5e5' : '#575757'),
      color: (props) => (props.light ? '#161616' : '#f4f4f4'),
    },
    '& li[data-focus="true"] + li, & li[aria-selected="true"] + li': {
      borderColor: 'transparent',
    },
    '& li[aria-selected="true"]': {
      backgroundColor: (props) => (props.light ? '#e0e0e0' : '#525252'),
    },
    '& li svg': {
      flexShrink: 0,
    },
  },
  endAdornment: {
    top: 'calc(50% - 12px)',
    right: '1rem',
    color: (props) => (props.light ? 'inherit' : '#f4f4f4'),

    '.Mui-disabled > &': {
      top: 'calc(50% - 10px)',
      color: (props) => (props.light ? '#c6c6c6' : '#525252'),
    },

    '& .MuiIconButton-root': {
      color: 'inherit',
    },
  },
  noOptions: {
    fontFamily: ['IBM Plex Sans', 'Helvetica Neue', 'Arial', 'sans-serif'],
    fontSize: '0.875rem',
    backgroundColor: (props) => (props.light ? '#ffffff' : '#393939'),
    color: (props) => (props.light ? '#525252' : '#c6c6c6'),
  },
  groupLabel: {
    fontFamily: ['IBM Plex Sans', 'Helvetica Neue', 'Arial', 'sans-serif'],
    fontWeight: 'bold',
    color: '#525252',
    letterSpacing: '0.16px',
  },
}))
