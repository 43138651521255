import { combineReducers } from 'redux'
import datasource from '../features/datasource/redux/reducer'
import scenarios from '../features/scenarios/redux/reducer'
import preprocessing from '../features/preprocessing/redux/reducer'
import planning from '../features/planning/redux/reducer'
import engine from '../features/engine/redux/reducer'
import engineJobs from '../features/engineJobs/redux/reducer'
import results from '../features/results/redux/reducer'

const createRootReducer = () => combineReducers({
  datasource,
  scenarios,
  preprocessing,
  planning,
  engine,
  engineJobs,
  results,
})

export default createRootReducer
