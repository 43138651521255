import React, { useEffect } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { Switch, Route, useHistory } from 'react-router-dom'
import clsx from 'clsx'

import { getFiles, getData } from '../features/datasource/redux/actions'
import {
  getScenarios,
  setActiveScenario,
} from '../features/scenarios/redux/actions'
import routes, { flowSteps } from '../configuration'
import { AppHeader } from '../components/AppHeader'
import { FlowNavigation } from '../components/FlowNavigation'
import {
  selectActiveScenario,
  selectLatestScenario,
  selectScenariosDataById,
} from '../features/scenarios/redux/selectors'
import { ProgressIndicator } from '../components/progress'
import { useFreezeSubscription } from '../hooks/useFreezeSubscription'
import { useFilteredDataSubscription } from '../hooks/useFilteredDataSubscription'

export function App({ handleLogout }) {
  const dispatch = useDispatch()
  const activeScenario = useSelector(selectActiveScenario)
  const latestScenario = useSelector(selectLatestScenario)
  const scenariosById = useSelector(selectScenariosDataById)
  const history = useHistory()

  useFreezeSubscription()
  useFilteredDataSubscription()

  // Set the active scenario on app load
  useEffect(() => {
    if (activeScenario) return

    const cachedActiveScenarioId = localStorage.getItem('activeScenarioId')

    if (
      parseInt(cachedActiveScenarioId) &&
      scenariosById[cachedActiveScenarioId]
    ) {
      dispatch(setActiveScenario(scenariosById[cachedActiveScenarioId]))
    } else if (latestScenario) {
      dispatch(setActiveScenario(latestScenario))
    }
  }, [latestScenario, activeScenario, scenariosById])

  useEffect(() => {
    if (history?.location.search.search('debug') > -1) {
      localStorage.setItem('debug', true)
    }
    if (history?.location.search.search('debug=false') > -1) {
      localStorage.setItem('debug', false)
    }
  }, [history])

  useEffect(() => {
    batch(() => {
      dispatch(getFiles())
      dispatch(getScenarios())
    })
  }, [dispatch])

  useEffect(() => {
    if (activeScenario?.id) {
      dispatch(getScenarios())
      dispatch(getData(activeScenario.csv_upload_id))

      if (history?.location.pathname === '/') history.push('/review-input-data')
    }
  }, [activeScenario, history])

  return (
    <>
      <AppHeader handleLogout={handleLogout} />
      <div
        className={clsx('flow-nav dark-theme app-nav', {
          'is-enabled': !!activeScenario,
        })}
      >
        <div className="app-nav-flow">
          <ProgressIndicator steps={flowSteps} />
        </div>
      </div>
      <Switch>
        {routes.map(({ component: Component, ...route }) => (
          <Route key={route.label} {...route}>
            <Component />
          </Route>
        ))}
      </Switch>
    </>
  )
}
