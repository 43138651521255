import React  from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AccordionItem } from 'carbon-components-react'
import clsx from 'clsx'
import { StatefulComboSelect } from '../../components/StatefulComboSelect'
import { usePreviousWeekOperations } from '../../hooks/usePreviousWeekOperations'
import { selectRetainOperations } from './redux/selectors'
import { setRetainOperations } from './redux/actions'
import { useUpdateSelectedOperations } from './redux/useUpdateSelectedOperations'

export const PreviousWeekOperations = ({ isLocked }) => {
  const dispatch = useDispatch()
  useUpdateSelectedOperations()
  const { previousOperationCodes } = usePreviousWeekOperations()
  const selectedOperations = useSelector(selectRetainOperations)
  const allCodes = new Set([...previousOperationCodes, ...selectedOperations])

  const handleSelectAllOperations = () => {
    if (selectedOperations.length === allCodes.size) {
      dispatch(setRetainOperations([]))
    } else {
      dispatch(setRetainOperations([...allCodes]))
    }
  }
  const handleOperationsChange = (operations) => {
    dispatch(setRetainOperations([...operations]))
  }

  return (
    <AccordionItem open title="Previous week operations">
      <div className="mb-1">
        <div className="flexbox">
          <span
            className={clsx('bx--label', {
              'bx--label--disabled': isLocked,
            })}
          >
            Retain operations
          </span>

          {!isLocked && (
            <a
              href="#"
              onClick={handleSelectAllOperations}
              style={{ marginLeft: 'auto' }}
            >
              <span className="bx--label">
                {allCodes.size > 0 &&
                allCodes.size === selectedOperations.length
                  ? 'Deselect all'
                  : 'Select all'}
              </span>
            </a>
          )}
        </div>
        <StatefulComboSelect
          multiple
          id="previousoperations"
          items={[...allCodes].sort()}
          selectedItems={selectedOperations}
          placeholder="Retain operations"
          disabled={isLocked}
          onClose={handleOperationsChange}
        />
      </div>
    </AccordionItem>
  )
}
