import moment from 'moment'
import sortObjectKeys from 'sort-object-keys'
import { mapResourceAvailability } from '../../preprocessing/redux/utils'
import {
  SET_INPUT,
  SET_SCORE,
  SET_RESOURCES,
  SET_RESOURCES_LIST,
  RESET_INPUT,
  SET_DAILY_RESOURCES,
} from './actions'
import { SET_INPUT_WORKCENTERS } from '../../preprocessing/redux/actions'
import {
  getNextWeekISO8601Year,
  getNextWeekNumber,
} from '../../../utils/moment-date'
import { SET_ACTIVE_SCENARIO } from '../../scenarios/redux/actions'

const initialState = {
  desiredWeek: getNextWeekNumber(moment()),
  desiredYear: getNextWeekISO8601Year(moment()),
  backlogWeeks: 8,
  scores: {
    distantBacklog: '1',
    backlog: '3',
    desiredWeek: '4',
    future: '2',
  },
  resources: {},
  dailyResources: {
    Monday: {},
    Tuesday: {},
    Wednesday: {},
    Thursday: {},
    Friday: {},
  },
  completed: false,
}

const reducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_INPUT:
      return {
        ...state,
        ...payload,
      }

    case RESET_INPUT:
      return { ...initialState }

    case SET_ACTIVE_SCENARIO: {
      return {
        ...state,
        ...initialState,
      }
    }

    case SET_SCORE:
      return {
        ...state,
        scores: {
          ...state.scores,
          ...payload,
        },
      }

    case SET_RESOURCES:
      return {
        ...state,
        resources: {
          ...state.resources,
          [payload.name]: {
            ...state.resources[payload.name],
            [payload.property]: payload.value,
          },
        },
        dailyResources: setDailyResourcesBase(state, payload),
      }

    case SET_RESOURCES_LIST:
      return setResourcesList(state, payload)

    case SET_DAILY_RESOURCES:
      return {
        ...state,
        dailyResources: setDailyResources(state, payload),
      }

    case SET_INPUT_WORKCENTERS:
      return {
        ...state,
        dailyResources: setWorkcentersInDailyResources(state, payload),
      }

    default:
      return state
  }
}

function setResourcesList(state, payload) {
  return {
    ...state,
    resources: sortObjectKeys(payload),
  }
}

function setDailyResources(state, payload) {
  const dailyResources = { ...state.dailyResources }
  const {
    weekday, workcenter, key, value,
  } = payload

  dailyResources[weekday] = {
    ...dailyResources[weekday],
    [workcenter]: {
      ...dailyResources[weekday][workcenter],
      [key]: value,
    },
  }

  return dailyResources
}

function setWorkcentersInDailyResources(state, payload) {
  if (!payload) {
    return state
  }

  const dailyResources = { ...state.dailyResources }
  const weekdays = Object.keys(dailyResources)

  // Add workcenters if it doesn't exist
  weekdays.forEach((weekday) => {
    payload.forEach((workcenter) => {
      if (dailyResources[weekday][workcenter]) return

      dailyResources[weekday][workcenter] = {
        availability: mapResourceAvailability(workcenter),
        capacity: 1,
      }
    })
  })

  // Delete workcenters
  weekdays.forEach((weekday) => {
    Object.keys(dailyResources[weekday]).forEach((workcenter) => {
      if (!payload.includes(workcenter)) {
        delete dailyResources[weekday][workcenter]
      }
    })
  })

  return dailyResources
}

const setDailyResourcesBase = (state, payload) => {
  const dailyResources = { ...state.dailyResources }
  const { name, value, property } = payload

  // if (property === 'capacity') {
  //   return dailyResources
  // }

  const weekdays = Object.keys(dailyResources)

  weekdays.forEach((weekday) => {
    if (
      dailyResources[weekday]
      && dailyResources[weekday][name]
      && dailyResources[weekday][name][property] >= 0
    ) dailyResources[weekday][name][property] = parseFloat(value)
  })

  return dailyResources
}

export default reducer
