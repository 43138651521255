import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ResponsiveLine } from '@nivo/line'
import { selectPlanningState } from './redux/selectors'
import { mapScoresToChart } from './redux/utils'
import { mapScore } from '../engine/redux/utils'

const ScoresChart = () => {
  const planningState = useSelector(selectPlanningState)

  const chartData = useMemo(() => {
    if (planningState && planningState.scores) {
      return mapScoresToChart(planningState)
    }
    return []
  }, [planningState])

  if (!chartData?.length) return null

  return (
    <div>
      <h4>Score assignment</h4>

      <div style={{ height: '500px' }}>
        <ResponsiveLine
          data={chartData}
          margin={{
            top: 50,
            right: 10,
            bottom: 160,
            left: 50,
          }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 4,
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="step"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Time [weeks]',
            legendOffset: 46,
            legendPosition: 'middle',
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: [1, 2, 3, 4],
            format: (v) => mapScore(v),
            legend: 'Score',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          lineWidth={0}
          enablePoints={false}
          pointSize={10}
          colors={['#66aac7', '#0067a6', '#83bb06', '#93d502']}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          enableArea
          areaOpacity={1}
          useMesh={false}
          legends={[
            {
              anchor: 'bottom-left',
              direction: 'column',
              justify: false,
              translateX: 0,
              translateY: 150,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  )
}

export default ScoresChart
