import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles(() => createStyles({
  colorPrimary: {
    color: '#0f61fe',
  },
  rail: {
    backgroundColor: '#393939',
    opacity: 1,
  },
  track: {
    backgroundColor: '#ffffff',
  },
  thumb: {
    width: '0.875rem',
    height: '0.875rem',
    backgroundColor: '#f4f4f4',
  },
}))

export function BasicSlider(props) {
  const {
    min = 0,
    max = 100,
    step = 1,
    value,
    id,
    className,
    labelText,
    onChange,
    valueLabelFormat,
    disabled,
  } = props

  const [ownValue, setOwnValue] = useState(value || 0)
  const classes = useStyles()

  useEffect(() => {
    if (value !== undefined) {
      setOwnValue(value)
    }
  }, [value])

  const onSliderChange = (e, value) => {
    setOwnValue(value)
  }

  const onSliderRelease = (e, value) => {
    onChange(value)
  }

  return (
    <div className={className}>
      {labelText && (
        <span
          className={clsx('bx--label', {
            'bx--label--disabled': disabled,
          })}
        >
          {labelText}
        </span>
      )}
      <div className="app--rangeslider">
        <Slider
          id={id}
          value={ownValue || 0}
          onChange={onSliderChange}
          onChangeCommitted={onSliderRelease}
          min={min}
          max={max}
          step={step}
          valueLabelFormat={valueLabelFormat}
          valueLabelDisplay="auto"
          aria-labelledby="slider"
          classes={classes}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
