import React from 'react'
import { CenterOperations } from '../preprocessing/redux/useFreezableOperations'
import styled from '@emotion/styled'
import { Dropdown, Button } from 'carbon-components-react'

import { TransparentButton } from '../../components/transparent-button'
import { useDispatch } from 'react-redux'
import { clearFreezeIds } from '../preprocessing/redux/actions'

export interface SidebarProps {
  uniqueCenters: CenterOperations[]
  selectedCenter?: CenterOperations
  selectCenter: (center?: CenterOperations) => void
  isLocked?: boolean
}

const Label = styled.div`
  padding: 1rem;
`

const ButtonContainer = styled.div`
  padding: 0.5rem;
  text-align: right;
`

export const Sidebar = (props: SidebarProps) => {
  const { uniqueCenters, selectedCenter, selectCenter, isLocked } = props
  const dispatch = useDispatch()

  return (
    <div>
      {uniqueCenters.length === 0 && <Label>No centers found</Label>}
      {uniqueCenters.length === 1 && (
        <Label>
          {uniqueCenters[0].responsibleEntity} (
          {uniqueCenters[0].frozenOperationCount})
        </Label>
      )}
      {uniqueCenters.length > 1 && (
        <Dropdown<CenterOperations>
          id="unique-centers-select"
          items={uniqueCenters}
          selectedItem={selectedCenter}
          label="Choose a center"
          itemToString={(item) =>
            `${item.responsibleEntity} (${item.frozenOperationCount})`
          }
          onChange={(data) => selectCenter(data.selectedItem || undefined)}
        />
      )}

      <ButtonContainer>
        <TransparentButton
          dark
          disabled={isLocked}
          onClick={() => dispatch(clearFreezeIds())}
        >
          Clear all
        </TransparentButton>
      </ButtonContainer>
    </div>
  )
}
