import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderNavigation,
  HeaderMenu,
  HeaderMenuItem,
} from 'carbon-components-react'
import {
  Logout20,
  Settings16,
  AddAlt16,
  Copy16,
  Locked16,
  DataBase20,
} from '@carbon/icons-react'
import FeedbackDialogButton from '../features/feedback/FeedbackDialogButton'
import CreatePlanModal from '../features/scenarios/CreatePlanModal'
import ClonePlanModal from '../features/scenarios/ClonePlanModal'
import {
  selectActiveScenario,
  selectScenariosDataById,
  selectScenariosDataAllIds,
} from '../features/scenarios/redux/selectors'
import { setActiveScenario } from '../features/scenarios/redux/actions'
import { ProjectHeaderAction } from './project-header-action'

export const AppHeader = ({ handleLogout }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const debugMode = JSON.parse(localStorage.getItem('debug'))

  const [openNewPlanModal, setOpenNewPlanModal] = useState(false)
  const [openClonePlanModal, setOpenClonePlanModal] = useState(false)

  const activeScenario = useSelector(selectActiveScenario)
  const scenariosById = useSelector(selectScenariosDataById)
  const scenariosIds = useSelector(selectScenariosDataAllIds)

  const onLoadScenario = (id) => () => {
    dispatch(setActiveScenario(scenariosById[id]))
  }

  const goToDatasource = () => {
    history.push('/datasource')
  }

  const onNewPlanOpen = () => {
    setOpenNewPlanModal(true)
  }

  const onNewPlanClose = () => {
    setOpenNewPlanModal(false)
  }

  const onClonePlanOpen = () => {
    setOpenClonePlanModal(true)
  }

  const onClonePlanClose = () => {
    setOpenClonePlanModal(false)
  }

  return (
    <>
      <Header
        aria-label=""
        className={clsx({
          debug: debugMode,
        })}
      >
        <HeaderName element={Link} to="/" prefix="">
          Borregaard Maintenance Planner
        </HeaderName>

        <HeaderNavigation aria-label="">
          <HeaderMenu aria-label="Plans" menuLinkName="Plans">
            <HeaderMenuItem
              element={Link}
              to="/plans"
              className="app-header-menu-item--hasIcon"
            >
              <Settings16 />
              <span>Manage plans</span>
            </HeaderMenuItem>
            <HeaderMenuItem
              onClick={onNewPlanOpen}
              className="app-header-menu-item--hasIcon"
            >
              <AddAlt16 />
              <span>Create new plan</span>
            </HeaderMenuItem>
            <HeaderMenuItem
              onClick={onClonePlanOpen}
              className={clsx(
                'app-header-menu-item--hasIcon app-header-menu-item--isLastOne',
                {
                  'is-disabled': !activeScenario,
                },
              )}
            >
              <Copy16 />
              <span>Save existing as new</span>
            </HeaderMenuItem>

            {scenariosById
              && [...scenariosIds].slice(0, 5).map((id) => (
                <React.Fragment key={id}>
                  <HeaderMenuItem
                    element={Link}
                    to="/review-input-data"
                    onClick={onLoadScenario(id)}
                  >
                    {scenariosById[id].name}
                  </HeaderMenuItem>
                </React.Fragment>
              ))}
          </HeaderMenu>
        </HeaderNavigation>

        {activeScenario && (
          <>
            <span className="active-scenario bx--tag ml-1" title="Active plan">
              <strong>Active plan:</strong>&nbsp;
              {activeScenario.name}
            </span>
            {activeScenario.engine_job_id && (
              <span className="bx--tag" title="Locked">
                <Locked16 />
                &nbsp;Locked
              </span>
            )}
          </>
        )}

        <HeaderGlobalBar>
          <ProjectHeaderAction
            tooltipText="Data source"
            onClick={goToDatasource}
          >
            <DataBase20 />
          </ProjectHeaderAction>
          {/* <HeaderGlobalAction */}
          {/*  aria-label="Data source" */}
          {/*  title="Data source" */}
          {/*  onClick={goToDatasource} */}
          {/* > */}
          {/*  <DataBase20 /> */}
          {/* </HeaderGlobalAction> */}
          <FeedbackDialogButton />

          <ProjectHeaderAction
            tooltipDirection="end"
            tooltipText="Log out"
            onClick={handleLogout}
          >
            <Logout20 />
          </ProjectHeaderAction>
        </HeaderGlobalBar>
      </Header>

      <CreatePlanModal open={openNewPlanModal} onClose={onNewPlanClose} />
      <ClonePlanModal open={openClonePlanModal} onClose={onClonePlanClose} />
    </>
  )
}
