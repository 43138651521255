import React from 'react'
import { useSelector } from 'react-redux'
import Papa from 'papaparse'
import { Button } from 'carbon-components-react'
import { DocumentDownload32 } from '@carbon/icons-react'
import { selectActiveScenario } from '../scenarios/redux/selectors'

export default function Toolbar({ results, noResults }) {
  const activeScenario = useSelector(selectActiveScenario)

  const handleExport = () => {
    if (!results?.plan?.length) return false

    try {
      const data = [...results.plan]

      data.forEach((plan) => {
        delete plan.operations_data_row_id
      })

      const fields = Object.keys(data[0])

      const formattedData = data.map((row) => {
        const formattedRow = { ...row }
        Object.keys(row).forEach((col) => {
          formattedRow[col] =
            typeof row[col] === 'number' && row[col] < 100
              ? String(row[col]).replace('.', ',') // use comma in decimals
              : row[col]
        })
        return formattedRow
      })
      const csv = Papa.unparse(
        {
          data: formattedData,
          fields,
        },
        {
          delimiter: ';',
        }
      )
      const blob = new Blob([csv])
      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = !!activeScenario
        ? `[final plan] ${activeScenario.name}.csv`
        : 'final-plan.csv'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="toolbar dark-theme sidebar">
      <Button
        kind="tertiary"
        size="field"
        renderIcon={DocumentDownload32}
        onClick={handleExport}
        disabled={noResults}
      >
        Export final plan
      </Button>
    </div>
  )
}
