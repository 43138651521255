import axios from 'axios'

export default function checkToken() {
  const url = `${window.REACT_APP_API_URL}/protected`

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }

  return axios
    .get(url, config)
    .then(() => true)
    .catch(() => false)
}
