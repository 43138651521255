import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { store, runSagaMiddleware } from './redux/store'
import { LoginContainer } from './containers/LoginContainer'
// import ErrorBoundary from './components/ErrorBoundary'

import '@whitespace/internal-whitespace-frontend-package/dist/css/index.css'
import './assets/scss/app.scss'

runSagaMiddleware()

ReactDOM.render(
  <Router>
    <Provider store={store}>
      {/* <ErrorBoundary> */}
      <LoginContainer />
      {/* </ErrorBoundary> */}
    </Provider>
  </Router>,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept(LoginContainer)
}
