import React, { useMemo } from 'react'
import moment from 'moment'
import { FilterTable } from '../../components/FilterTable'

const FINAL_PLAN_HEADERS = [

  {
    key: 'work_order_id',
    header: 'Work Order Id',
  },
  {
    key: 'operation_id',
    header: 'Operation Id',
  },
  {
    key: 'func_loc',
    header: 'Func Loc',
    nowrap: true,
  },
  {
    key: 'description',
    header: 'Description',
  },
  {
    key: 'responsible_entity',
    header: 'Responsible Entity',
  },
  {
    key: 'scheduled_start_date',
    header: 'Scheduled Start Date',
    format: (value) => (value ? moment(value).format('YYYY-MM-DD') : value),
    nowrap: true,
  },
  {
    key: 'scheduled_end_date',
    header: 'Scheduled End Date',
    format: (value) => (value ? moment(value).format('YYYY-MM-DD') : value),
    nowrap: true,
  },
  {
    key: 'priority',
    header: 'Priority',
  },
  {
    key: 'revision_code',
    header: 'Revision Code',
  },
  {
    key: 'operation_status',
    header: 'Operation Status',
  },
  {
    key: 'plan_ikke',
    header: 'Plan Ikke',
  },
  {
    key: 'number_of_persons',
    header: 'Number Of Persons',
  },
  {
    key: 'duration_hours',
    header: 'Duration Hours',
  },
  {
    key: 'popup',
    header: 'Popup',
  },
  {
    key: 'calculated_start',
    header: 'Calculated Start',
    nowrap: true,
  },
  {
    key: 'calculated_end',
    header: 'Calculated End',
    nowrap: true,
  },
  {
    key: 'staff_names',
    header: 'Staff Names',
  },
]

export default function Plan({ data }) {
  const rows = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map((x, i) => ({
      id: String(x.operations_data_row_id),
      ...x,
    }))
  }, [data])

  return (
    <div className="mt-2">
      <FilterTable rows={rows} headers={FINAL_PLAN_HEADERS} />
    </div>
  )
}
