import { useDispatch, useSelector } from 'react-redux'
import { selectOutputData, selectPlanningFreezeIds } from '../features/preprocessing/redux/selectors'
import { useEffect } from 'react'
import { setFreezeIds } from '../features/preprocessing/redux/actions'
import { selectDatasourceDataIsFetching } from '../features/datasource/redux/selectors'

const isEqual = (a: number[], b: number[]) => {
  if (a.length !== b.length) {
    return false
  }

  return a.filter((v: number) => b.includes(v)).length === a.length
}

export const useFreezeSubscription = () => {
  const data = useSelector(selectOutputData)
  const isFetching = useSelector(selectDatasourceDataIsFetching)

  const frozen = useSelector(selectPlanningFreezeIds)
  const dispatch = useDispatch()

  const freezeIds = data?.freeze_ids

  useEffect(() => {
    if (typeof freezeIds === 'undefined') {
      return
    }

    const remaining = frozen.filter((freezeId: number) =>
      freezeIds?.includes(freezeId),
    )

    if (!isEqual(remaining, frozen)) {
      dispatch(setFreezeIds(remaining))
    }
  }, [freezeIds, frozen])
}
