import {
  START_ENGINE,
  START_ENGINE_SUCCESS,
  START_ENGINE_FAIL,
  CHECK_ENGINE_SUCCESS,
  CHECK_ENGINE_FAIL,
  CHECK_ENGINE_STATUS_SUCCESS,
  CHECK_ENGINE_STATUS_FAIL,
  RESET_ENGINE,
  SET_ENGINE_STATE,
} from './actions'

const initialState = {
  isRunning: false,
  error: null,
  success: false,
  status: null,
  jobId: null,
  prioritizeShortJobs: false,
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ENGINE_STATE:
      return {
        ...state,
        ...payload,
      }
    case START_ENGINE:
      return {
        ...state,
        isRunning: true,
        error: null,
        success: false,
        status: null,
        jobId: null,
      }

    case START_ENGINE_FAIL:
      return {
        ...state,
        error: payload,
        isRunning: false,
        success: false,
        jobId: null,
      }

    case START_ENGINE_SUCCESS:
      return {
        ...state,
        error: null,
        isRunning: true,
        jobId: payload,
      }

    case CHECK_ENGINE_SUCCESS:
      return checkEngineSuccess(state, payload)

    case CHECK_ENGINE_STATUS_SUCCESS:
      return checkEngineStatusSuccess(state, payload)

    case CHECK_ENGINE_FAIL:
    case CHECK_ENGINE_STATUS_FAIL:
      return {
        ...state,
        error: payload,
        success: false,
      }
    case RESET_ENGINE:
      return initialState

    default:
      return state
  }
}

function checkEngineSuccess(state, payload) {
  const isRunning =
    payload.status === 'RUNNING' || payload.status === 'SCHEDULED'
      ? true
      : false

  const prioritizeShortJobs = payload.hasOwnProperty('prioritize_short_jobs')
    ? payload.prioritize_short_jobs
    : state.prioritizeShortJobs

  return {
    ...state,
    isRunning,
    status: payload.status || payload.message,
    error: payload.error || null,
    prioritizeShortJobs,
  }
}

function checkEngineStatusSuccess(state, payload) {
  const isRunning =
    payload.message === 'Job started successfully' ||
    payload.status === 'RUNNING' ||
    payload.status === 'SCHEDULED'
      ? true
      : false

  return {
    ...state,
    isRunning,
    status: payload.status || payload.message,
    error: payload.error || null,
  }
}

export default reducer
