import { IconButton as UIIconButton } from '@whitespace/ui'
import styled from '@emotion/styled'

export const IconButton = styled(UIIconButton)`
  margin: 0.25rem;

  &:hover {
    color: unset;
  }
`
