import React from 'react'

import {
  Grid, Row, Column, Tabs, Tab,
} from 'carbon-components-react'
import { DelayedRender } from '../../components/DelayedRender'
import Workload from './charts/Workload'
import Metrics from './Metrics'
import Plan from './Plan'
import { CurrentWeekOperationsOverview } from './current-week-operations-overview/index'

export default function Main({ results, noResults = true }) {
  if (noResults) {
    return (
      <DelayedRender delay={1000}>
        <Grid fullWidth>
          <Row>
            <Column>There are no results available.</Column>
          </Row>
        </Grid>
      </DelayedRender>
    )
  }

  return (
    <Grid style={{ maxWidth: '100%' }}>
      <Row>
        <Column style={{ position: 'relative' }}>
          <Tabs>
            <Tab
              id="tab-1"
              label="Workload per work center"
              className="tab--large"
            >
              <Workload data={results.workload} />
            </Tab>
            <Tab id="tab-2" label="Metrics" className="tab--large">
              <Metrics data={results.metrics} />
            </Tab>
            <Tab id="tab-3" label="Final plan" className="tab--large">
              <Plan data={results.plan} />
            </Tab>
            <Tab id="tab-4" label="Unplanned operations" className="tab--large">
              <CurrentWeekOperationsOverview data={results} />
            </Tab>
          </Tabs>
        </Column>
      </Row>
    </Grid>
  )
}
