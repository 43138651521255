export const GET_ENGINE_JOBS = '[engine jobs] GET'
export const GET_ENGINE_JOBS_SUCCESS = '[engine jobs] GET :success'
export const GET_ENGINE_JOBS_FAIL = '[engine jobs] GET :fail'

export const getEngineJobs = (payload) => ({
  type: GET_ENGINE_JOBS,
  payload,
})

export const getEngineJobsSuccess = (payload) => ({
  type: GET_ENGINE_JOBS_SUCCESS,
  payload,
})

export const getEngineJobsFail = (payload) => ({
  type: GET_ENGINE_JOBS_FAIL,
  payload,
})
