import React from 'react'
import { useSelector } from 'react-redux'

import { selectDatasourceData } from '../datasource/redux/selectors'
import { Page } from '../../components/Page'
import Sidebar from './Sidebar'
import Main from './Main'
import EngineToolbar from '../engine/EngineToolbar'
import { selectActiveScenario } from '../scenarios/redux/selectors'

export default function Container() {
  const data = useSelector(selectDatasourceData)
  const activeScenario = useSelector(selectActiveScenario)

  if (!data || !data.length) {
    return null
  }

  const isLocked = !!activeScenario?.engine_job_id
  const sidebarProps = {
    isLocked,
  }

  return (
    <Page
      SidebarComponent={Sidebar}
      sidebarProps={sidebarProps}
      ContentComponent={Main}
      ToolbarComponent={EngineToolbar}
    />
  )
}
