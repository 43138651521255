import Case from 'case'

export const mapDataToWorkloadPerWorkcenter = (data) => {
  const chartData = []
  data.forEach((op) => {
    const mappedOp = {}
    Object.keys(op).forEach((key) => {
      mappedOp[Case.sentence(key)] = op[key]
    })

    chartData.push({
      ...mappedOp,
      [Case.sentence('input_workload')]:
        op.input_workload - op.executed_workload,
    })
  })

  return chartData
}

export const mapDataToOperationsPerTimeSegment = (data) => {
  const chartData = []

  data.forEach((op) => {
    const mappedOp = {}
    Object.keys(op).forEach((key) => {
      switch (key) {
        case 'priority_type_operations':
          mappedOp[Case.sentence(key)] = Case.capital(
            op[key].replace('operations', '')
          )
          break
        case 'time_type_operations':
          mappedOp[Case.sentence(key)] = Case.sentence(op[key])
          break
        default:
          mappedOp[Case.sentence(key)] = op[key]
      }
    })

    chartData.push({
      ...mappedOp,
      [Case.sentence('input_operations')]:
        op.input_operations - op.executed_operations,
    })
  })

  return chartData
}

export const mapDataToUtilizationPerWorkcenter = (data) => {
  const chartData = Object.keys(data).map((workcenter) => {
    return {
      workcenter,
      value: parseFloat(data[workcenter]),
      grey: 100 - parseFloat(data[workcenter]),
    }
  })

  return chartData
}
