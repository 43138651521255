import styled from '@emotion/styled'
import { theme } from '@whitespace/ui'

export interface TransparentButtonProps {
  dark?: boolean
}

export const TransparentButton = styled.button<TransparentButtonProps>`
  background: none !important;
  border: none;
  color: ${(props) => (props.dark ? theme.text01 : theme.textInverse)};
  padding: 0 !important;
  cursor: pointer;
  outline: unset;

  &:disabled {
    cursor: not-allowed;
    color: ${(props) => (props.dark ? theme.ui04 : theme.ui04)};
  }

  &:focus {
    border: unset;
    border-color: unset;
    box-shadow: unset;

    &:disabled {
      color: ${(props) => (props.dark ? theme.ui04 : theme.ui04)};
    }
  }

  &:hover {
    color: inherit;
    outline: unset;

    &:disabled {
      color: ${(props) => (props.dark ? theme.ui04 : theme.ui04)};
    }
  }
`
