import { client } from '../../../api/client'

async function getAllUploads() {
  const response = await client({ method: 'GET', url: '/csv_uploads' })
  return response.data
}

async function getUploadData(csv_upload_id) {
  const response = await client({
    method: 'GET',
    url: '/operations_data_rows',
    params: {
      csv_upload_id,
    },
  })
  return response.data
}

async function upload(name, headers, body) {
  const response = await client({
    method: 'POST',
    url: `/csv_uploads?name=${name}`,
    headers,
    data: body,
  })
  return response.data
}

async function deleteUpload(csv_upload_id) {
  const response = await client({
    method: 'DELETE',
    url: `/csv_uploads/${csv_upload_id}`,
  })
  return response.data
}

export default {
  upload,
  getUploadData,
  getAllUploads,
  deleteUpload,
}
