import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRetainOperations } from './actions'
import { usePreviousWeekOperations } from '../../../hooks/usePreviousWeekOperations'
import { selectRetainOperations } from './selectors'

export const useUpdateSelectedOperations = () => {
  const dispatch = useDispatch()
  const { notAllowedCodes } = usePreviousWeekOperations()
  const selectedOperations = useSelector(selectRetainOperations)

  useEffect(() => {
    if (notAllowedCodes.length === 0) {
      return
    }

    const updatedSelected = selectedOperations.filter((selectedOperation) => notAllowedCodes.includes(selectedOperation))

    if (updatedSelected.length !== selectedOperations.length) {
      dispatch(setRetainOperations(updatedSelected))
    }
  }, [notAllowedCodes, selectedOperations])
}
