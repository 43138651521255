import moment from 'moment'
import * as labels from './labels'

const headersMap = [
  {
    key: 'operation',
    header: 'Operation',
    width: 150,
  },
  {
    key: 'description',
    header: 'Description',
    width: 300,
  },
  {
    key: 'responsible_entity',
    header: 'Work center',
  },
  {
    key: 'operation_status',
    header: 'Operation status',
  },
  {
    key: 'plan_ikke',
    header: 'Plan/Ikke',
  },
  {
    key: 'priority',
    header: 'Priority',
  },
  {
    key: 'duration_hours',
    header: 'Duration',
  },
  {
    key: 'number_of_persons',
    header: 'Nr. of persons',
  },
  {
    key: 'scheduled_start_date',
    header: 'Start date',
    format: (value) => (value ? moment(value).format('YYYY-MM-DD') : ''),
  },
  {
    key: 'scheduled_end_date',
    header: 'End date',
    format: (value) => (value ? moment(value).format('YYYY-MM-DD') : ''),
  },
  {
    key: 'revision_code',
    header: 'Revision code',
  },
  {
    key: 'func_loc',
    header: 'Functional location',
  },
]

const getHeaders = (keys) =>
  keys.map((k) => headersMap.find((h) => h.key === k))

const headersConfig = {
  [labels.TOTAL_OPERATIONS]: getHeaders([
    'operation',
    'description',
    'responsible_entity',
  ]),
  [labels.REQUIRED_WORKCENTERS]: getHeaders([
    'operation',
    'description',
    'responsible_entity',
    'scheduled_start_date',
    'scheduled_end_date',
  ]),
  [labels.STATUS]: getHeaders([
    'operation',
    'description',
    'operation_status',
    'plan_ikke',
  ]),
  [labels.IKKE]: getHeaders([
    'operation',
    'description',
    'operation_status',
    'plan_ikke',
  ]),
  [labels.PRIORITY]: getHeaders(['operation', 'description', 'priority']),
  [labels.ZERO_DURATION]: getHeaders([
    'operation',
    'description',
    'duration_hours',
  ]),
  [labels.ZERO_STAFF]: getHeaders([
    'operation',
    'description',
    'number_of_persons',
  ]),
  [labels.TIME_WINDOW]: getHeaders([
    'operation',
    'description',
    'scheduled_start_date',
    'scheduled_end_date',
  ]),
  [labels.NON_STOP_OPERATIONS_WITH_REVISION_CODES]: getHeaders([
    'operation',
    'description',
    'revision_code',
  ]),
  [labels.NOT_ALLOWED_IN_WEEK]: getHeaders(['operation', 'description']),
  [labels.ALREADY_PLANNED]: getHeaders([
    'operation',
    'description',
    'scheduled_start_date',
    'scheduled_end_date',
  ]),
  [labels.BEYOND]: getHeaders([
    'operation',
    'description',
    'scheduled_start_date',
    'scheduled_end_date',
  ]),
  [labels.S_OPERATIONS]: getHeaders([
    'operation',
    'description',
    'revision_code',
    'func_loc',
  ]),
  [labels.RETAINED_OPERATIONS]: getHeaders([
    'operation',
    'description',
    'responsible_entity',
    'priority',
    'func_loc',
  ]),
}

export const getHeadersConfig = (name) => {
  let headers = []
  Object.keys(headersConfig).forEach((header) => {
    if (name && name.search(header) > -1) {
      headers = headersConfig[header]
    }
  })
  return headers
}
