import React from 'react'
import {
  ProgressIndicator,
  ProgressStep as CarbonProgressStep,
} from 'carbon-components-react'
import { useHistory, useLocation } from 'react-router-dom'

export function FlowNavigation({ steps }) {
  return (
    <ProgressIndicator>
      {steps.map((step) => {
        return (
          <ProgressStep
            href={step.path}
            label={step.label}
            key={step.label}
            disabled={step.disabled}
          />
        )
      })}
    </ProgressIndicator>
  )
}

const ProgressStep = ({ href, label, disabled }) => {
  const history = useHistory()
  const location = useLocation()

  const onClickHandler = () => {
    history.push(href)
  }

  const renderLabel = ({ children }) => {
    return (
      <span className="bx--progress-label ovr-flow-nav-label">{children}</span>
    )
  }

  return (
    <CarbonProgressStep
      current={location.pathname === href}
      onClick={onClickHandler}
      label={label}
      renderLabel={renderLabel}
      disabled={disabled}
    />
  )
}
