import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import Case from 'case'
import moment from 'moment'
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Pagination,
  TextInput,
  Tag,
} from 'carbon-components-react'
import {
  Delete16 as Delete,
  Edit16 as Edit,
  Save16,
  Star16,
  Locked16,
  CheckmarkOutline16,
  MisuseOutline16,
  InProgress16,
  StarFilled16,
} from '@carbon/icons-react'
import { Tooltip } from '@whitespace/ui'
import {
  selectScenariosDataById,
  selectScenariosDataAllIds,
  selectActiveScenario,
} from './redux/selectors'
import * as actions from './redux/actions'
import * as engineJobsActions from '../engineJobs/redux/actions'
import { selectDatasourceFiles } from '../datasource/redux/selectors'
import { selectEngineJobs } from '../engineJobs/redux/selectors'
import { PrimaryIconButton } from '../../components/PrimaryIconButton'
import { IconButton } from '../../components/IconButton'

const PAGE_SIZES = [10, 20, 50, 100, 200]

export function ScenariosTable() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [paginatedRows, setPaginatedRows] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [editing, setEditing] = useState()
  const [planName, setPlanName] = useState('')

  const scenariosById = useSelector(selectScenariosDataById)
  const scenariosIds = useSelector(selectScenariosDataAllIds)
  const activeScenario = useSelector(selectActiveScenario)
  const files = useSelector(selectDatasourceFiles)
  const engineJobs = useSelector(selectEngineJobs)

  const onPaginationChange = (pagination) => {
    setPage(pagination.page)
    setPageSize(pagination.pageSize)
  }

  const onActiveScenario = (id) => () => {
    if (activeScenario.id === id) {
      history.push('/review-input-data')
    } else {
      dispatch(actions.setActiveScenario(scenariosById[id]))
    }
  }

  const onDelete = (id) => () => {
    dispatch(actions.deleteScenario(id))
  }

  const paginate = (initialRows, page, pageSize) => {
    const rows = [...initialRows]
    return rows.splice((page - 1) * pageSize, pageSize)
  }

  const handleEdit = (id) => () => {
    setPlanName(scenariosById[id].name)
    setEditing(id)
  }

  const handleSave = () => {
    dispatch(
      actions.updateScenario({
        id: editing,
        body: {
          name: planName,
        },
      }),
    )
    setPlanName('')
    setEditing()
  }

  const onEditPlanName = (e) => {
    setPlanName(e.target.value)
  }

  const onKeypressEditPlanName = (e) => {
    if (e.key === 'Enter') {
      handleSave()
    }
  }

  const rows = useMemo(() => {
    if (!scenariosIds) return []
    return [...scenariosIds].map((id) => {
      const engineStatus = engineJobs && engineJobs[scenariosById[id].engine_job_id]
        ? engineJobs[scenariosById[id].engine_job_id].status
        : ''

      return {
        ...scenariosById[id],
        id: String(scenariosById[id].id),
        locked: scenariosById[id].engine_job_id ? <LockedTag /> : '',
        engineStatus: <StatusTag status={engineStatus} />,
        created: moment(scenariosById[id].created).format('YYYY-MM-DD'),
      }
    })
  }, [scenariosIds, scenariosById, engineJobs])

  const headers = [
    {
      key: 'name',
      header: 'Name',
    },
    {
      key: 'csv_upload_id',
      header: 'CSV file name',
    },
    {
      key: 'created',
      header: 'Created on',
    },
    { key: 'locked', header: 'Locked' },
    { key: 'engineStatus', header: 'Engine status' },
  ]

  // Effects
  useEffect(() => {
    dispatch(engineJobsActions.getEngineJobs())
  }, [])

  useEffect(() => {
    if (rows?.length) {
      const paginatedRows = paginate(rows, page, pageSize)
      setPaginatedRows(paginatedRows)

      if (rows.length <= (page - 1) * pageSize) {
        setPage(1)
      }
    }
  }, [rows, page, pageSize])

  if (!rows || !headers) {
    return null
  }

  return (
    <div>
      <DataTable rows={paginatedRows} headers={headers} isSortable>
        {({
          rows, headers, getHeaderProps, getRowProps, getTableProps,
        }) => (
          <TableContainer>
            <Table className="app-data-table" {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableHeader>Select as active</TableHeader>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                  <TableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    <TableCell style={{ width: 120 }}>
                      <Tooltip position="right" content="Select as active">
                        <IconButton onClick={onActiveScenario(row.id)}>
                          {activeScenario?.id === parseInt(row.id, 10) ? (
                            <StarFilled16 />
                          ) : (
                            <Star16 />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {row.cells.map((cell) => {
                      if (cell.info.header === 'csv_upload_id' && files) {
                        const file = files.find((f) => f.id === cell.value)
                        return <TableCell key={cell.id}>{file?.name}</TableCell>
                      }

                      if (editing === row.id && cell.info.header === 'name') {
                        return (
                          <TableCell key={cell.id}>
                            <TextInput
                              labelText=""
                              id="plan-name"
                              onKeyDown={onKeypressEditPlanName}
                              onChange={onEditPlanName}
                              value={planName}
                            />
                          </TableCell>
                        )
                      }

                      return <TableCell key={cell.id}>{cell.value}</TableCell>
                    })}
                    <TableCell
                      style={{ textAlign: 'right', position: 'relative' }}
                    >
                      {editing === row.id && (
                      <Tooltip content="Save" position="top">
                        <PrimaryIconButton onClick={handleSave}>
                          <Save16 />
                        </PrimaryIconButton>
                      </Tooltip>
                      )}

                      {editing !== row.id && (
                      <Tooltip content="Edit plan" position="top">
                        <IconButton onClick={handleEdit(row.id)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      )}
                      <DeleteScenario id={row.id} onDelete={onDelete(row.id)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <Pagination
        page={page}
        totalItems={rows?.length || 0}
        pageSizes={PAGE_SIZES}
        pageSize={pageSize}
        onChange={onPaginationChange}
      />
    </div>
  )
}

function DeleteScenario({ onDelete }) {
  const [confirm, setConfirm] = useState(false)

  const handleDelete = () => {
    setConfirm(true)
  }

  const onConfirmDelete = () => {
    onDelete()
    setConfirm(false)
  }

  const onCancelConfirm = () => {
    setConfirm(false)
  }

  return (
    <>
      {!confirm && (
        <Tooltip content="Delete plan" position="left">
          <IconButton type="button" onClick={handleDelete}>
            <Delete />
          </IconButton>
        </Tooltip>
      )}
      {confirm && (
        <div className="app-inline-prompt">
          <Button
            size="small"
            kind="danger"
            onClick={onConfirmDelete}
            style={{ marginLeft: 'auto' }}
          >
            Confirm?
          </Button>
          <Button size="small" kind="secondary" onClick={onCancelConfirm}>
            Cancel
          </Button>
        </div>
      )}
    </>
  )
}

function LockedTag() {
  return (
    <Tag title="Locked" type="gray">
      <Locked16 />
      &nbsp;Locked
    </Tag>
  )
}

const TAG_TYPES_PER_STATUS = {
  COMPLETED: 'green',
  FAILED: 'red',
  RUNNING: 'cyan',
}

function StatusTag({ status }) {
  if (!status) return null

  function renderIcon(status) {
    switch (status) {
      case 'COMPLETED':
        return <CheckmarkOutline16 />
      case 'FAILED':
        return <MisuseOutline16 />
      case 'RUNNING':
        return <InProgress16 />
      default:
        return null
    }
  }

  return (
    <Tag title="Engine status" type={TAG_TYPES_PER_STATUS[status]}>
      {renderIcon(status)}
      &nbsp;{Case.title(status)}
    </Tag>
  )
}
