export const mapScore = (score) => {
  const scoreMap = ['zero', 'low', 'mid', 'high']

  return scoreMap[score - 1]
}

export const mapScoreReverse = (score) => {
  const scoreMap = {
    zero: 1,
    low: 2,
    mid: 3,
    high: 4,
  }

  return scoreMap[score]
}
