import { ProgressIndicator as ProgressIndicatorCarbon } from 'carbon-components-react'
import React from 'react'
import { ProgressStep } from './ProgressStep'

export interface ProgressStep {
  path: string
  label: string
  disabled?: boolean
}

export interface ProgressIndicatorProps {
  steps: ProgressStep[]
}

export const ProgressIndicator = ({ steps }: ProgressIndicatorProps) => {
  return (
    <ProgressIndicatorCarbon>
      {steps.map((step) => {
        return (
          <ProgressStep
            href={step.path}
            label={step.label}
            key={step.label}
            disabled={step.disabled}
          />
        )
      })}
    </ProgressIndicatorCarbon>
  )
}
