export const TOTAL_OPERATIONS = 'Total operations available'
export const REQUIRED_WORKCENTERS = 'Non-selected work centres'
export const STATUS = 'Operation status not planned'
export const IKKE = 'Ikke status'
export const PRIORITY = 'Non-selected priority'
export const S_OPERATIONS = 'S-operations but no stop at location'
export const NON_STOP_OPERATIONS_WITH_REVISION_CODES = 'Non stop operations with revision code'
export const ZERO_DURATION = 'Zero duration'
export const ZERO_STAFF = 'Zero staff required'
export const TIME_WINDOW = 'Invalid time window'
export const NOT_ALLOWED_IN_WEEK = 'Not allowed in week '
export const ALREADY_PLANNED = 'Executed in previous week'
export const BEYOND = 'Beyond '
export const RETAINED_OPERATIONS = 'Retained operations'

export const isDroppedLabel = (label) => {
  return [REQUIRED_WORKCENTERS, PRIORITY, S_OPERATIONS, IKKE].some(
    (x) => label.search(x) >= 0
  )
}
