import { createSelector } from 'reselect'

// Data
export const selectScenariosDataById = (state) => state.scenarios.data.byId
export const selectScenariosDataAllIds = (state) => state.scenarios.data.allIds

// UI
export const selectActiveScenario = (state) => state.scenarios.ui.activeScenario
export const selectScenariosIsGetting = (state) => state.scenarios.ui.isGetting

export const selectLatestScenario = createSelector(
  selectScenariosDataAllIds,
  selectScenariosDataById,
  (ids, scenarios) => {
    let lastId = [...ids].sort((a, b) => b - a)
    lastId = lastId[0]
    return lastId >= 0 ? scenarios[lastId] : null
  },
)
