import { all } from 'redux-saga/effects'
import datasource from '../features/datasource/redux/sagas'
import scenarios from '../features/scenarios/redux/sagas'
import preprocessing from '../features/preprocessing/redux/sagas'
import engine from '../features/engine/redux/sagas'
import engineJobs from '../features/engineJobs/redux/sagas'
import results from '../features/results/redux/sagas'

export default function* rootSaga() {
  yield all([
    datasource(),
    scenarios(),
    preprocessing(),
    engine(),
    engineJobs(),
    results(),
  ])
}
