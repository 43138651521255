import { combineReducers } from 'redux'
import {
  SET_DATA_STATE,
  SET_UPLOAD_STATE,
  SET_SELECTED_FILE,
  SET_MAPPED_DATA,
  UPLOAD_DATA,
  UPLOAD_DATA_FAIL,
  UPLOAD_DATA_SUCCESS,
  GET_DATA,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  GET_FILES,
  GET_FILES_FAIL,
  GET_FILES_SUCCESS,
  DELETE_FILE,
  DELETE_FILE_FAIL,
  DELETE_FILE_SUCCESS,
  SET_UPLOAD_MODAL_OPEN,
} from './actions'

const uploadState = {
  isUploading: false,
  error: null,
  success: false,
  modalOpen: false,
  files: [],
}
const dataState = {
  isFetching: false,
  error: null,
  success: false,
  data: null,
  selectedFile: '',
}
const mappedDataState = {
  workcenters: [],
  operationStatuses: [],
  approvals: [],
  priorities: [],
}

const upload = (state = uploadState, { type, payload }) => {
  switch (type) {
    case UPLOAD_DATA:
      return {
        ...state,
        isUploading: true,
        error: null,
        success: false,
      }

    case UPLOAD_DATA_SUCCESS:
      return {
        ...state,
        isUploading: false,
        success: true,
      }

    case UPLOAD_DATA_FAIL:
      return {
        ...state,
        isUploading: false,
        error: payload,
        success: false,
      }

    case GET_FILES:
      return {
        ...state,
      }

    case GET_FILES_SUCCESS:
      return {
        ...state,
        files:
          payload && Array.isArray(payload)
            ? [...payload].sort((a, b) => (a.created - b.created > 1 ? 1 : -1))
            : state.files,
      }

    case GET_FILES_FAIL:
      return {
        ...state,
        files: [],
      }

    case DELETE_FILE:
      return state

    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        files: [...state.files].filter((x) => x.id !== payload),
      }

    case DELETE_FILE_FAIL:
      return {
        ...state,
      }

    case SET_UPLOAD_MODAL_OPEN:
      return {
        ...state,
        modalOpen: payload,
      }

    case SET_UPLOAD_STATE:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}

const data = (state = dataState, { type, payload }) => {
  switch (type) {
    case GET_DATA:
      return {
        ...state,
        isFetching: true,
        error: null,
        success: false,
      }
    case GET_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: true,
        data: payload,
      }
    case GET_DATA_FAIL:
      return {
        ...state,
        isFetching: false,
        error: payload,
      }

    case SET_SELECTED_FILE:
      return {
        ...state,
        selectedFile: payload,
      }

    case SET_DATA_STATE:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}

const mappedData = (state = mappedDataState, { type, payload }) => {
  switch (type) {
    case SET_MAPPED_DATA:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

export default combineReducers({
  upload,
  data,
  mappedData,
})
