import React from 'react'
import { Grid, Row, Column } from 'carbon-components-react'
import { Page } from '../../components/Page'
import Table from './Table'

export default function Container() {
  return (
    <Page>
      <Grid fullWidth>
        <Row>
          <Column>
            <h4 className="mb-2">Data source</h4>
            <Table />
          </Column>
        </Row>
      </Grid>
    </Page>
  )
}
