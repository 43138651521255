import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Page } from '../../components/Page'
import { selectResultsData } from './redux/selectors'
import { getResults } from './redux/actions'

import Main from './Main'
import Toolbar from './Toolbar'
import { selectActiveScenario } from '../scenarios/redux/selectors'

export default function Solution() {
  const dispatch = useDispatch()
  const [scoredOperations] = useState([])

  const results = useSelector(selectResultsData)
  const activeScenario = useSelector(selectActiveScenario)

  const noResults = useMemo(() => {
    return !!(
      !results ||
      _.isEmpty(results.metrics) ||
      _.isEmpty(results.plan) ||
      _.isEmpty(results.workload)
    )
  }, [results])

  useEffect(() => {
    if (noResults && activeScenario) {
      dispatch(getResults())
    }
    // eslint-disable-next-line
  }, [noResults, activeScenario])

  const contentProps = {
    results,
    noResults,
    scoredOperations,
  }

  const toolbarProps = {
    results,
    noResults,
  }

  return (
    <Page
      ContentComponent={Main}
      ToolbarComponent={Toolbar}
      contentProps={contentProps}
      toolbarProps={toolbarProps}
    />
  )
}
