export const SET_INPUT = '[planning] SET INPUT'
export const RESET_INPUT = '[planning] RESET'
export const SET_SCORE = '[planning] set-score'
export const SET_RESOURCES = '[planning] set-resources'
export const SET_RESOURCES_LIST = '[planning] set-resources-list'
export const ADD_RESOURCES_PER_STOP = '[planning] ADD RESOURCES PER STOP'
export const SET_DAILY_RESOURCES = '[preprocessing] SET additional resources'

export const setInput = (payload) => ({
  type: SET_INPUT,
  payload,
})

export const resetInput = (payload) => ({
  type: RESET_INPUT,
  payload,
})

export const setScore = (payload) => ({
  type: SET_SCORE,
  payload,
})

export const setResources = (payload) => ({
  type: SET_RESOURCES,
  payload,
})

export const setResourcesList = (payload) => ({
  type: SET_RESOURCES_LIST,
  payload,
})

export const setDailyResources = (payload) => ({
  type: SET_DAILY_RESOURCES,
  payload,
})
