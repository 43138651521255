import { takeLatest, put } from 'redux-saga/effects'
import * as actions from './actions'
import api from './api'

export function* getEngineJobs() {
  try {
    const response = yield api.fetchEngineJobs()

    yield put(actions.getEngineJobsSuccess(response))
  } catch (err) {
    yield put(actions.getEngineJobsFail('Something went wrong'))
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_ENGINE_JOBS, getEngineJobs)
}
