import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { TextInput } from 'carbon-components-react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

export function CarbonizedSlider(props) {
  const {
    min,
    max,
    value,
    step,
    marks,
    disabled,
    id,
    className,
    labelText,
    onChange,
    hideTextInput,
    valueLabel,
    valueLabelFormat = (val) => val,
  } = props

  const [ownValue, setOwnValue] = useState()
  const classes = useStyles()

  useEffect(() => {
    if (value !== undefined) {
      setOwnValue(value)
    }
  }, [value])

  const onSliderChange = (e, value) => {
    setOwnValue(value)
  }

  const onSliderRelease = (e, newValue) => {
    onChange(newValue)
  }

  return (
    <div className={className}>
      {labelText && (
        <span
          className={clsx('bx--label', {
            'bx--label--disabled': disabled,
          })}
        >
          {labelText}
        </span>
      )}
      <div className="app--rangeslider">
        <Slider
          id={id}
          value={Number(ownValue) || 0}
          onChange={onSliderChange}
          onChangeCommitted={onSliderRelease}
          min={min}
          max={max}
          step={step}
          marks={marks}
          disabled={disabled}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          aria-labelledby="slider"
          classes={classes}
        />
        {!hideTextInput && (
          <TextInput
            value={valueLabel ? valueLabel(value) : value}
            id={`${id}-textinput`}
            labelText=""
            disabled={disabled}
          />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => createStyles({
  colorPrimary: {
    color: '#0f61fe',
  },
  root: {
    marginRight: '1rem',
  },
  rail: {
    backgroundColor: '#393939',
    opacity: 1,
  },
  track: {
    backgroundColor: '#ffffff',
  },
  thumb: {
    width: '0.875rem',
    height: '0.875rem',
    backgroundColor: '#f4f4f4',
  },
}))
