import { useSelector } from 'react-redux'
import {
  selectInputWorkcenters,
  selectOutputData,
  selectPlanningFreezeIds,
} from './selectors'
import { useMemo } from 'react'
import { selectDatasourceData } from '../../datasource/redux/selectors'

export interface Operation {
  id: number
  description: string
  func_location: string
  status: string
  priority: string
  responsible_entity: string
  work_order_id: string
  operation_id: number
  operations_data_row_id: number
  revision_code: string
}

export interface OperationRow {
  id: string
  operation: string
  description: string
  func_location: string
  status: string
  priority: number
  responsible_entity: string
  work_order_id: string
}

export interface CenterOperations {
  responsibleEntity: string
  operations: OperationRow[]
  frozenOperationCount: number
}

export const useFreezableOperations = () => {
  const data = useSelector(selectOutputData)
  const operations = useSelector(selectDatasourceData)
  const freezeIds = useSelector(selectPlanningFreezeIds)

  const freezableOperations = useMemo(() => {
    if (!data?.freeze_ids || !data.freeze_ids.length) {
      return []
    }

    return operations.filter((operation: any) =>
      data.freeze_ids.includes(operation.id),
    )
  }, [data, operations])

  const uniqueCenters = useMemo<CenterOperations[]>(() => {
    return freezableOperations
      .filter((freezableOperation: Operation, index: number) => {
        const indexOf = freezableOperations.findIndex(
          (indexOperation: Operation) => {
            return (
              indexOperation.responsible_entity ===
              freezableOperation.responsible_entity
            )
          },
        )

        return index === indexOf
      })
      .map((uniqueOperation: Operation) => {
        return {
          responsibleEntity: uniqueOperation.responsible_entity,
          frozenOperationCount: freezeIds.filter((freezeId: number) => {
            const operation = operations.find(
              (operation: Operation) => operation.id === freezeId,
            )

            return (
              operation?.responsible_entity ===
              uniqueOperation.responsible_entity
            )
          }).length,
          operations: freezableOperations
            .filter(
              (freezableOperation: Operation) =>
                freezableOperation.responsible_entity ===
                uniqueOperation.responsible_entity,
            )
            .map((operation: Operation) => {
              return {
                ...operation,
                id: `${operation.id}`,
                operation: `${operation.work_order_id}-${operation.operation_id}`,
              }
            }),
        }
      })
  }, [freezableOperations, freezeIds])

  return { freezableOperations, uniqueCenters }
}
