import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ResponsiveBar } from '@nivo/bar'
import { Person16 } from '@carbon/icons-react'
import { selectPlanningState } from './redux/selectors'
import { mapStaffToChart } from './redux/utils'
import { toFixed } from '../../utils/Math'

const ResourcesChart = () => {
  const planningState = useSelector(selectPlanningState)

  const staffChartData = useMemo(() => {
    if (planningState && planningState.resources) {
      return mapStaffToChart(planningState.resources)
    }

    return []
  }, [planningState])

  if (!planningState || !Object.keys(planningState.resources).length) {
    return null
  }

  const chartHeight = planningState?.resources && Object.keys(planningState.resources).length > 0
    ? Object.keys(planningState.resources).length * 35 + 80
    : '100%'

  return (
    <>
      <h4>Base crew resources</h4>
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ height: chartHeight, marginTop: '45px', width: '100%' }}>
          <ResponsiveBar
            data={staffChartData}
            keys={['Capacity']}
            indexBy="workcenter"
            layout="horizontal"
            margin={{
              top: 30,
              right: 10,
              bottom: 30,
              left: 80,
            }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#48dbc0']}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            minValue={0}
            maxValue={100}
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 42,
            }}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[]}
            animate
            motionStiffness={90}
            motionDamping={15}
            barComponent={CustomBarComponent}
            tooltip={CustomTooltip}
          />
        </div>
      </div>
    </>
  )
}

const CustomBarComponent = (props) => {
  const {
    x,
    y,
    width,
    height,
    color,
    data,
    tooltip: Tooltip,
    showTooltip,
    hideTooltip,
    onMouseEnter,
    onMouseLeave,
  } = props
  if (!data) {
    return null
  }

  const staff = parseFloat(data.data.Staff) || 0
  const capacity = parseInt(data.data.Capacity) || 0
  const staffArray = Array.from(Array(Math.round(staff)).keys())

  const handleTooltip = (e) => showTooltip(
    <Tooltip entity={data.indexValue} capacity={capacity} staff={staff} />,
    e,
  )
  const handleMouseEnter = (e) => {
    onMouseEnter(data, e)
    showTooltip(
      <Tooltip entity={data.indexValue} capacity={capacity} staff={staff} />,
      e,
    )
  }
  const handleMouseLeave = (e) => {
    onMouseLeave(data, e)
    hideTooltip(e)
  }

  return (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleTooltip}
      onMouseLeave={handleMouseLeave}
    >
      <rect
        width={width}
        height={height}
        rx="0"
        ry="0"
        fill={color}
        strokeWidth="0"
        stroke="rgb(41, 124, 109)"
      />
      {staffArray.map((n, i) => (
        <g transform={`translate(${i * 10}, 3), scale(0.6)`} key={n}>
          {/* Path is from the icon Person */}
          <path d="M18 30H14a2 2 0 01-2-2V21a2 2 0 01-2-2V13a3 3 0 013-3h6a3 3 0 013 3v6a2 2 0 01-2 2v7A2 2 0 0118 30zM13 12a.94.94 0 00-1 1v6h2v9h4V19h2V13a.94.94 0 00-1-1zM16 9a4 4 0 114-4h0A4 4 0 0116 9zm0-6a2 2 0 102 2h0a2 2 0 00-2-2z" />
        </g>
      ))}
    </g>
  )
}

function CustomTooltip({ entity, capacity, staff }) {
  return (
    <div className="chart-bar-tooltip custom-bar-tooltip">
      <h6>
        <span>{entity}</span>
      </h6>
      <ul className="tooltip-list">
        <li>
          Staff: <strong>{toFixed(staff)}</strong>x <Person16 />
        </li>
        <li>
          Capacity: <strong>{capacity}%</strong>
        </li>
      </ul>
    </div>
  )
}

export default ResourcesChart
