import { combineReducers } from 'redux'
import * as actions from './actions'

const uiInitialState = {
  isGetting: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  getError: null,
  createError: null,
  updateError: null,
  deleteError: null,
  activeScenario: null,
}

const dataInitialState = {
  byId: {},
  allIds: [],
}

const ui = (state = uiInitialState, { type, payload }) => {
  switch (type) {
    case actions.GET_SCENARIOS:
      return {
        ...state,
        isGetting: true,
        getError: null,
      }
    case actions.GET_SCENARIOS_SUCCESS:
      return {
        ...state,
        isGetting: false,
      }
    case actions.GET_SCENARIOS_FAIL:
      return {
        ...state,
        isGetting: false,
        getError: payload,
      }
    case actions.CREATE_SCENARIO:
    case actions.CLONE_SCENARIO:
      return {
        ...state,
        isCreating: true,
        createError: null,
      }
    case actions.CREATE_SCENARIO_SUCCESS:
    case actions.CLONE_SCENARIO_SUCCESS:
      return {
        ...state,
        isCreating: false,
      }
    case actions.CREATE_SCENARIO_FAIL:
    case actions.CLONE_SCENARIO_FAIL:
      return {
        ...state,
        isCreating: false,
        createError: payload,
      }

    case actions.UPDATE_SCENARIO:
      return {
        ...state,
        isUpdating: true,
        updateError: null,
      }
    case actions.UPDATE_SCENARIO_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      }
    case actions.UPDATE_SCENARIO_FAIL:
      return {
        ...state,
        isUpdating: false,
        updateError: payload,
      }
    case actions.DELETE_SCENARIO:
      return {
        ...state,
        isDeleting: true,
        deleteError: null,
      }
    case actions.DELETE_SCENARIO_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        activeScenario: null,
      }

    case actions.DELETE_SCENARIO_FAIL:
      return {
        ...state,
        isDeleting: false,
        deleteError: payload,
      }
    case actions.SET_ACTIVE_SCENARIO:
      return {
        ...state,
        activeScenario: payload,
      }
    case actions.SET_ACTIVE_SCENARIO_ENGINE_JOB:
      return {
        ...state,
        activeScenario: {
          ...state.activeScenario,
          engine_job_id: payload,
        },
      }
    default:
      return state
  }
}

const putScenarios = (state, payload) => {
  const byId = {}
  const allIds = []

  payload.forEach((scenario) => {
    byId[scenario.id] = scenario
    allIds.push(scenario.id)
  })

  return {
    ...state,
    byId,
    allIds: [...allIds].sort((a, b) => b - a),
  }
}

const putScenario = (state, payload) => {
  const byId = {
    ...state.byId,
    [payload.id]: payload,
  }
  const allIds = [...new Set([...state.allIds, payload.id])].sort(
    (a, b) => b - a,
  )

  return {
    ...state,
    byId,
    allIds,
  }
}

const deleteScenario = (state, payload) => {
  const removedId = parseInt(payload, 10)

  const byId = {
    ...state.byId,
  }

  const allIds = [...state.allIds].filter((id) => id !== removedId)

  delete byId[payload]

  return {
    ...state,
    byId,
    allIds,
    isDeleting: false,
  }
}

const data = (state = dataInitialState, { type, payload }) => {
  switch (type) {
    case actions.GET_SCENARIOS_SUCCESS:
      return putScenarios(state, payload)
    case actions.CREATE_SCENARIO_SUCCESS:
    case actions.CLONE_SCENARIO_SUCCESS:
      return putScenario(state, payload)
    case actions.UPDATE_SCENARIO_SUCCESS:
      return putScenario(state, payload)
    case actions.DELETE_SCENARIO_SUCCESS:
      return deleteScenario(state, payload)
    default:
      return state
  }
}

export default combineReducers({ ui, data })
