import { takeLatest, put, select } from 'redux-saga/effects'
import { selectActiveScenario } from '../../scenarios/redux/selectors'
import * as actions from './actions'
import api from './api'

function* getFilteredData({ payload }) {
  try {
    const activeScenario = yield select(selectActiveScenario)
    const response = yield api.getFilteredData(
      payload,
      activeScenario.csv_upload_id,
    )
    yield put(actions.getFilteredDataSuccess(response))
  } catch (err) {
    //console.log(err)
    yield put(actions.getFilteredDataFail())
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_FILTERED_DATA, getFilteredData)
}
