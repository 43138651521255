import { takeLatest, put, select } from 'redux-saga/effects'
import { useSelector } from 'react-redux'
import * as actions from './actions'
import api from './api'
import { mapScore } from './utils'
import { selectPrioritizeShortJobs } from './selectors'
import { mapStops } from '../../preprocessing/redux/utils'
import {
  selectPlanningFreezeIds,
  selectPreprocessingInputState,
  selectRevisionCodes,
  selectRetainOperations
} from '../../preprocessing/redux/selectors'
import { selectPlanningState } from '../../planning/redux/selectors'
import { setActiveScenarioEngineJob } from '../../scenarios/redux/actions'
import { selectActiveScenario } from '../../scenarios/redux/selectors'

function* startEngine({ payload: debugParams = {} }) {
  try {
    const {
      desiredWeek: desired_week,
      desiredYear: desired_year,
      backlogWeeks: backlog_weeks,
      scores,
      resources,
      dailyResources,
    } = yield select(selectPlanningState)
    const {
      workcenters, operationStatus, stops, priority, ikke,
    } = yield select(selectPreprocessingInputState)
    const activeScenario = yield select(selectActiveScenario)
    const prioritizeShortJobs = yield select(selectPrioritizeShortJobs)
    const freezeIds = yield select(selectPlanningFreezeIds)
    const revisionCodes = yield select(selectRevisionCodes)
    const retainOperations = yield select(selectRetainOperations)

    const {
      distantBacklog, backlog, desiredWeek, future,
    } = scores
    const filter_parameters = {
      workcenters,
      operation_statuses: Object.keys(operationStatus)
        .filter((key) => operationStatus[key])
        .map((key) => key.toLowerCase()),
      stop_operations: priority.S ? mapStops(stops) : [],
      priorities: Object.keys(priority)
        .filter((key) => priority[key])
        .map((key) => key),
      revision_codes: revisionCodes,
      retain_operations: retainOperations,
      ikke,
    }

    const body = {
      desired_week,
      desired_year,
      backlog_weeks,
      freeze_ids: freezeIds,
      scores: {
        distant_backlog: mapScore(distantBacklog),
        desired_week: mapScore(desiredWeek),
        backlog: mapScore(backlog),
        future: mapScore(future),
      },
      resources,
      filter_parameters,
      daily_resources: dailyResources,
      plan_id: activeScenario.id,
      prioritize_short_jobs: prioritizeShortJobs,
      ...debugParams,
    }

    const response = yield api.startEngine(body)

    yield put(actions.startEngineSuccess(response?.engine_job_id))
    yield put(setActiveScenarioEngineJob(response?.engine_job_id))
  } catch (err) {
    let error = err.message || err.response?.data.detail[0].msg
    if (err.response.status === 500) {
      error = 'Internal server error. (500)'
    }
    yield put(actions.startEngineFail(error))
  }
}

export function* checkEngine({ payload: jobId }) {
  try {
    const response = yield api.checkEngine(jobId)

    yield put(actions.checkEngineSuccess(response))
  } catch (err) {
    yield put(actions.checkEngineFail(err.response.data.detail[0].msg))
  }
}

export function* checkEngineStatus({ payload: jobId }) {
  try {
    const response = yield api.checkEngineStatus(jobId)

    yield put(actions.checkEngineStatusSuccess(response))
  } catch (err) {
    yield put(actions.checkEngineStatusFail(err.response.data.detail[0].msg))
  }
}

export default function* sagas() {
  yield takeLatest(actions.START_ENGINE, startEngine)
  yield takeLatest(actions.CHECK_ENGINE, checkEngine)
  yield takeLatest(actions.CHECK_ENGINE_STATUS, checkEngineStatus)
}
