import { useSelector } from 'react-redux'
import { selectScenariosDataById } from './selectors'
import { useMemo } from 'react'

export const useCanDelete = () => {
  const scenarioData = useSelector(selectScenariosDataById)

  const scenarios = useMemo(()=> {
    const keys = Object.keys(scenarioData);

    return keys.map((key) => scenarioData[key])
  }, [scenarioData])

  const canDelete = (csvId: number) => {
    return !scenarios.find((scenario) => scenario.csv_upload_id === csvId)
  }

  return  {
    canDelete
  }
}
