import { client } from '../../../api/client'

export async function getScenarios() {
  const response = await client({
    method: 'GET',
    url: '/plan',
  })

  return response.data
}

export async function createScenario(body) {
  const response = await client({
    method: 'POST',
    url: '/plan',
    data: body,
  })

  return response.data
}

export async function cloneScenario({ name, planId }) {
  const response = await client({
    method: 'POST',
    url: `/plan/${planId}/clone`,
    data: {
      name,
    },
  })

  return response.data
}

export async function updateScenario({ id, body }) {
  const response = await client({
    method: 'PATCH',
    url: `/plan/${id}`,
    data: body,
  })

  return response.data
}

export async function deleteScenario(planId) {
  const response = await client({
    method: 'DELETE',
    url: `/plan/${planId}`,
  })

  return response.data
}

export async function getScenarioFilterParams(planId) {
  const response = await client({
    method: 'GET',
    url: `/plan/${planId}/filter_parameters`,
  })

  return response.data
}

export async function getScenarioPlanningParams(inputParameterSetId) {
  const response = await client({
    method: 'GET',
    url: `/input_parameter_set/${inputParameterSetId}`,
  })

  return response.data
}

export async function getScenarioStopsParams(planId) {
  const response = await client({
    method: 'GET',
    url: `/plan/${planId}/stops`,
  })

  return response.data
}
