export const GET_RESULTS = '[results] get-results'
export const GET_RESULTS_SUCCESS = '[results] get-results :success'
export const GET_RESULTS_FAIL = '[results] get-results :fail'
export const SET_RESULTS = '[results] SET'
export const GET_CURRENT_WEEK_RESULTS_SUCCESS = '[results] SET current week results'

export const getResults = (payload) => ({
  type: GET_RESULTS,
  payload,
})

export const getResultsSuccess = (payload) => ({
  type: GET_RESULTS_SUCCESS,
  payload,
})

export const getResultsFail = (payload) => ({
  type: GET_RESULTS_FAIL,
  payload,
})

export const setResults = (payload) => ({
  type: SET_RESULTS,
  payload,
})

export const getCurrentWeekResultsSuccess = (payload) => ({
  type: GET_CURRENT_WEEK_RESULTS_SUCCESS,
  payload,
})
