import React, { useMemo } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { mapDataToOperationsPerTimeSegment } from './utils'

const coolors = ['#ddd', '#06d6a0', '#118ab2', '#073b4c', '#ef476f']

const OperationsPerPriority = ({ data }) => {
  const chartData = useMemo(() => {
    if (!data.length) return []

    return mapDataToOperationsPerTimeSegment(data)
  }, [data])

  return (
    <ResponsiveBar
      data={chartData}
      keys={['Executed operations', 'Input operations']}
      indexBy="Priority type operations"
      margin={{ top: 30, right: 10, bottom: 100, left: 80 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={[coolors[2], coolors[0]]}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Number of operations',
        legendPosition: 'middle',
        legendOffset: -60,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 70,
          itemsSpacing: 4,
          itemWidth: 150,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 20,
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={CustomTooltip}
    />
  )
}

function CustomTooltip({ indexValue, data }) {
  const inputOperationsCount = data['Input operations'] || 0
  const executedOperationsCount = data['Executed operations'] || 0

  return (
    <div className="chart-bar-tooltip">
      <h6>
        <span>{indexValue}</span>
      </h6>
      <span>
        Input operations:{' '}
        {inputOperationsCount + executedOperationsCount}
      </span>
      <span>Executed operations: {executedOperationsCount}</span>
    </div>
  )
}

export default OperationsPerPriority
