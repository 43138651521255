import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ResponsiveBar } from '@nivo/bar'
import { Grid, Row, Column, Button } from 'carbon-components-react'
import { Reset16 } from '@carbon/icons-react'
import { FilterTable } from '../../components/FilterTable'
import { coolors } from '../../settings'
import {
  selectInputSelectedBar,
  selectInputWorkcenters,
  selectOutputData,
} from './redux/selectors'
import {
  selectDatasourceData,
  selectDatasourceWorkcenters,
} from '../datasource/redux/selectors'
import { getHeadersConfig } from './Breakdown.config'
import * as labels from './labels'

export default function Breakdown() {
  const [serieName, setSerieName] = useState('')
  const [selectedEntity, setSelectedEntity] = useState('')

  // Selectors
  const workCenters = useSelector(selectDatasourceWorkcenters)
  const selectedWorkcenters = useSelector(selectInputWorkcenters)
  const selectedBar = useSelector(selectInputSelectedBar)
  const datasource = useSelector(selectDatasourceData)
  const data = useSelector(selectOutputData)

  // Effects
  useEffect(() => {
    if (!data || !selectedBar) {
      return
    }
    setSerieName(selectedBar)
  }, [data, selectedBar])

  useEffect(() => {
    setSelectedEntity()
  }, [selectedBar])

  // Memos
  const operationsData = useMemo(() => {
    if (!data || !selectedBar || !datasource || !datasource.length) return []

    switch (selectedBar) {
      case labels.TOTAL_OPERATIONS:
        return datasource
      case labels.RETAINED_OPERATIONS:
        return datasource.filter((x) => data.retained_data_ids.includes(x.id))

      default:
        const selectedData = data.filtered_data.find(
          (x) => x.name === selectedBar
        )
        const sourceIndex = data.filtered_data.findIndex(
          (x) => x.name === selectedBar
        )

        if (sourceIndex < 0) {
          return []
        }

        const source = data.filtered_data[sourceIndex - 1]

        return datasource
          .filter((x) => source.id.includes(x.id))
          .filter((x) => !selectedData.id.includes(x.id))
    }
  }, [datasource, data, selectedBar])

  const headers = getHeadersConfig(selectedBar)
  const rows = useMemo(() => {
    return operationsData
      .filter((x) =>
        selectedEntity ? x.responsible_entity === selectedEntity : true
      )
      .map((x) => ({
        ...x,
        operation: `${x.work_order_id}-${x.operation_id}`,
        id: `${x.operation_id}${x.work_order_id}`,
      }))
  }, [operationsData, selectedEntity])

  const centers = useMemo(() => {
    return [labels.TOTAL_OPERATIONS, labels.REQUIRED_WORKCENTERS].includes(
      serieName
    )
      ? workCenters
      : selectedWorkcenters
  }, [workCenters, selectedWorkcenters, serieName])

  const chartData = useMemo(() => {
    const data = []
    centers.forEach((center) => {
      const count = operationsData.filter(
        (op) => op.responsible_entity === center
      ).length
      data.push({
        label: center,
        value: count,
      })
    })

    return data.reverse()
  }, [operationsData, centers])

  // Methods
  const handleBarClick = ({ indexValue }) => {
    setSelectedEntity(indexValue)
  }

  const resetEntity = () => {
    setSelectedEntity()
  }

  if (!rows || !rows.length) {
    return null
  }

  let barColor = coolors[5]
  let preLabel = 'dropped '

  if (
    [labels.TOTAL_OPERATIONS, labels.RETAINED_OPERATIONS].includes(serieName)
  ) {
    barColor = coolors[3]
    preLabel = ''
  }
  if (labels.isDroppedLabel(serieName)) {
    barColor = coolors[1]
  }

  const maxChartValue =
    chartData && chartData.length
      ? Math.max(...chartData.map((x) => x.value))
      : 10
  const nrOfTickValues = maxChartValue > 10 ? 10 : maxChartValue

  return (
    <Grid fullWidth className="mt-2">
      <Row>
        <Column md={5}>
          <div
            className="mb-1"
            style={{ display: 'flex', alignItems: 'center', height: 40 }}
          >
            <h4>
              {selectedBar} ({rows.length})
              {selectedEntity && ` - ${selectedEntity}`}
            </h4>
            {selectedEntity && (
              <Button
                hasIconOnly
                renderIcon={Reset16}
                iconDescription="Reset filter"
                size="small"
                kind="ghost"
                onClick={resetEntity}
              />
            )}
          </div>

          <FilterTable rows={rows} headers={headers} />
        </Column>

        <Column md={3}>
          <div
            style={{
              width: '100%',
              height: 150 + 30 * centers.length,
            }}
          >
            <ResponsiveBar
              data={chartData}
              onClick={handleBarClick}
              keys={['value']}
              indexBy="label"
              layout="horizontal"
              margin={{ top: 55, right: 10, bottom: 50, left: 100 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={barColor}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={{
                tickValues: nrOfTickValues,
                legend: `Nr. of ${preLabel}operations per work center`,
                legendPosition: 'middle',
                legendOffset: -40,
              }}
              axisRight={null}
              axisBottom={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              tooltip={CustomTooltip}
            />
          </div>
        </Column>
      </Row>
    </Grid>
  )
}

function CustomTooltip({ indexValue, value }) {
  return (
    <div className="chart-bar-tooltip">
      <span>
        {indexValue}: <strong>{value}</strong>
      </span>
    </div>
  )
}
