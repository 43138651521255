import React, { useState } from 'react'
import { Debug20 } from '@carbon/icons-react'
import FeedbackDialog from './FeedbackDialog'
import { ProjectHeaderAction } from '../../components/project-header-action'

const FeedbackDialogButton = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <ProjectHeaderAction
        tooltipText="Send feedback"
        onClick={() => setOpen(true)}
      >
        <Debug20 />
      </ProjectHeaderAction>

      {open && <FeedbackDialog onClose={() => setOpen(false)} />}
    </>
  )
}

export default FeedbackDialogButton
