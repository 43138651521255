import { IconButton } from '@whitespace/ui'
import styled from '@emotion/styled'

export const PrimaryIconButton = styled(IconButton)`
  background-color: #0f62fe;
  width: 2rem;
  height: 2rem;
  color: white;

  &:hover {
    color: white;
  }
`
