import React, { useState, useEffect } from 'react'
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Pagination,
} from 'carbon-components-react'

export function FilterTable({ rows, headers }) {
  // Hooks
  const [paginatedRows, setPaginatedRows] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const PAGE_SIZES = [10, 20, 50, 100, 200]

  const paginate = (initialRows, page, pageSize) => {
    const rows = [...initialRows]
    return rows.splice((page - 1) * pageSize, pageSize)
  }

  // Effects
  useEffect(() => {
    if (rows?.length) {
      const paginatedRows = paginate(rows, page, pageSize)
      setPaginatedRows(paginatedRows)

      if (rows.length <= (page - 1) * pageSize) {
        setPage(1)
      }
    }
  }, [rows, page, pageSize])

  // Methods
  const onPaginationChange = (pagination) => {
    setPage(pagination.page)
    setPageSize(pagination.pageSize)
  }

  return (
    <div>
      <DataTable rows={paginatedRows} headers={headers} isSortable>
        {({ rows, headers, getHeaderProps, getRowProps, getTableProps }) => (
          <TableContainer>
            <Table className="app-data-table" {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      style={{ width: header.width }}
                      {...getHeaderProps({ header })}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow key={row.id} {...getRowProps({ row })}>
                      {row.cells.map((cell) => {
                        const header = headers.find(
                          (h) => h.key === cell.info.header,
                        )
                        const style = header?.width
                          ? { width: header.width }
                          : {}

                        if (header?.nowrap) {
                          style.whiteSpace = 'nowrap'
                        }

                        let { value } = cell

                        if (header?.type === 'boolean') {
                          value = cell.value ? 'Yes' : 'No'
                        }

                        return (
                          <TableCell key={cell.id} style={style}>
                            {header && header.format
                              ? header.format(cell.value)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <Pagination
        page={page}
        totalItems={rows?.length || 0}
        pageSizes={PAGE_SIZES}
        pageSize={pageSize}
        onChange={onPaginationChange}
      />
    </div>
  )
}
