import React from 'react'
import ReactDOM from 'react-dom'
import { ComposedModal, ModalBody, ModalHeader } from 'carbon-components-react'
import Stops from './Stops'

const StopsModal = ({ open, onClose }) => {
  // Render
  return ReactDOM.createPortal(
    <ComposedModal
      preventCloseOnClickOutside
      open={open}
      className="app-modal"
      onClose={onClose}
    >
      <ModalHeader title="Edit stops" />
      <ModalBody>
        <Stops onClose={onClose} />
      </ModalBody>
    </ComposedModal>,
    document.body
  )
}

export default StopsModal
