import { takeLatest, select, put } from 'redux-saga/effects'
import { selectActiveScenario } from '../../scenarios/redux/selectors'
import * as actions from './actions'
import api from './api'

function* getResults() {
  try {
    const activeScenario = yield select(selectActiveScenario)

    if (activeScenario && activeScenario.engine_job_id) {
      const response = yield api.getResults(activeScenario.engine_job_id)

      yield put(actions.getResultsSuccess(response.results))

      const currentWeekResponse = yield api.getCurrentWeekResults(activeScenario.engine_job_id)

      yield put(actions.getCurrentWeekResultsSuccess(currentWeekResponse))
    }
  } catch (err) {
    //console.log(err)
    yield put(actions.getResultsFail(err.response.data))
  }
}

export default function* sagas() {
  yield takeLatest(actions.GET_RESULTS, getResults)
}
