import React, { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import {
  Button,
  ComposedModal,
  ModalBody,
  ModalHeader,
  TextArea,
  TextInput,
} from 'carbon-components-react'
import { CheckmarkFilled32 } from '@carbon/icons-react'

const feedbackUrl =
  'https://hooks.slack.com/services/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'

const submittedMessages = [
  'Thank you! Time for a very well deserved ☕.',
  'Thank you. You’re a hero 💪! ',
  'Thank you! Your feedback will be sent over by the first available postal 🐦.',
  'Thank you 🎉! Everytime we receive feedback we do a little 🕺.',
  'Thank you. You 🤘.',
  'Every piece of feedback is a gift 🎁 - thank you.',
  'Thank you, you awesome human 😎.',
  'Good feedback creates good karma 🧘‍♀️. Thank you.',
  'High fives 🖐! Thanks for sharing your feedback.',
  'Thanks for sharing! Our army of busy 🐝s salutes you.',
]

const FeedbackDialog = ({ onClose }) => {
  const [name, setName] = useState(
    () => localStorage.getItem('feedback_name') || ''
  )
  const [email, setEmail] = useState(
    () => localStorage.getItem('feedback_email') || ''
  )
  const [feedback, setFeedback] = useState('')

  useEffect(() => {
    localStorage.setItem('feedback_name', name)
  }, [name])

  useEffect(() => {
    localStorage.setItem('feedback_email', email)
  }, [email])

  const [submitted, setSubmitted] = useState(false)

  const formValid = useMemo(
    () => name.length > 0 && feedback.length > 0 && email.length > 0,
    [name, feedback, email]
  )

  const onSubmit = async () => {
    if (formValid) {
      const feedbackMessage = `---------\n*Name*: ${name} \n*Email*: ${email} \n*URL*: ${window.location.href} \n*Message*: ${feedback} \n`

      try {
        await axios.post(feedbackUrl, JSON.stringify({ text: feedbackMessage }))
        setSubmitted(true)
      } catch (e) {
        console.warn(e)
      }
    }
  }

  return ReactDOM.createPortal(
    <ComposedModal className="dark-theme app-modal" open onClose={onClose}>
      <ModalHeader
        title={
          submitted ? '' : 'Suggestions? Please share - your feedback matters.'
        }
      />
      <ModalBody>
        {submitted && (
          <div className="submitted-wrapper">
            <div className="submitted-icon">
              <CheckmarkFilled32 />
            </div>
            <div className="submitted-message">
              {
                submittedMessages[
                  Math.floor(Math.random() * submittedMessages.length)
                ]
              }
            </div>
          </div>
        )}
        {!submitted && (
          <>
            <fieldset className="bx--fieldset">
              <TextInput
                id="feedback__name"
                labelText="Your name"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={100}
              />
              <div className="spacer" />
              <TextInput
                id="feedback__email"
                labelText="Your email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                maxLength={100}
              />
            </fieldset>
            <fieldset className="bx--fieldset">
              <TextArea
                id="feedback__message"
                labelText="Feedback"
                placeholder="Enter your message"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                maxLength={250}
              />
            </fieldset>
            <Button
              // className="button-submit"
              disabled={!formValid}
              onClick={onSubmit}
            >
              Submit feedback
            </Button>
          </>
        )}
      </ModalBody>
    </ComposedModal>,
    document.body
  )
}

export default FeedbackDialog
