import React, { useMemo } from 'react'
import { FilterTable } from '../../../components/FilterTable'
import { Operation } from '../../preprocessing/redux/useFreezableOperations'
import { useSelector } from 'react-redux'
import { selectResultsCurrentWeekData } from '../redux/selectors'
import { selectOutputData } from '../../preprocessing/redux/selectors'
import { selectDatasourceData } from '../../datasource/redux/selectors'

export const CurrentWeekOperationsOverview = (props: any) => {
  const results = useSelector(selectResultsCurrentWeekData)
  const operations = useSelector(selectDatasourceData)
  const data = useSelector(selectOutputData)
  const freezeIds = data?.freeze_ids
  const retained = data?.retained_data_ids

  const rows = useMemo(() => {
    if (!results || !operations || !retained) {
      return []
    }

    const plannedData = results.planned_data.map(
      (plannedDataItem: any) => plannedDataItem.operations_data_row_id,
    )

    // const filteredOutIds = results.filtered_data
    //   .slice(1)
    //   .reduce((acc: number[], item: any) => {
    //     return [...acc, ...item.id]
    //   }, [])

    const unplannedData = results.unplanned_data

    return operations
      .filter((operation: Operation) => {
        return (
          retained.includes(operation.id) &&
          unplannedData.includes(operation.id)
        )
      })
      .map((operation: Operation) => {
        const frozen = freezeIds.includes(operation.id)
        const planned = plannedData.includes(operation.id)
        const filteredOut = freezeIds.includes(operation.id)

        const unplanned = unplannedData.includes(operation.id)
        const currentWeek = planned || filteredOut || unplanned

        // let status = ''
        //
        // if (planned) {
        //   status = 'Planned'
        // } else if (unplanned) {
        //   status = 'Unplanned'
        // } else if (filteredOut) {
        //   status = 'Filtered out'
        // }

        return {
          ...operation,
          id: `${operation.id}`,
          operation: `${operation.work_order_id}-${operation.operation_id}`,
          currentWeekOrFrozen: currentWeek || frozen,
          //status,
        }
      })
  }, [results, operations, freezeIds, retained])

  const headers = [
    {
      key: 'operation',
      header: 'Operation',
    },
    {
      key: 'description',
      header: 'Description',
    },
    {
      key: 'responsible_entity',
      header: 'Work center',
    },
    {
      key: 'priority',
      header: 'Priority',
    },
    {
      key: 'func_loc',
      header: 'Functional location',
    },
    // {
    //   key: 'currentWeekOrFrozen',
    //   header: 'Current week or frozen',
    //   type: 'boolean',
    // },
    // {
    //   key: 'status',
    //   header: 'Status',
    // },
  ]

  return (
    <div className="mt-2">
      <FilterTable rows={rows} headers={headers} />
    </div>
  )
}
