export const SET_INPUT = '[preprocessing] set-input'
export const SET_INPUT_STATE = '[preprocessing] set-input-state'
export const CLEAR_INPUT_STATE = '[preprocessing] clear-input-state'
export const SET_INPUT_WORKCENTERS = '[preprocessing] set-input-workcenters'
export const SET_INPUT_REVISION_CODES = '[preprocessing] set-input-revision-codes'
export const SET_INPUT_IKKE = '[preprocessing] set-ikke'
export const GET_FILTERED_DATA = '[preprocessing] get-data'
export const GET_FILTERED_DATA_SUCCESS = '[preprocessing] get-data :success'
export const GET_FILTERED_DATA_FAIL = '[preprocessing] get-data :fail'
export const SET_STOPS = '[preprocessing] SET stops'
export const SET_RETAIN_OPERATIONS = '[preprocessing] SET retain operations'

export const ADD_FREEZE_ID = '[planning] add freeze-id'
export const ADD_FREEZE_IDS = '[planning] add freeze-ids'
export const REMOVE_FREEZE_ID = '[planning] remove freeze-id'
export const REMOVE_FREEZE_IDS = '[planning] remove freeze-ids'

export const CLEAR_FREEZE_IDS = '[planning] clear freeze-ids'
export const SET_FREEZE_IDS = '[planning] set freeze-ids'

export const setInput = (payload) => ({
  type: SET_INPUT,
  payload,
})

export const setInputState = (payload) => ({
  type: SET_INPUT_STATE,
  payload,
})

export const setRevisionCodes = (payload) => ({
  type: SET_INPUT_REVISION_CODES,
  payload,
})

export const clearInputState = () => ({
  type: CLEAR_INPUT_STATE,
})

export const setInputWorkcenters = (payload) => ({
  type: SET_INPUT_WORKCENTERS,
  payload,
})

export const setInputIkke = (payload) => ({
  type: SET_INPUT_IKKE,
  payload,
})

export const getFilteredData = (payload) => ({
  type: GET_FILTERED_DATA,
  payload,
})

export const getFilteredDataSuccess = (payload) => ({
  type: GET_FILTERED_DATA_SUCCESS,
  payload,
})

export const getFilteredDataFail = (payload) => ({
  type: GET_FILTERED_DATA_FAIL,
  payload,
})

export const setStops = (payload) => ({
  type: SET_STOPS,
  payload,
})

export const addFreezeId = (payload) => ({
  type: ADD_FREEZE_ID,
  payload,
})

export const setFreezeIds = (payload) => ({
  type: SET_FREEZE_IDS,
  payload,
})

export const addFreezeIds = (payload) => ({
  type: ADD_FREEZE_IDS,
  payload,
})

export const removeFreezeId = (payload) => ({
  type: REMOVE_FREEZE_ID,
  payload,
})

export const removeFreezeIds = (payload) => ({
  type: REMOVE_FREEZE_IDS,
  payload,
})

export const clearFreezeIds = () => ({
  type: CLEAR_FREEZE_IDS,
})

export const setRetainOperations = (payload) => ({
  type: SET_RETAIN_OPERATIONS,
  payload,
})
