import { takeLatest, put } from 'redux-saga/effects'
import * as actions from './actions'
import { getUniqueValues } from './utils'
import api from './api'

function* uploadData({ payload }) {
  try {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    formData.append('csv_file', payload.file)

    const response = yield api.upload(payload.name, headers, formData)

    yield put(actions.uploadDataSuccess(response.id))
  } catch (err) {
    if (!err?.response) {
      yield put(actions.uploadDataFail('Something went wrong!'))
    }
    let { validation_errors, error, missing_headers } = err?.response?.data
    if (missing_headers && missing_headers.length) {
      missing_headers = missing_headers.map(
        (missing) => `${missing} header is missing`
      )
    }
    yield put(
      actions.uploadDataFail(
        validation_errors || error || missing_headers || 'Something went wrong!'
      )
    )
  }
}

function* getInitialData({ payload: id }) {
  try {
    const data = yield api.getUploadData(id)
    const options = {
      workcenters: getUniqueValues(data, 'responsible_entity'),
      operationStatuses: getUniqueValues(data, 'operation_status'),
      approvals: getUniqueValues(data, 'plan_ikke'),
      priorities: getUniqueValues(data, 'priority'),
    }

    yield put(actions.setDataState({ data }))
    yield put(actions.setMappedData(options))
  } catch (err) {
    yield put(actions.getDataFail(err.response.data))
  }
}

function* getData({ payload: id }) {
  try {
    const data = yield api.getUploadData(id)
    const options = {
      workcenters: getUniqueValues(data, 'responsible_entity'),
      operationStatuses: getUniqueValues(data, 'operation_status'),
      approvals: getUniqueValues(data, 'plan_ikke'),
      priorities: getUniqueValues(data, 'priority'),
    }

    yield put(actions.getDataSuccess(data))
    yield put(actions.setMappedData(options))
  } catch (err) {
    yield put(actions.getDataFail(err.response.data))
  }
}

function* getFiles() {
  try {
    const response = yield api.getAllUploads()

    yield put(actions.getFilesSuccess(response))
  } catch (err) {
    yield put(actions.getFilesFail())
  }
}

function* deleteFile({ payload: id }) {
  try {
    yield api.deleteUpload(id)

    yield put(actions.deleteFileSuccess(id))
    yield put(actions.getFiles())
  } catch (err) {
    yield put(actions.deleteFileFail())
  }
}

export default function* sagas() {
  yield takeLatest(actions.UPLOAD_DATA, uploadData)
  yield takeLatest(actions.UPLOAD_DATA_SUCCESS, getFiles)
  yield takeLatest(actions.GET_DATA, getData)
  yield takeLatest(actions.GET_INITIAL_DATA, getInitialData)
  yield takeLatest(actions.GET_FILES, getFiles)
  yield takeLatest(actions.DELETE_FILE, deleteFile)
}
